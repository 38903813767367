<button
  mat-flat-button
  type="button"
  [color]="color"
  (click)="onButtonClick($event)"
  [disabled]="disabled"
  [disableRipple]="disableRipple"
>
  <app-icon *ngIf="!!icon" [icon]="icon"></app-icon>
  {{ label | translate }}
  <ng-content></ng-content>
</button>
