import { environment } from "../../../environments/environment";
import { LogLevel } from "./log-level";
import { isLoggerType, LoggerType } from "./logger-type";

const STYLES = "font-weight: 700;";

export default class Logger {
  private static readonly CURRENT_LOG_LEVEL: LogLevel = environment.logLevel ?? LogLevel.ERROR;

  public static withName(contextName: string, type?: LoggerType): Logger;
  public static withName(contextName: string, options?: LoggerOptions): Logger;

  public static withName(contextName: string, optionsOrType?: LoggerOptions | LoggerType): Logger {
    let options: LoggerOptions;

    if (isLoggerType(optionsOrType)) {
      options = { type: optionsOrType };
    } else {
      options = optionsOrType;
    }

    return new Logger(contextName, options);
  }

  public static debug(...data: unknown[]): void {
    this.use(LogLevel.DEBUG, ...data);
  }

  public static info(...data: unknown[]): void {
    this.use(LogLevel.INFO, ...data);
  }

  public static warn(...data: unknown[]): void {
    this.use(LogLevel.WARN, ...data);
  }

  public static error(...data: unknown[]): void {
    this.use(LogLevel.ERROR, ...data);
  }

  public static use(logLevel: LogLevel, ...data: unknown[]): void {
    if (logLevel < this.CURRENT_LOG_LEVEL) {
      return;
    }

    switch (logLevel) {
      case LogLevel.DEBUG:
        console.log(...data);
        return;

      case LogLevel.INFO:
        console.info(...data);
        return;

      case LogLevel.WARN:
        console.warn(...data);
        return;

      case LogLevel.ERROR:
        console.error(...data);
        return;
    }
  }

  constructor(
    private readonly contextName: string = "Logger",
    private options: LoggerOptions = {},
  ) {}

  public debug(...data: unknown[]): void {
    Logger.debug(...this.styledContextArgs, ...data);
  }

  public info(...data: unknown[]): void {
    Logger.info(...this.styledContextArgs, ...data);
  }

  public warn(...data: unknown[]): void {
    Logger.warn(...this.styledContextArgs, ...data);
  }

  public error(...data: unknown[]): void {
    Logger.error(...this.styledContextArgs, ...data);
  }

  public errors(errors: unknown[]): void {
    errors.forEach((error) => this.error(error));
  }

  public use(logLevel: LogLevel, ...data: unknown[]) {
    return Logger.use(logLevel, ...this.styledContextArgs, ...data);
  }

  private get styledContextArgs() {
    const { type, color = type?.getColor() } = this.options;
    let styles = STYLES;

    if (color) {
      styles += `color: ${color};`;
    }

    return [`%c${this.contextName}`, styles];
  }
}

interface LoggerOptions {
  color?: string;
  type?: LoggerType;
}
