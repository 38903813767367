import { animate, style, transition, trigger } from "@angular/animations";
import { CommonModule } from "@angular/common";
import {
  AfterContentInit,
  Component,
  computed,
  ContentChild,
  ContentChildren,
  DestroyRef,
  ElementRef,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  QueryList,
  signal,
  Signal,
} from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { NgControl, Validators } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Observable, Subscription, zip } from "rxjs";
import { map } from "rxjs/operators";
import { pick } from "../../../helpers/observables/mappers";
import { Icons } from "../../icons";
import { TranslatablePipe } from "../../pipes/translatable.pipe";
import { TranslatableService } from "../../services/translatable.service";
import { bindToSignal, randomChars } from "../../utils/helpers";
import { IconComponent } from "../icon/icon.component";
import { LrdErrorComponent } from "./lrd-error/lrd-error.component";
import { LrdFormFieldActionComponent } from "./lrd-form-field-action/lrd-form-field-action.component";
import { LrdFormFieldContextService } from "./lrd-form-field-context.service";

export const LRD_FORM_FIELD_SHOW_HINTS = new InjectionToken<boolean>("LRD_FORM_FIELD_SHOW_HINTS", {
  providedIn: "root",
  factory: () => true,
});

export const LRD_FORM_FIELD_HANDLE_ERROR_DISPLAY = new InjectionToken<boolean>(
  "LRD_FORM_FIELD_HANDLE_ERROR_DISPLAY",
  {
    providedIn: "root",
    factory: () => true,
  },
);

@Component({
  selector: "app-lrd-form-field",
  standalone: true,
  imports: [CommonModule, IconComponent, MatTooltipModule, TranslatablePipe, LrdErrorComponent],
  templateUrl: "./lrd-form-field.component.html",
  styleUrls: ["./lrd-form-field.component.scss"],
  providers: [LrdFormFieldContextService],
  animations: [
    trigger("showErrors", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(100%)" }),
        animate("200ms ease-out", style({ opacity: 1, transform: "translateY(0%)" })),
      ]),
    ]),
  ],
})
export class LrdFormFieldComponent implements AfterContentInit, OnDestroy {
  @Input() label: string;
  @Input() tip: string;
  @Input() hint: string;
  @Input() icon: ValuesOf<typeof Icons>;
  @Input() toolTip: string;
  @Input() handleErrors = this.handleErrorDisplayDefault;
  @Input() showFirstErrorOnly = true;

  @Input()
  @HostBinding("class.lrd-compact")
  compact: boolean;

  @Input()
  showHints = this.showHintsDefault;

  @HostBinding("class.lrd-empty") isEmpty = true;

  @HostBinding("class.lrd-ignore-errors")
  get ignoreErrors(): boolean {
    return !this.handleErrors;
  }

  @HostBinding("class.lrd-error")
  get _hasErrors(): boolean {
    return this.errors().length > 0;
  }

  @ContentChild(NgControl)
  private ngControl: NgControl;

  @ContentChild(NgControl, { read: ElementRef })
  private ngControlEl: ElementRef;

  @ContentChild(LrdFormFieldActionComponent, { static: true })
  private fieldAction: LrdFormFieldActionComponent;

  @ContentChildren(LrdErrorComponent)
  public contentChildrenErrors: QueryList<any>;

  hasFieldAction = computed(() => !!this.fieldAction);

  fieldId: string;
  labelId: string;
  allowIcon: Signal<boolean>;
  isRequired: Signal<boolean>;
  errors = signal<string[]>([]);
  hasErrors: Signal<boolean>;

  private subscriptions = new Subscription();

  constructor(
    private formFieldContext: LrdFormFieldContextService,
    private translatableService: TranslatableService,
    private destroyRef: DestroyRef,
    @Inject(LRD_FORM_FIELD_SHOW_HINTS) private showHintsDefault: boolean,
    @Inject(LRD_FORM_FIELD_HANDLE_ERROR_DISPLAY) private handleErrorDisplayDefault: boolean,
  ) {
    this.allowIcon = toSignal(formFieldContext.options().pipe(map(pick("allowIcon"))));
    this.isRequired = toSignal(formFieldContext.hasValidator(Validators.required));
    this.hasErrors = computed(() => !!this.errors()?.length);
  }

  ngAfterContentInit(): void {
    const formControl = this.ngControl?.control;

    if (formControl == null) {
      return;
    }

    const fieldId = this.getOrCreateFieldId();
    this.ngControlEl.nativeElement.id = fieldId;
    this.formFieldContext.setFieldId(fieldId);
    this.formFieldContext.setControl(formControl);

    this.subscriptions.add(
      zip([
        this.formFieldContext.isEmpty(),
        this.formFieldContext.fieldId(),
        this.formFieldContext.fieldLabelId(),
      ]).subscribe({
        next: ([isEmpty, fieldId, labelId]) => {
          this.isEmpty = isEmpty;
          this.fieldId = fieldId;
          this.labelId = labelId;
        },
      }),
    );

    this.buildErrorsSignal();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onLabelClick(event: MouseEvent): void {
    this.formFieldContext.triggerLabelClick(event);
  }

  private getOrCreateFieldId(): string {
    return this.ngControlEl.nativeElement.id || `lrd-field-${randomChars()}`;
  }

  private buildErrorsSignal(): void {
    const formControlErrors$ = this.formFieldContext.errors();

    const errors$: Observable<string[]> = formControlErrors$.pipe(
      map((errors) => {
        const errorMessages: string[] = [];

        if (errors == null) {
          return errorMessages;
        }

        for (let [error, data] of Object.entries(errors)) {
          const translationKey = `form-errors.${error}`;
          let message: string;

          if (data.skipTranslation) {
            message = data.error;
          }

          if (error === "minlength" || error === "maxlength") {
            data = { ...data, [error]: data.requiredLength };
          }

          message ??= this.translatableService.transform(
            translationKey,
            typeof data === "object" ? data : null,
          );

          if (message === translationKey) {
            message = this.translatableService.transform("form-errors.unknown", { error });
          }

          if (this.showFirstErrorOnly) {
            return [message];
          }

          errorMessages.push(message);
        }

        return errorMessages;
      }),
    );

    bindToSignal(errors$, this.errors, this.destroyRef);
  }
}
