import { environment } from "../../environments/environment";
import { ApiServicesList } from "@shared/enumerations/apiServiceEnums";

export class UrlService {
  public static get isLocal(): boolean {
    return location.hostname === "localhost" || location.hostname === "127.0.0.1";
  }

  public static getSocialLoginURL(
    service: string,
    redirectionQueryParams?: Record<string, string>,
  ) {
    const appSettings = JSON.parse(sessionStorage.getItem("appSettings"));
    const appClientId = appSettings.appClientId;

    if (redirectionQueryParams != null) {
      const state = encodeURIComponent(JSON.stringify(redirectionQueryParams));

      return `${
        appSettings.baseAuthUrl
      }/oauth2/authorize?redirect_uri=${UrlService.getBaseApiUrlForEnvironment(
        ApiServicesList.SOCIAL_SERVICE,
      )}login/social&state=${state}&response_type=code&client_id=${appClientId}&identity_provider=${service}`;
    } else {
      return `${
        appSettings.baseAuthUrl
      }/oauth2/authorize?redirect_uri=${UrlService.getBaseApiUrlForEnvironment(
        ApiServicesList.SOCIAL_SERVICE,
      )}login/social&response_type=code&client_id=${appClientId}&identity_provider=${service}`;
    }
  }

  public static getAPICallAddress(serviceToContact: ApiServicesList, path: string) {
    return `${UrlService.getBaseApiUrlForEnvironment(serviceToContact)}${path}`;
  }

  public static getBaseUrlForEnvironment(getApiUrl = false) {
    if (environment.production) {
      return getApiUrl ? environment.apiUrl : environment.url;
    }

    const apiUrl = localStorage.getItem("LRD_API_URL_OVERRIDE") ?? environment.apiUrl;
    const url = localStorage.getItem("LRD_URL_OVERRIDE") ?? environment.url;

    return getApiUrl ? apiUrl : url;
  }

  public static getBaseApiUrlForEnvironment(
    serviceToContact: ApiServicesList,
    apiUrl = true,
  ): string {
    const url: string = this.getBaseUrlForEnvironment(apiUrl);

    switch (serviceToContact) {
      case ApiServicesList.USER_SERVICE: {
        return "https://" + url + "/users/";
      }
      case ApiServicesList.VARIOUS_SERVICE: {
        return "https://" + url + "/various/v1/";
      }
      case ApiServicesList.ABOUT_PAGE_SERVICE: {
        return "https://" + url + "/contentparts/v1/";
      }
      case ApiServicesList.ORGANIZATION_SERVICE: {
        return "https://" + url + "/organizations/";
      }
      case ApiServicesList.PROGRAM_SERVICE: {
        return "https://" + url + "/programs/";
      }
      case ApiServicesList.SOCIAL_SERVICE: {
        return "https://" + url + "/social/";
      }
      case ApiServicesList.PROJECT_SERVICE: {
        return "https://" + url + "/projects/";
      }
      case ApiServicesList.GENERATED_SERVICE: {
        return "https://" + url + "/generated/";
      }
      case ApiServicesList.SYSTEM_SERVICE: {
        return "https://" + url + "/system/v1/";
      }
      case ApiServicesList.UTIL_SERVICE: {
        return "https://" + url + "/util/v1/";
      }
      case ApiServicesList.CONTRIBUTIONS_SERVICE: {
        return "https://" + url + "/contributions/";
      }
      case ApiServicesList.SEARCH_SERVICE: {
        return "https://" + url + "/search/";
      }
      case ApiServicesList.PAYMENTS_SERVICE: {
        return "https://" + url + "/payments/";
      }
      case ApiServicesList.HELLO_SERVICE: {
        return "https://" + url + "/hello/";
      }
      case ApiServicesList.PROMOTION_BANNER_SERVICE: {
        return "https://" + url + "/promotion-banners/";
      }
    }

    return `https://${url}`;
  }

  public static jsonToQueryParams(json: {}): string {
    return Object.keys(json)
      .filter((key) => !!json[key] || json[key] === 0)
      .map((key) => `${key}=${encodeURIComponent(json[key])}`)
      .join("&");
  }
}
