import { Injectable } from "@angular/core";
import { PromotionBannerViewModel } from "@app/shared-v2/components/promotion-banner/promotion-banner-view-model";
import { PromotionBannerLocationEnum } from "lrd-interfaces/interfaces";
import { BehaviorSubject, Observable, of, switchMap } from "rxjs";
import { PromotionBannerService } from "./promotion-banner.service";
import { PromotionBannerFactory } from "@app/shared-v2/components/promotion-banner/promotion-banner-factory.service";
import { v4 as uuid } from "uuid";

@Injectable({
  providedIn: "root",
})
export class PromotionBannerDisplayService {
  promotionBannerLocation$ = new BehaviorSubject<PromotionBannerLocationEnum>(null);
  promotionBannerViewModel$: Observable<PromotionBannerViewModel> =
    this.promotionBannerLocation$.pipe(
      switchMap((promotionBannerLocation) => {
        if (!promotionBannerLocation) return of(null);

        return this.promotionBannerService
          .getRandomPromotionBanners({
            limit: 1,
            location: promotionBannerLocation,
          })
          .then((promotionBanners) => {
            if (!promotionBanners?.length) return null;

            return this.promotionBannerFactory.createPromotionBannerViewModel(
              promotionBanners[0],
              promotionBannerLocation,
              uuid(),
            );
          });
      }),
    );

  constructor(
    private promotionBannerService: PromotionBannerService,
    private promotionBannerFactory: PromotionBannerFactory,
  ) {}

  public setPromotionBannerLocation(promotionBannerLocation: PromotionBannerLocationEnum): void {
    this.promotionBannerLocation$.next(promotionBannerLocation);
  }
}
