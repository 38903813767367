import { Injectable } from "@angular/core";
import { ApiService } from "./apiService";
import { ApiServicesList } from "@shared/enumerations/apiServiceEnums";

@Injectable()
export class AboutPagesService {
  private cachedAboutPages: any;

  constructor(private apiService: ApiService) {}

  public async getAboutPages(): Promise<any> {
    if (!this.cachedAboutPages) {
      this.cachedAboutPages = await this.getAboutPage("all");
    }

    return Promise.resolve(this.cachedAboutPages);
  }

  public async getAboutPage(aboutPageId: string): Promise<any> {
    return this.apiService.get(ApiServicesList.ABOUT_PAGE_SERVICE, `aboutPage/${aboutPageId}`);
  }

  public async putAboutPage(aboutPageId: string, aboutPage: any) {
    return this.apiService.put(ApiServicesList.ABOUT_PAGE_SERVICE, `aboutPage/${aboutPageId}`, {
      aboutPage: aboutPage,
    });
  }

  public async getCustomPage(customPageId: string): Promise<any> {
    return this.apiService.get(ApiServicesList.ABOUT_PAGE_SERVICE, `customPage/${customPageId}`);
  }
}
