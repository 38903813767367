import { Renderer2 } from "@angular/core";

export const loadZohoScript = (renderer: Renderer2, document: Document) => {
  const script = renderer.createElement("script");

  script.type = "text/plain";
  script.setAttribute("data-cookieconsent", "preferences,marketing");
  script.innerHTML = `
        var w = window;
        var p = w.location.protocol;
        if (p.indexOf("http") < 0) {
          p = "http" + ":";
        }
        var d = document;
        var f = d.getElementsByTagName("script")[0],
          s = d.createElement("script");
        s.type = "text/javascript";
        s.async = false;
        if (s.readyState) {
          s.onreadystatechange = function () {
            if (s.readyState == "loaded" || s.readyState == "complete") {
              s.onreadystatechange = null;
              try {
                loadwaprops(
                  "3zdda2b89e1147d7da746a540665d36c60",
                  "3z9a4ddb9c8c4dc279bfe69eaf82232ec5",
                  "3zbb68726572554f4986ebbe771f922bab717a176fd82f0b36872588d9ca1b6f91",
                  "3ze9718507248e6e2c7ca9c86c9f0dc42007a15421cad9c527c81bb8f5d9a5c284",
                  "0.0",
                );
              } catch (e) {}
            }
          };
        } else {
          s.onload = function () {
            try {
              loadwaprops(
                "3zdda2b89e1147d7da746a540665d36c60",
                "3z9a4ddb9c8c4dc279bfe69eaf82232ec5",
                "3zbb68726572554f4986ebbe771f922bab717a176fd82f0b36872588d9ca1b6f91",
                "3ze9718507248e6e2c7ca9c86c9f0dc42007a15421cad9c527c81bb8f5d9a5c284",
                "0.0",
              );
            } catch (e) {}
          };
        }
        s.src = p + "//ma.zoho.com/hub/js/WebsiteAutomation.js";
        f.parentNode.insertBefore(s, f);
  `;

  renderer.appendChild(document.body, script);
};
