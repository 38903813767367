import { Routes } from "@angular/router";
import { AppV2Resolver } from "./app-v2-resolver.service";
import { useViewModelResolver } from "@shared-v2/utils/view-models/helpers";
import { dynamicStylesResolverFactory } from "./dynamic-styles-resolver.factory";
import { DynamicStylesheet } from "./services/dynamic-styles.service";
import { generateTranslatedRoutes } from "./services-v2/routing/routing.service";

export const APP_ROUTES: Routes = generateTranslatedRoutes([
  {
    path: "admin",
    loadChildren: () => import("./ui/admin-console/admin.module").then((m) => m.AdminModule),
    data: { routeName: "admin" },
    resolve: {
      theme: dynamicStylesResolverFactory([DynamicStylesheet.ADMIN]),
    },
  },
  {
    paths: {
      fr: "fr/l",
      en: "en/l",
    },
    loadChildren: () => import("./legacy-app.module").then((m) => m.LegacyAppModule),
  },
  {
    path: "",
    loadChildren: () => import("./app-v2.module").then((m) => m.AppV2Module),
    resolve: useViewModelResolver(AppV2Resolver),
  },
]);
