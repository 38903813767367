import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlService } from "../services/urlService";
import { ApiServicesList } from "./enumerations/apiServiceEnums";

export interface ProvidedDataEntry {
  name: string;
  fileName: string;
  specificAction?: Function;
  data: any;
}

@Injectable()
export class GeneratedDataProvider {
  private dataToProvide: ProvidedDataEntry[] = [
    {
      name: "lookups",
      fileName: "lookups.json",
      specificAction: (data) => {
        data.sort((lhs, rhs) => {
          return lhs.fr.toLowerCase().localeCompare(rhs.fr.toLowerCase());
        });

        for (const lookup of data) {
          let numberOfChildren = 0;
          for (const otherLookup of data) {
            if (otherLookup.parent === lookup.lookupId) {
              numberOfChildren = numberOfChildren + 1;
            }
          }
          lookup["numberOfChildren"] = numberOfChildren;
        }
      },
      data: null,
    },
  ];

  constructor(private httpClient: HttpClient) {}

  async getData(requestedDataName: string) {
    const dataInfo = this.dataToProvide.find((data) => {
      return data.name === requestedDataName;
    });

    if (dataInfo.data === null) {
      await this.fetchData(dataInfo);
    }
    return dataInfo.data;
  }

  private async fetchData(dataInfo: ProvidedDataEntry) {
    await this.httpClient
      .get(
        `${UrlService.getBaseApiUrlForEnvironment(ApiServicesList.GENERATED_SERVICE, false)}${
          dataInfo.fileName
        }`,
      )
      .toPromise()
      .then((data) => {
        if (dataInfo.specificAction) {
          dataInfo.specificAction(data);
        }
        dataInfo.data = data;
      });
  }
}
