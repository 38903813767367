import { IApiPostResponse } from "@shared/models/api";
import { StateData } from "@shared-v2/utils/state-data";

export function pick<T, K extends keyof T>(
  key: K,
  defaultValue: T[K] = null,
): MapperFunction<T, T[K]> {
  return (object) => {
    if (object == null) {
      return defaultValue;
    }

    return object[key] ?? defaultValue;
  };
}

export function toArrayLength<T>(): MapperFunction<T[], number> {
  return (value) => value.length;
}

export function isEqualTo<T>(expectedValue: T, strict = true): MapperFunction<T, boolean> {
  return (value) => {
    if (strict) {
      return value === expectedValue;
    } else {
      return value == expectedValue;
    }
  };
}

export function isNotEqualTo<T>(expectedValue: T, strict = true): MapperFunction<T, boolean> {
  return (value) => {
    if (strict) {
      return value !== expectedValue;
    } else {
      return value != expectedValue;
    }
  };
}

export function isEmptyValue<T>(): MapperFunction<T, boolean> {
  return (value) => value == null || value === "";
}

export function invertBool(): MapperFunction<boolean, boolean> {
  return (value) => !value;
}

export function asSuccessStateData<T, E>(): MapperFunction<T, StateData<T, E>> {
  return (value) => StateData.createSuccess(value);
}

export function getData<T, E>(): MapperFunction<StateData<T, E>, T> {
  return (stateData) => stateData.getData();
}

export function toIsPending<T>(): MapperFunction<StateData<T>, boolean> {
  return (stateData) => stateData.isPending();
}

export function arrayIncludes<T>(expectedValue: T): MapperFunction<T[], boolean> {
  return (values) => values.includes(expectedValue);
}

export function asApiResponseItems<T extends IApiPostResponse<unknown>>(): MapperFunction<
  T,
  T["items"]
> {
  return ({ items }) => items;
}

export function areAllEqualTo<T extends unknown[]>(
  expectedValue: T[number],
): MapperFunction<T, boolean> {
  return (values) => values.every((value) => value === expectedValue);
}

export function areAllTrue<T extends boolean[]>(): MapperFunction<T, boolean> {
  return areAllEqualTo<T>(true);
}
