import { CommonModule } from "@angular/common";
import { Component, ElementRef, HostBinding, HostListener, Input, OnInit } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslatablePipe } from "@app/shared-v2/pipes/translatable.pipe";
import { Translatable } from "@app/types";
import { Colors } from "@app/shared-v2/colors";
import { IconComponent } from "@app/shared-v2/components/icon/icon.component";
import { Icons } from "../../icons";

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent, TranslatablePipe, MatProgressSpinnerModule],
  selector: "button[app-v2-button], a[app-v2-button]",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
  exportAs: "v2Button",
})
export class ButtonComponent implements ButtonViewModel, OnInit {
  @Input()
  label?: Translatable;

  @Input()
  @HostBinding("class")
  color?: Colors | ValuesOf<typeof Colors> | string = Colors.DEFAULT;

  @Input()
  @HostBinding("class.light")
  light = false;

  @Input()
  @HostBinding("class.compact")
  compact?: boolean = false;

  @Input()
  @HostBinding("class.big")
  big?: boolean = false;

  @Input()
  @HostBinding("class.round")
  round?: boolean = false;

  @Input()
  @HostBinding("class.square")
  square?: boolean = false;

  @Input()
  @HostBinding("class.flat")
  flat?: boolean = false;

  @Input()
  @HostBinding("class.textual")
  textual?: boolean = false;

  @Input() iconLeft?: Icons | ValuesOf<typeof Icons> | string;
  @Input() iconRight?: Icons | ValuesOf<typeof Icons> | string;
  @Input() busy?: boolean = false;
  @Input() trackingClass?: string;

  @Input()
  @HostBinding("class.force-disabled")
  forceDisabled = false;

  @Input()
  action?: (event: MouseEvent) => void;

  constructor(public elementRef: ElementRef) {}

  @HostListener("click", ["$event"])
  onHostClick(event: MouseEvent): void {
    if (this.forceDisabled) {
      return;
    }

    this.action?.(event);
  }

  /**
   * @warning
   * Should only be used if component options are static.
   * Changes on @Inputs values may not behave as expected otherwise.
   */
  @Input() useImmutableViewModel?: Readonly<ButtonViewModel>;

  ngOnInit(): void {
    if (this.useImmutableViewModel) {
      for (const propName in this.useImmutableViewModel) {
        this[propName] = this.useImmutableViewModel[propName];
      }
    }
  }
}

export interface ButtonViewModel {
  action?: (event: MouseEvent) => void;
  busy?: boolean;
  color?: Colors | ValuesOf<typeof Colors> | string;
  compact?: boolean;
  big?: boolean;
  disabled?: boolean;
  forceDisabled?: boolean;
  flat?: boolean;
  iconLeft?: Icons | ValuesOf<typeof Icons> | string;
  iconRight?: Icons | ValuesOf<typeof Icons> | string;
  label?: Translatable;
  round?: boolean;
  square?: boolean;
  textual?: boolean;
  light?: boolean;
  accessibleTitle?: Translatable;
  trackingClass?: string;
}
