import { Injectable } from "@angular/core";
import { Icons } from "@app/shared-v2/icons";
import { Colors } from "src/app/shared-v2/colors";
import { ThirdPartyAuthPlatform } from "../../../services-v2/third-party-auth/third-party-auth-platform";
import { SignUpViewModel } from "./sign-up-view-model";

@Injectable({
  providedIn: "root",
})
export class SignUpFactory {
  public fromDefault(): SignUpViewModel {
    return {
      register: {
        title: { key: "pages.sign-up.register.title" },
        formUI: {
          firstName: { label: { key: "pages.sign-up.register.form.first-name.label" } },
          lastName: { label: { key: "pages.sign-up.register.form.last-name.label" } },
          email: { label: { key: "pages.sign-up.register.form.email.label" } },
          password: {
            label: { key: "pages.sign-up.register.form.password.label" },
            tooltip: { key: "pages.sign-up.register.form.password.tooltip" },
          },
          passwordValidation: {
            label: { key: "pages.sign-up.register.form.password-validation.label" },
          },
          submit: {
            label: { key: "pages.sign-up.register.form.submit.label" },
          },
        },
        managedErrors: {
          SIGNUP_USERNAME_ALREADY_EXISTS: {
            key: "pages.sign-up.register.errors.SIGNUP_USERNAME_ALREADY_EXISTS",
          },
          PASSWORD_NOT_EQUALS: {
            key: "pages.sign-up.register.errors.PASSWORD_NOT_EQUALS",
          },
        },
        socialUI: {
          description: { key: "pages.sign-up.register.social.description" },
          options: [
            {
              platform: ThirdPartyAuthPlatform.GOOGLE,
              icon: Icons.SOCIAL_GOOGLE,
              trackingClass: "gtm-creer_compte_google",
            },
            {
              platform: ThirdPartyAuthPlatform.FACEBOOK,
              icon: Icons.SOCIAL_FACEBOOK,
              trackingClass: "gtm-creer_compte_facebook",
            },
          ],
        },
        dismissButton: {
          color: Colors.YELLOW,
          icon: Icons.CROSS,
        },
      },
    };
  }
}
