import { ContactMethod } from "src/app/shared-v2/types";

export const ContactMethods: ContactMethod[] = [
  {
    name: "Phone",
    label: "418-286-0373",
    url: "tel:418-286-0373",
    icon: "contact-phone",
  },
  {
    name: "Email",
    label: "info@laruchequebec.com",
    url: "mailto:info@laruchequebec.com",
    icon: "contact-email",
  },
];
