import { booleanAttribute, Directive, HostListener, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BaseLrdFieldDirective } from "./base-lrd-field.directive";

@Directive({
  selector: "input[lrdInput],textarea[lrdInput]",
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LrdInputDirective,
      multi: true,
    },
    {
      provide: BaseLrdFieldDirective,
      useExisting: LrdInputDirective,
    },
  ],
})
export class LrdInputDirective extends BaseLrdFieldDirective<string | number, LrdInputHostType> {
  @Input()
  public type: string;

  @Input({ transform: booleanAttribute })
  public autoSelect = false;

  @HostListener("input")
  onHostInput(): void {
    this.setValue(this.elementRef.nativeElement.value);
  }

  @HostListener("focus")
  onHostFocus(): void {
    if (this.autoSelect) {
      this.elementRef.nativeElement.select();
    }
  }

  @HostListener("blur")
  onHostBlur(): void {
    if (!this.touched) {
      this.markAsTouched();
    }
  }

  get hostIsNumberInput(): boolean {
    const hostEl = this.elementRef.nativeElement;
    return this.type === "number" && hostEl.tagName === "INPUT";
  }

  protected override setValue(value: string | number, emitChange = true): void {
    if (this.hostIsNumberInput) {
      value = this.parseNumericValue(value);
    }

    super.setValue(value, emitChange);
  }

  public override writeValue(value: string | number): void {
    this.setValue(value);

    if (this.hostIsNumberInput) {
      const hostEl = this.elementRef.nativeElement as HTMLInputElement;
      hostEl.valueAsNumber = this.parseNumericValue(value);
      return;
    }

    this.elementRef.nativeElement.value = value?.toString();
  }

  protected onDisabledChange(isDisabled: boolean): void {
    this.elementRef.nativeElement.disabled = isDisabled;
  }

  private parseNumericValue(value: string | number): number {
    if (typeof value === "number") {
      return value;
    }

    const parsedValue = parseFloat(value);

    if (isNaN(parsedValue)) {
      return null;
    }

    return parsedValue;
  }
}

type LrdInputHostType = HTMLInputElement | HTMLTextAreaElement;
