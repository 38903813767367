<div>
  <p class="title">Utiliser des critères</p>

  <ng-container *ngIf="actionForm">
    <form class="action-groups" [formGroup]="actionForm" (submit)="handleOnSubmit()">
      <app-lrd-form-field
        [compact]="true"
        class="tone-input"
        [label]="'components.ai-drawer.criteria.ton' | translatable"
      >
        <app-lrd-select
          [multiselect]="true"
          [placeholder]="'components.ai-drawer.criteria.ton' | translatable"
          formControlName="tones"
          [multiselectMax]="3"
        >
          <app-lrd-option *ngFor="let tone of tones" [value]="tone.value">
            {{ tone.label | translatable }}
          </app-lrd-option>
        </app-lrd-select>
      </app-lrd-form-field>

      <app-lrd-form-field
        [compact]="true"
        [label]="'components.ai-drawer.criteria.length' | translatable"
      >
        <input
          lrdInput
          class="length-input"
          type="number"
          [max]="maxChar"
          formControlName="length"
          [placeholder]="'components.ai-drawer.criteria.length' | translatable"
        />
      </app-lrd-form-field>

      <button app-v2-button type="submit" [compact]="true" [flat]="true" color="purple">
        {{ "components.ai-drawer.criteria.apply" | translatable }}
      </button>
    </form>
  </ng-container>
</div>
