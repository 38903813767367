import { Component, Inject, OnInit } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-snackbar",
  templateUrl: "./snackbar.component.html",
  styleUrls: ["./snackbar.component.scss"],
})
export class SnackbarComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {}

  get getIcon() {
    switch (this.data.snackType) {
      case "Message":
        return "check";
      case "Error":
        return "exclamation-circle";
    }
  }

  close() {
    this.snackBar.dismiss();
  }
}
