import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { METADATA_CONTENT_MAP } from "@app/shared/models/metadataContentMap";
import { MetaTag } from "@app/shared/models/metaTag";
import { TranslateService } from "@app/shared/translations/translate.service";
import { UrlService } from "./urlService";
import slugify from "slugify";
import { GoogleTagManagerImplService } from "./google-tag-manager-impl.service";

@Injectable({
  providedIn: "root",
})
export class MetaTagService {
  private urlMeta = "og:url";
  private titleMeta = "og:title";
  private descriptionMeta = "og:description";
  private imageUrlMeta = "og:image";
  private imageWidthMeta = "og:image:width";
  private imageHeightMeta = "og:image:height";
  private type = "og:type";
  private secureImageMeta = "og:image:secure_url";
  private siteName = "og:site_name";
  private twitterCard = "twitter:card";
  private twitterSite = "twitter:site";
  private twitterTitle = "twitter:title";
  private twitterDescription = "twitter:description";
  private twitterImage = "twitter:image";

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService,
    private googleTagManagerImplService: GoogleTagManagerImplService,
    @Inject(DOCUMENT) private dom,
  ) {}

  public getTitleAndDesctiptionFromKey(
    key: string,
    regionName: string = null,
  ): { title: string; description: string; image: string } {
    let metadata = METADATA_CONTENT_MAP.find((metadataContent) => metadataContent.key === key);
    if (!metadata) {
      metadata = METADATA_CONTENT_MAP.find((metadataContent) => metadataContent.key === "home");
    }
    const regionSlug = regionName ? slugify(regionName, { lower: true, strict: true }) : null;
    return {
      title: metadata.title[this.translate.currentLang]?.replace("[regionName]", regionName),
      description:
        metadata.description?.[this.translate.currentLang]?.replace("[regionName]", regionName) ||
        null,
      image:
        metadata.image?.[this.translate.currentLang]?.replace("[regionName]", regionSlug) || null,
    };
  }

  public setTitle(title: string): void {
    if (!title.includes("La Ruche")) {
      title = `${title} | La Ruche`;
    }
    this.titleService.setTitle(this.removeHtmlAndFormatContent(title));
  }

  public setDescription(description: string): void {
    this.metaService.updateTag({
      name: "description",
      content: this.removeHtmlAndFormatContent(description),
    });
  }

  public setSocialMediaTags(
    url: string,
    title: string,
    description: string,
    imageUrl?: string,
    imageWidth = "600",
    imageHeight = "350",
    type = "website",
  ): void {
    if (!imageUrl) {
      imageUrl = `https://${UrlService.getBaseUrlForEnvironment()}/assets/images/social-share-thumbnails/laruchequebec-${
        this.translate.currentLang
      }.png`;
    }

    url = this.buildCompleteUrl(url);
    imageUrl = this.buildCompleteUrl(imageUrl, false);
    const imageSecureUrl = this.buildCompleteUrl(imageUrl);
    const tags = [
      new MetaTag(this.urlMeta, url, true),
      new MetaTag(this.titleMeta, this.removeHtmlAndFormatContent(title), true),
      new MetaTag(this.descriptionMeta, this.removeHtmlAndFormatContent(description), true),
      new MetaTag(this.imageUrlMeta, imageUrl, true),
      new MetaTag(this.imageWidthMeta, imageWidth, true),
      new MetaTag(this.imageHeightMeta, imageHeight, true),
      new MetaTag(this.type, type, true),
      new MetaTag(this.secureImageMeta, imageSecureUrl, true),
      new MetaTag(this.twitterCard, "summary", false),
      new MetaTag(this.siteName, "La Ruche", true),
      new MetaTag(this.twitterSite, "@laruchequebec", false),
      new MetaTag(this.twitterTitle, this.removeHtmlAndFormatContent(title), false),
      new MetaTag(this.twitterDescription, this.removeHtmlAndFormatContent(description), false),
      new MetaTag(this.twitterImage, imageUrl, false),
    ];
    this.setTags(tags);
    this.googleTagManagerImplService.pushTag("page", url, title);
  }

  public setAlternateUrls(hreflang: string, url: string): void {
    url = this.buildCompleteUrl(url);
    const head = this.dom.getElementsByTagName("head")[0];
    let element: HTMLLinkElement = this.dom.querySelector(`link[hreflang='${hreflang}']`) || null;
    if (element == null) {
      element = this.dom.createElement("link") as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute("rel", "alternate");
    element.setAttribute("hreflang", hreflang);
    element.setAttribute("href", url);
  }

  public setCanonicalUrl(url: string): void {
    url = this.buildCompleteUrl(url);
    const head = this.dom.getElementsByTagName("head")[0];
    let element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement("link") as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute("rel", "canonical");
    element.setAttribute("href", url);
  }

  public removeCanonicalUrl(): void {
    const element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element) {
      element.remove();
    }
  }

  public addNoIndex(): void {
    this.metaService.addTag({ name: "robots", content: "noindex, nofollow" });
  }

  public removeNoIndex(): void {
    this.metaService.removeTag('name="robots"');
  }

  public switchHtmlLang(locale: string): void {
    const html = this.dom.getElementsByTagName("html")[0];
    html.setAttribute("lang", locale === "en" ? "en-CA" : "fr-CA");
  }

  public setDataForNoIndexPage(url: string, pageKey: string): void {
    const completeUrl = this.buildCompleteUrl(url);
    const metadata = this.getTitleAndDesctiptionFromKey(pageKey);

    this.setTitle(metadata.title);
    this.setDescription(metadata.description);
    this.setSocialMediaTags(completeUrl, metadata.title, metadata.description);
    this.removeCanonicalUrl();
  }

  private buildCompleteUrl(url: string, secure = true): string {
    url = !url.startsWith("http")
      ? `https://${UrlService.getBaseUrlForEnvironment()}/${url.replace(/^\/|\/$/g, "")}`
      : url.replace(/\/$/g, "");
    return secure ? url.replace("http://", "https://") : url.replace("https://", "http://");
  }

  private removeHtmlAndFormatContent(content: string): string {
    content =
      content
        ?.replace(/(<([^>]+)>)/gi, "")
        .replace(/\s\s+/g, " ")
        .trim() || "";
    return content?.length > 155 ? `${content.substring(0, 155)}...` : content;
  }

  private setTags(tags: MetaTag[]): void {
    tags.forEach((siteTag) => {
      if (siteTag.isProperty) {
        this.metaService.updateTag({
          property: siteTag.name,
          content: siteTag.value,
        });
      } else {
        this.metaService.updateTag({
          name: siteTag.name,
          content: siteTag.value,
        });
      }
    });
  }
}
