import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input() label: string;
  @Input() color = "transparent";
  @Input() icon: string;
  @Input() disabled = false;
  @Input() disableRipple = false;
  @Output() onClick = new EventEmitter<MouseEvent>();

  onButtonClick(event: MouseEvent) {
    this.onClick.emit(event);
  }
}
