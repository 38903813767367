import { random } from "@shared-v2/utils/helpers";
import Logger from "../../services-v2/logger/logger.service";

export function pickRandom<T>(array: T[], quantity: number): T[] {
  const arrayCopy = [...array];
  const results: T[] = [];

  if (quantity > array.length) {
    Logger.warn(
      "Trying to pick more items than available. " +
        `Asked for ${quantity} but array only has ${array.length}.`,
    );
    quantity = array.length;
  }

  for (let i = 0; i < quantity; i++) {
    const randomIndex = random(0, arrayCopy.length);
    const randomItem = arrayCopy.splice(randomIndex, 1)[0];
    results.push(randomItem);
  }

  return results;
}

export const EMPTY_ARRAY = Object.freeze([]);

export function immutableArray<T>(array: T[]): readonly T[] {
  return Object.freeze([...array]);
}

export function isEmptyArray<T>(array: T[]): boolean {
  return array.length === 0;
}

export function areAllEmptyArrays(...arrays: unknown[][]): boolean {
  return arrays.every((it) => isEmptyArray(it));
}

export function areEqualArrays<T>(array1: T[], array2: T[], strictOrder = false): boolean {
  return (
    array1.length === array2.length &&
    arrayIncludesAll(array1, array2, strictOrder) &&
    arrayIncludesAll(array2, array1, strictOrder)
  );
}

export function arrayIncludesAll<T>(target: T[], values: T[], strictOrder = false): boolean {
  if (!strictOrder) {
    return target.every((value, index) => values[index] === value);
  }

  for (let i = 0; i < target.length; i++) {
    if (values[i] !== target[i]) {
      return false;
    }
  }

  return true;
}
