import { inject } from "@angular/core";
import { DynamicStylesheet, DynamicStylesService } from "./services/dynamic-styles.service";

export const dynamicStylesResolverFactory = (themes: DynamicStylesheet[]) => {
  return async () => {
    const dynamicStyles = inject(DynamicStylesService);
    await dynamicStyles.use(themes).promise;
    return true;
  };
};
