import { SocialNetwork } from "src/app/shared-v2/types";

export const SocialNetworks: SocialNetwork[] = [
  {
    name: "Facebook",
    url: "https://facebook.com/LaRuche.co/",
    icon: "social-facebook",
  },
  {
    name: "Instagram",
    url: "https://instagram.com/LaRuche.co/",
    icon: "social-instagram",
  },
  {
    name: "Linkedin",
    url: "https://linkedin.com/company/laruchefinancement/",
    icon: "social-linkedin",
  },
  {
    name: "X",
    url: "https://x.com/laruchequebec",
    icon: "social-x",
  },
];
