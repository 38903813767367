import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SnackbarComponent } from "./snackbar.component";
import { SnackbarService } from "./snackbar.service";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatSnackBarModule } from "@angular/material/snack-bar";

@NgModule({
  declarations: [SnackbarComponent],
  imports: [CommonModule, FontAwesomeModule, MatSnackBarModule],
  providers: [SnackbarService],
  exports: [SnackbarComponent],
})
export class SnackbarModule {}
