import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { UrlService } from "@app/services/urlService";

@Injectable({
  providedIn: "root",
})
export class CookieStorageService {
  constructor(private readonly cookieService: CookieService) {}

  public setToken(token: string): void {
    this.cookieService.set("token", token, undefined, "/", undefined, !UrlService.isLocal);
  }

  public get token(): string {
    return this.cookieService.check("token") ? this.cookieService.get("token") : null;
  }

  public removeToken(): void {
    this.cookieService.delete("token", "/");
  }
}
