import { Renderer2 } from "@angular/core";

export const loadDonorBoxScript = (renderer: Renderer2, document: Document) => {
  const script = renderer.createElement("script");

  script.type = "text/javascript";
  script.src = "https://donorbox.org/widget.js";
  script.setAttribute("paypalExpress", false);

  renderer.appendChild(document.body, script);
};
