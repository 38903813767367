import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "enumerate",
  standalone: true,
})
export class EnumeratePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(values: string | string[] | null): string {
    if (values == null) {
      return values as null;
    }

    if (!Array.isArray(values)) {
      values = [values];
    }

    const currentLocale = this.translateService.currentLang;
    const listFormat = new Intl.ListFormat(currentLocale, { style: "long", type: "conjunction" });
    return listFormat.format(values);
  }
}
