export class LoggerType {
  public readonly TAG = "LoggerType";

  public static readonly SERVICE = new LoggerType("service", "#63bfc4");
  public static readonly COMPONENT = new LoggerType("component", "#b79ac8");
  public static readonly VIEW_MODEL = new LoggerType("view model", "#ff7c57");

  private constructor(
    private readonly name: string,
    private readonly color: string,
  ) {}

  public getName(): string {
    return this.name;
  }

  public getColor(): string {
    return this.color;
  }
}

export function isLoggerType(value: unknown): value is LoggerType {
  return typeof value === "object" && value["TAG"] === "LoggerType";
}
