export enum StateDataType {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export class StateData<T, E = unknown> {
  public static createPending<T = unknown, E = unknown>(): StateData<T, E> {
    return new StateData(StateDataType.PENDING);
  }

  public static createSuccess<T, E = unknown>(value: T): StateData<T, E> {
    return new StateData(StateDataType.SUCCESS, value);
  }

  public static createWithErrors<T, E = unknown>(...errors: E[]): StateData<T, E> {
    return new StateData(StateDataType.ERROR, null, errors);
  }

  private readonly _data: T | null;
  private readonly _errors: E[];
  private readonly _type: StateDataType;

  public constructor(status: StateDataType, data: T | null = null, errors: E[] = []) {
    this._type = status;
    this._data = data;
    this._errors = errors;
  }

  public type(): StateDataType {
    return this._type;
  }

  public isSuccess(): boolean {
    return this._type === StateDataType.SUCCESS;
  }

  public isPending(): boolean {
    return this._type === StateDataType.PENDING;
  }

  public hasErrors(): boolean {
    return this._type === StateDataType.ERROR;
  }

  public getErrors(): E[] {
    return this._errors;
  }

  public getFirstError(): E {
    return this._errors != null ? this._errors[0] : null;
  }

  public getNonNullData(): T {
    if (this._data == null) {
      throw new Error(`Unexpected empty value.`);
    }

    return this._data;
  }

  public getData(): T | null {
    return this._data;
  }
}
