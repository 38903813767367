import { HttpClient } from "@angular/common/http";
import { Directive, EventEmitter, Inject, Injectable, InjectionToken, Output } from "@angular/core";

// to remove
import { MessageService } from "@app/services/messageService";
import { TranslateService as NgxTranslateService } from "@ngx-translate/core";
import appPackage from "../../../../package.json";

export const TRANS_DEFAULT_LANG = new InjectionToken("TRANS_DEFAULT_LANG");

export interface LangChangeEvent {
  lang: string;
  // translations: any;
}

declare interface Window {
  navigator: any;
}

declare const window: Window;

@Directive()
@Injectable({ providedIn: "root" })
// tslint:disable-next-line:directive-class-suffix
export class TranslateService {
  locales: string[] = ["en", "fr"];
  // For Backward Compatibility
  translations: any = {
    en: {},
    fr: {},
  };

  currentLanguage: string;

  // For ngx translate migration
  @Output() change = new EventEmitter<LangChangeEvent>();

  // For Backward Compatibility
  @Output() language = new EventEmitter<any>();

  constructor(
    @Inject(TRANS_DEFAULT_LANG) public defaultLang: string = "fr",
    private http: HttpClient,
    private messageService: MessageService,
    private ngxTranslateService: NgxTranslateService,
  ) {
    this.defaultLang = defaultLang;
    this.currentLanguage = this.detectLanguage();
    this.use(this.currentLanguage);
  }

  detectLanguage(): string | null {
    return (
      this.ngxTranslateService.currentLang ?? this.getBrowserLang() ?? this.defaultLang ?? null
    );
  }

  getBrowserLang(): string {
    if (typeof window === "undefined" || typeof window.navigator === "undefined") {
      return undefined;
    }

    let browserLang: any = window.navigator.languages ? window.navigator.languages[0] : null;
    browserLang =
      browserLang ||
      window.navigator.currentLanguage ||
      window.navigator.browserLanguage ||
      window.navigator.userLanguage;

    if (browserLang.indexOf("-") !== -1) {
      browserLang = browserLang.split("-")[0];
    }

    if (browserLang.indexOf("_") !== -1) {
      browserLang = browserLang.split("_")[0];
    }

    return browserLang;
  }

  set currentLang(lang: string) {
    this.currentLanguage = lang;
    this.change.emit({ lang });
    this.language.emit(lang);
    this.messageService.sendMessage("language");
  }

  get currentLang(): string {
    return this.getCurrentLanguage();
  }

  get oppositeLang(): string {
    return this.getCurrentLanguage() === "fr" ? "en" : "fr";
  }

  instant(key: string): string {
    if (this.translations[this.currentLang] && this.translations[this.currentLang][key]) {
      return this.translations[this.currentLang][key];
    }

    return key;
  }

  transform(key: string, locale?: any): string {
    if (locale && locale !== this.currentLang) {
      return this.instantAlternate(key);
    }
    return this.instant(key);
  }

  instantAlternate(key: string): string {
    let language = this.getCurrentLanguage();
    if (language === "fr") {
      language = "en";
    } else {
      language = "fr";
    }

    this.load(language);

    if (this.translations[language] && this.translations[language][key]) {
      return this.translations[language][key];
    }

    return key;
  }

  switch() {
    const language = this.getCurrentLanguage();
    if (language === "fr") {
      this.use("en");
    } else {
      this.use("fr");
    }
  }

  use(lang: string): void {
    this.load(lang);
    this.currentLang = lang;
  }

  async load(language: string): Promise<any> {
    if (!this.isLoadedLanguage(language)) {
      const langPath = `assets/i18n/${language || "fr"}.json?v=${appPackage.version}`;

      await this.http
        .get<{}>(langPath)
        .toPromise()
        .then((translation) => {
          this.translations[language] = Object.assign({}, translation || {});
        });
    }
  }

  public isLoadedLanguage(language: string): boolean {
    return Object.keys(this.translations[language] ?? {}).length > 0;
  }

  getCurrentLanguage(): string {
    return this.currentLanguage;
  }

  getTranslation(obj: { frenchName: string; englishName: string }): string {
    return this.currentLang === "fr"
      ? obj.frenchName || obj.englishName
      : obj.englishName || obj.frenchName;
  }

  getTranslationShort(obj: { fr: string; en: string }): string {
    return this.currentLang === "fr" ? obj.fr || obj.en : obj.en || obj.fr;
  }
}
