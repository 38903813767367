import { animate, style, transition, trigger } from "@angular/animations";

export const dropdownAnimation = trigger("dropdownAnimation", [
  transition(":enter", [
    style({ opacity: 0, transform: "translate(-50%, 8px)" }),
    animate("200ms ease-out", style({ opacity: 1, transform: "translate(-50%, 0)" })),
  ]),
  transition(":leave", [
    style({ opacity: 1, transform: "translate(-50%, 0)" }),
    animate("200ms ease-in", style({ opacity: 0, transform: "translate(-50%, 8px)" })),
  ]),
]);
