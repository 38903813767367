export enum Colors {
  BEIGE = "beige",
  BLACK = "black",
  BLUE = "blue",
  DEFAULT = "default",
  GREEN = "green",
  ORANGE = "orange",
  PURPLE = "purple",
  TURQUOISE = "turquoise",
  WHITE = "white",
  YELLOW = "yellow",
}
