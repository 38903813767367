export class Deferrer<T> {
  private readonly _promise: Promise<T>;

  public resolve: (value: PromiseLike<T> | T) => void;

  public reject: (reason?: any) => void;

  constructor() {
    this._promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  public promise(): Promise<T> {
    return this._promise;
  }
}
