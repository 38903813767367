import { HttpErrorResponse } from "@angular/common/http";
import { DestroyRef, WritableSignal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { I18nString } from "lrd-interfaces/interfaces";
import { isObservable, Observable, takeUntil, tap } from "rxjs";
import { toI18nTranslatable, Translatable } from "../../types";

export function random(from: number, to: number): number {
  return ~~(Math.random() * to) + from;
}

const HEX_CHARACTERS = "abcdef0123456789";

export function randomChars(length = 8): string {
  let result = "";

  for (let i = 0; i < length; i++) {
    result += HEX_CHARACTERS[random(0, HEX_CHARACTERS.length)];
  }

  return result;
}

export function hasProps<T extends object>(object: T | unknown, propNames: (keyof T)[]): boolean {
  return propNames.every((propName) => propName in (object as object));
}

export function hasSomeProps<T extends object>(object: T, propNames: (keyof T)[]): boolean {
  return propNames.some((propName) => propName in object);
}

/**
 * @deprecated
 */
export function watchProp<O extends object, K extends keyof O>(
  object: O,
  key: K,
  updateImmediately = false,
): Observable<O[K]> {
  let value: O[K] = object[key];

  return new Observable((subject) => {
    if (updateImmediately) {
      subject.next(value);
    }

    Object.defineProperty(object, key, {
      get: () => value,
      set: (newValue) => {
        value = newValue;
        subject.next(newValue);
      },
    });
  });
}

export function markAllAsDirty(...controls: AbstractControl[]): void {
  controls.forEach((control) => {
    if (control instanceof FormControl) {
      control.markAsDirty({ onlySelf: false });
      control.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    } else if (control instanceof FormGroup) {
      markAllAsDirty(...Object.values(control.controls));
    } else if (control instanceof FormArray) {
      markAllAsDirty(...control.controls);
    }
  });
}

export function bindToSignal<T>(
  observable: Observable<T>,
  signal: WritableSignal<T>,
  destroyer: DestroyRef | Observable<void>,
): void {
  const onUpdate$ = observable.pipe(tap((value) => signal.set(value)));

  if (isObservable(destroyer)) {
    onUpdate$.pipe(takeUntil(destroyer)).subscribe();
  } else {
    onUpdate$.pipe(takeUntilDestroyed(destroyer)).subscribe();
  }
}

export function validateNonNull<T>(value: T | null | undefined): NonNullable<T> {
  if (value == null) {
    throw new Error("Unexpected null value.");
  }

  return value;
}

const FOCUSABLE_ELEMENTS_SELECTOR = [
  "button:not([disabled])",
  "[href]",
  "input:not([disabled])",
  "select:not([disabled])",
  "textarea:not([disabled])",
  "[tabindex]:not([tabindex='-1']):not([disabled])",
  "details:not([disabled])",
  "summary:not(:disabled)",
].join(",");

export function getFocusableElements(parent: HTMLElement): NodeListOf<HTMLElement> {
  return parent.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTOR);
}

export function getApiErrorMessage(error: unknown): Translatable {
  if (typeof error === "string") {
    return { fr: error, en: error };
  }

  if (typeof error === "object") {
    if (error instanceof HttpErrorResponse && error.error?.body?.error) {
      return toI18nTranslatable(`errors.${error.error.body.error}`);
    }

    if ("message" in error) {
      return {
        fr: error.message,
        en: error.message,
      } as I18nString;
    }
  }

  return toI18nTranslatable("errors.GENERIC_ERROR");
}

export function asSecureUrl(url: string): string {
  return url.replace(/^(http:)?\/\//, "https://");
}

export function asUnsecureUrl(url: string): string {
  return url.replace(/^(https:)?\/\//, "http://");
}

export type ClearedObject<K extends string[]> = { [key in K[number]]: null };

export function createNullPropsObject<P extends string[]>(props: string[]): ClearedObject<P> {
  const object = {} as ClearedObject<P>;

  for (const key of props) {
    object[key] = null;
  }

  return object;
}

export function parseBool(value: unknown): boolean {
  if (typeof value === "string") {
    return value === "true" || value == "0";
  }

  return !!value;
}
