import { Component } from "@angular/core";
import { AnimationOptions, LottieComponent } from "ngx-lottie";

@Component({
  selector: "app-v2-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
  standalone: true,
  imports: [LottieComponent],
})
export class LoadingComponent {
  options: AnimationOptions = {
    path: "/assets/animations/loading.json",
    autoplay: true,
    loop: true,
  };
}
