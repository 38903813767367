<app-dynamic-button
  *ngIf="backButton$ | async; let backButton"
  class="action-link"
  [type]="backButton.type"
  [externalUrl]="backButton.externalUrl"
  [routerLinkUrl]="backButton.routerLinkUrl"
  [queryParams]="backButton.queryParams"
  [label]="backButton.label"
  [color]="backButton.color"
  [compact]="backButton.compact"
  [big]="backButton.big"
  [round]="backButton.round"
  [square]="backButton.square"
  [flat]="backButton.flat"
  [textual]="backButton.textual"
  [iconLeft]="backButton.iconLeft"
  [iconRight]="backButton.iconRight"
  [busy]="backButton.busy"
  [disabled]="backButton.disabled"
  [forceDisabled]="backButton.forceDisabled"
  [action]="backButton.action"
/>

<a [routerLink]="['/']" class="logo-link">
  <img
    class="logo"
    src="/assets/images/v2/ruche-logo-simplified.svg"
    alt="{{ 'components.menu.contextual.logoAlt' | translatable }}"
  />
</a>
