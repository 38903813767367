import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { debounceTime, Observable, Subject, tap } from "rxjs";
import { filter, map } from "rxjs/operators";
import { isNotEmpty } from "../../../helpers/observables/filters";
import { LrdFormFieldContextService } from "../lrd-form-field/lrd-form-field-context.service";

@Component({
  selector: "app-lrd-text-counter",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./lrd-text-counter.component.html",
  styleUrls: ["./lrd-text-counter.component.scss"],
})
export class LrdTextCounterComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  public maxLength: number;

  public value$: Observable<string> = this.formFieldContext.value<string>();

  private destroy$ = new Subject<void>();

  constructor(private formFieldContext: LrdFormFieldContextService) {}

  ngOnInit(): void {
    this.formFieldContext
      .value()
      .pipe(
        debounceTime(500),
        filter(isNotEmpty()),
        map((value) => typeof value),
        tap((valueType) => {
          if (valueType !== "string") {
            throw new Error("<app-lrd-text-counter/> component can only be bound to string value.");
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
