import { Injectable } from "@angular/core";
import { ApiService } from "@app/services/apiService";
import { ApiServicesList } from "@app/shared/enumerations/apiServiceEnums";
import {
  GptLanguage,
  GptTone,
  GptTypeOfText,
  IGptEnhanceTextRequest,
  IGptEnhanceTextResponse,
  IGptGenerateTextResponse,
  IGptGenerateTextRequest,
  IGptTranslateTextRequest,
  IGptTranslateTextResponse,
} from "lrd-interfaces/interfaces";

export interface GptTextResponse {
  text: string;
  tones: GptTone[];
  textType: GptTypeOfText;
}

export interface GptTranslateResponse {
  text: string;
}

export interface generateTextParams {
  formData?: Record<string, string>;
  language?: GptLanguage;
  maxLength?: number;
  textType: GptTypeOfText;
  tones?: GptTone[];
}

export interface enhanceTextParams {
  formData?: Record<string, string>;
  maxLength?: number;
  text: string;
  language: GptLanguage;
  textType: GptTypeOfText;
  tones?: GptTone[];
}

export interface translateTextParams {
  text: string;
  target: GptLanguage;
}

@Injectable({
  providedIn: "root",
})
export class GptService {
  constructor(private apiService: ApiService) {}

  public async generateText({
    formData,
    language,
    maxLength,
    textType,
    tones = [],
  }: generateTextParams): Promise<GptTextResponse> {
    const params: IGptGenerateTextRequest = {
      formData,
      language,
      maxLength,
      textType,
      tones,
    };

    const response: IGptGenerateTextResponse = await this.apiService.post(
      ApiServicesList.UTIL_SERVICE,
      "gpt/generate-text",
      params,
      {},
      true,
    );

    return {
      text: response.text,
      tones: response.tones,
      textType: response.textType,
    };
  }

  public async enhanceText({
    formData,
    maxLength,
    text,
    language,
    textType,
    tones = [],
  }: enhanceTextParams): Promise<GptTextResponse> {
    const params: IGptEnhanceTextRequest = {
      formData,
      maxLength,
      text,
      textType,
      tones,
      language,
    };

    const response: IGptEnhanceTextResponse = await this.apiService.post(
      ApiServicesList.UTIL_SERVICE,
      "gpt/enhance-text",
      params,
      {},
      true,
    );

    return {
      text: response.text,
      tones: response.tones,
      textType: response.textType,
    };
  }

  public async translateText({ text, target }: translateTextParams): Promise<GptTranslateResponse> {
    const translateRequest: IGptTranslateTextRequest = {
      text,
      targetLanguage: target,
    };

    const response: IGptTranslateTextResponse = await this.apiService.post(
      ApiServicesList.UTIL_SERVICE,
      "gpt/translate",
      translateRequest,
      {},
      true,
    );

    return {
      text: response.text,
    };
  }
}

export const GptToneI18NKey: { [key in GptTone]: string } = {
  [GptTone.LIGHTLY_HUMOROUS]: "data.tones.lightly_humorous",
  [GptTone.FRIENDLY]: "data.tones.friendly",
  [GptTone.ENTHOUSIAST]: "data.tones.enthousiast",
  [GptTone.PERSUASIVE]: "data.tones.persuasive",
  [GptTone.EDUCATIONAL]: "data.tones.educational",
  [GptTone.INSPIRATIONAL]: "data.tones.inspirational",
  [GptTone.INFORMATIVE]: "data.tones.informative",
};
