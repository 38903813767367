import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProjectUsersConcurencyComponent } from "./project-users-concurency.component";
import { TranslationsModule } from "@shared/translations/translations.module";

@NgModule({
  declarations: [ProjectUsersConcurencyComponent],
  imports: [CommonModule, TranslationsModule],
  exports: [ProjectUsersConcurencyComponent],
})
export class ProjectUsersConcurencyModule {}
