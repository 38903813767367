import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SnackbarService } from "@app/shared/components/snackbar/snackbar.service";
import { CookieStorageService } from "@shared/cookie-storage.service";
import { noop } from "rxjs";
import { LegacyAppComponent } from "../legacy-app.component";
import { ApiServicesList } from "@shared/enumerations/apiServiceEnums";
import { User } from "@shared/user";
import { LoadingService } from "./common/loading/loading.service";
import { UrlService } from "./urlService";

export enum IErrorHandlingStrategy {
  THROW = "throwError",
  RETURN = "returnError",
}

export interface IErrorHandlingOptions {
  returnError?: boolean;
  errorHandling?: IErrorHandlingStrategy;
  errorMessage?: string;
  ignoreKickOut?: boolean;
  ignoreRedirectionOnKickOut?: boolean;
}

export interface IHttpOptions extends IErrorHandlingOptions {
  parameters?: { [key: string]: any };
}

@Injectable({
  providedIn: "root",
})
export class ApiService {
  public static readonly DISABLE_CACHE: number = 0;

  appComponent: LegacyAppComponent;

  constructor(
    private http: HttpClient,
    public user: User,
    private router: Router,
    private loadingService: LoadingService,
    private snackBar: SnackbarService,
    private cookieStorageService: CookieStorageService,
  ) {}

  setAppComponent(appComponent: LegacyAppComponent) {
    this.appComponent = appComponent;
  }

  public get(
    serviceToContact: ApiServicesList,
    path: string,
    options: IHttpOptions = {},
    skipLoading = false,
  ): Promise<any> {
    if (!skipLoading) {
      this.loadingService.startLoading();
    }
    const url: string = UrlService.getAPICallAddress(serviceToContact, path);

    return this.http
      .get<any>(url, this.getRequestOptions(options))
      .toPromise()
      .catch((error) => this.catchError(error, options))
      .finally(() => this.loadingService.stopLoading());
  }

  public delete(
    serviceToContact: ApiServicesList,
    path: string,
    options: IHttpOptions = {},
  ): Promise<any> {
    this.loadingService.startLoading();
    const url = UrlService.getAPICallAddress(serviceToContact, path);
    return this.http
      .delete<any>(url, this.getRequestOptions(options))
      .toPromise()
      .catch((error) => this.handleErrors(error, options))
      .finally(() => this.loadingService.stopLoading());
  }

  public post(
    serviceToContact: ApiServicesList,
    path: string,
    body: any,
    options: IHttpOptions = {},
    skipLoading = false,
  ): Promise<any> {
    if (!skipLoading) {
      this.loadingService.startLoading();
    }
    const url = UrlService.getAPICallAddress(serviceToContact, path);
    return this.http
      .post<any>(url, body, this.getRequestOptions(options))
      .toPromise()
      .catch((error) => this.catchError(error, options))
      .finally(() => (!skipLoading ? this.loadingService.stopLoading() : noop()));
  }

  public put(
    serviceToContact: ApiServicesList,
    path: string,
    body: any,
    options: IHttpOptions = {},
  ): Promise<any> {
    this.loadingService.startLoading();
    const url = UrlService.getAPICallAddress(serviceToContact, path);
    return this.http
      .put<any>(url, body, this.getRequestOptions(options))
      .toPromise()
      .catch((error) => this.catchError(error, options))
      .finally(() => this.loadingService.stopLoading());
  }

  public getRequestOptions(options: IHttpOptions): any {
    let headers: HttpHeaders = new HttpHeaders();
    let params = new HttpParams();

    if (this.cookieStorageService.token) {
      headers = headers.set("Authorization", this.cookieStorageService.token);
    }

    if (options.parameters) {
      Object.keys(options.parameters)
        .filter((key) => !!options.parameters[key])
        .forEach((key) => {
          params = params.set(key, options.parameters[key].toString());
        });
    }

    const httpOptions = {
      headers,
      params,
    };

    return httpOptions;
  }

  private handleErrors(error: any, options: IErrorHandlingOptions) {
    if (error && !options.ignoreKickOut) {
      this.kickOutUserIfNecessary(error, options.ignoreRedirectionOnKickOut);
    }

    const isJoiErrors = error && error.error && error.error.body && error.error.body.joiErrors;
    const isError5XX = error && error.status && error.status >= 500;

    if (isJoiErrors || isError5XX) {
      this.snackBar.showError(error);
    }

    throw error;
  }

  private kickOutUserIfNecessary(error: HttpEvent<any>, ignoreRedirectionOnKickOut: boolean) {
    if ([401, 403].includes(error["status"]) || [401, 403].includes(error["error"]["statusCode"])) {
      if (
        error["error"]["body"] === "EXPIRED_TOKEN" ||
        error["error"]["body"]["error"] === "EXPIRED_TOKEN"
      ) {
        this.snackBar.showError("EXPIRED_TOKEN");
      }

      console.warn("Authentication or authorization has failed.");

      this.cookieStorageService.removeToken();
      sessionStorage.removeItem("user");

      this.user.refresh();

      if (!ignoreRedirectionOnKickOut) {
        this.router.navigate(["/"]);
      }
    }
  }

  private catchError(error: unknown, options: IHttpOptions) {
    const { errorHandling, returnError } = options;

    switch (true) {
      case errorHandling === IErrorHandlingStrategy.THROW:
        throw error;

      case errorHandling === IErrorHandlingStrategy.RETURN || returnError:
        return error;

      default:
        this.handleErrors(error, options);
        break;
    }
  }
}
