export const METADATA_CONTENT_MAP = [
  {
    key: "home",
    title: {
      fr: "La Ruche | Plateforme de sociofinancement # 1 au Québec",
      en: "#1 crowdfunding platform in Québec | La Ruche",
    },
    description: {
      fr: "Vous avez une idée de projet, vous voulez tester un marché et vous êtes à la recherche de financement? Découvrez comment La Ruche peut vous aider.",
      en: "Do you have a business project in mind? Want to test the market or get funding? Find out how La Ruche can help.",
    },
    image: {
      fr: "/assets/images/social-share-thumbnails/laruchequebec-fr.png",
      en: "/assets/images/social-share-thumbnails/laruchequebec-en.png",
    },
  },
  {
    key: "laruche",
    title: {
      fr: "Located across the province of Québec | La Ruche",
      en: "#1 crowdfunding platform in Québec | La Ruche",
    },
    description: {
      fr: "La Ruche, déjà active un peu partout au Québec, a comme objectif d'être présente dans l'ensemble des régions pour accompagner les idéateurs d'ici.",
      en: "La Ruche, active across Québec, has a mission to support entrepreneurs and innovators in all of the province's regions.",
    },
    image: {
      fr: "/assets/images/social-share-thumbnails/laruchequebec-fr.png",
      en: "/assets/images/social-share-thumbnails/laruchequebec-en.png",
    },
  },
  {
    key: "region",
    title: {
      fr: "La Ruche [regionName] | Campagnes de sociofinancement",
      en: "Crowdfunding campaigns | La Ruche",
    },
    description: {
      fr: "Vous avez une idée de projet, vous voulez tester un marché et vous êtes à la recherche de financement? Découvrez comment La Ruche [regionName] peut vous aider.",
      en: "Do you have a business project in mind? Want to test the market or get funding? Find out how La Ruche can help.",
    },
    image: {
      fr: "/assets/images/social-share-thumbnails/region-[regionName].png",
      en: "/assets/images/social-share-thumbnails/region-[regionName].png",
    },
  },
  {
    key: "ambassadeurs",
    title: {
      fr: "Nos ambassadeurs | La Ruche",
      en: "Our ambassadors | La Ruche",
    },
    description: {
      fr: "Les ambassadeurs de La Ruche sont des personnalités engagées dans votre communauté. Profitez de conseils personnalisés lors de cellules ponctuelles.",
      en: "La Ruche ambassadors are dedicated professionals in your community. Take advantage of their tips during cell presentations.",
    },
    image: {
      fr: "/assets/images/social-share-thumbnails/ambassadeurs-fr.png",
      en: "/assets/images/social-share-thumbnails/ambassadeurs-en.png",
    },
  },
  {
    key: "ateliers",
    title: {
      fr: "Conférences et ateliers | La Ruche",
      en: "Conferences and workshops | La Ruche",
    },
    description: {
      fr: "Participez à nos ateliers ou à nos conférences pour en apprendre plus sur le financement participatif.",
      en: "Participate in La Ruche's workshops and conferences to learn more about the advantages of crowdfunding.",
    },
  },
  {
    key: "ca",
    title: {
      fr: "Notre conseil d'administration | La Ruche",
      en: "Our board of directors | La Ruche",
    },
    description: {
      fr: "Faites connaissance avec les membres du conseil d'administration de La Ruche: des gens impliqués qui ont à coeur notre mission et notre saine gouvernance.",
      en: "Meet the members of La Ruche's board of directors, people who are dedicated to our mission and effective corporate governance.",
    },
  },
  {
    key: "carrieres",
    title: {
      fr: "Carrières | La Ruche",
      en: "Careers | La Ruche",
    },
    description: {
      fr: "Joignez-vous à nore équipe d'experts en financement participatif et contribuez au développement socio-économique du Québec, une campagne à la fois!",
      en: "Join our team of crowdfunding experts and contribute to the socio-economic development of Québec…one campaign at a time!",
    },
    image: {
      fr: "/assets/images/social-share-thumbnails/carriere-fr.png",
      en: "/assets/images/social-share-thumbnails/carriere-en.png",
    },
  },
  {
    key: "cellule",
    title: {
      fr: "La cellule et s'y préparer | La Ruche",
      en: "Preparing for your cell presentation | La Ruche",
    },
    description: {
      fr: "Maximisez vos chances de réussite sur La Ruche en présentant votre projet lors d'une cellule et recevez de judicieux conseils d'ambassadeurs de votre région.",
      en: "Organize a successful crowdfunding campaign on La Ruche. Present your project during a cell work session and get great tips from ambassadors in your region.",
    },
  },
  {
    key: "conseils",
    title: {
      fr: "Conseils et astuces | La Ruche",
      en: "Tips and advice | La Ruche",
    },
    description: {
      fr: "Avant de lancer un projet sur La Ruche, assistez à une séance d'introduction sur le financement participatif et consultez nos guides pratiques.",
      en: "Before launching a campaign on La Ruche, take part in an introductory presentation on crowdfunding and get best practices on how you can reach your goal!",
    },
  },
  {
    key: "contact",
    title: {
      fr: "Contact | La Ruche",
      en: "Contact | La Ruche",
    },
    description: {
      fr: "Vous avez une question ou un commentaire? N'hésitez pas à communiquer avec nous par courriel ou via nos réseaux sociaux. Un humain vous répondra!",
      en: "Do you have any questions or comments? Reach out to our team via email or social media! A real human will respond!",
    },
  },
  {
    key: "contributeurs",
    title: {
      fr: "Information aux contributeurs | La Ruche",
      en: "Information for contributors | La Ruche",
    },
    description: {
      fr: "Recevez une contrepartie et encouragez l'économie locale en contribuant à une campagne sur La Ruche.",
      en: "Get great perks and encourage the local economy by contributing to a crowdfunding campaign on La Ruche.",
    },
  },
  {
    key: "desjardins",
    title: {
      fr: "Notre partenaire principal : Desjardins | La Ruche",
      en: "Our main partner: Desjardins | La Ruche",
    },
    description: {
      fr: "Desjardins est le partenaire principal de La Ruche. Grâce à cette association, nous pouvons optimiser les sources de financement de vos projets.",
      en: "Desjardins is La Ruche's main partner. Thanks to this partnership, we can help optimize your project's funding.",
    },
  },
  {
    key: "distinctions",
    title: {
      fr: "Nos distinctions | La Ruche",
      en: "How we stand out | La Ruche",
    },
    description: {
      fr: "La Ruche, c'est plus qu'une plateforme. C'est un accompagnement humain, un vaste réseau d'ambassadeurs, un accès à du financement additionnel et bien plus!",
      en: "La Ruche is more than a crowdfunding platform. It also offers professional support, a vast network of ambassadors, additional financing, and so much more!",
    },
  },
  {
    key: "equipe",
    title: {
      fr: "Notre équipe | La Ruche",
      en: "Our team | La Ruche",
    },
    description: {
      fr: "Découvrez qui sont les humains derrière La Ruche! Notre équipe est constituée de professionnels passionnés ayant à coeur la réussite de vos projets.",
      en: "Discover the people behind La Ruche! Our team is made up of dedicated professionals that are committed to your project's success.",
    },
  },
  {
    key: "etape-projet",
    title: {
      fr: "Étapes d'un projet | La Ruche",
      en: "Project steps | La Ruche",
    },
    description: {
      fr: "Découvrez  les grandes étapes d'un projet pour bien vous préparer avant de lancer une campagne sur La Ruche.",
      en: "Discover the major steps in a crowdfunding project to prepare your next campaign on La Ruche. Follow the steps for a successful crowdfunding campaign!",
    },
  },
  {
    key: "faq",
    title: {
      fr: "FAQ | La Ruche",
      en: "FAQ | La Ruche",
    },
    description: {
      fr: "Consultez notre FAQ pour trouver réponses à vos questions. Vous ne trouvez pas? N'hésitez pas à nous écrire via notre adresse info!",
      en: "Read our FAQ to find all the answers to your questions. Didn't find what you were looking for? Contact via email today!",
    },
  },
  {
    key: "fonctionnement",
    title: {
      fr: "Notre fonctionnement | La Ruche",
      en: "How we work | La Ruche",
    },
    description: {
      fr: "La Ruche est la plateforme de financement participatif numéro 1 au Québec! Découvrez comment propulser votre idée ou comment contribuer à une campagne.",
      en: "La Ruche is the #1 crowdfunding platform in Québec! Discover how you can turn your idea into reality or contribute to a campaign.",
    },
  },
  {
    key: "logos",
    title: {
      fr: "Éléments graphiques | La Ruche",
      en: "Graphics | La Ruche",
    },
    description: {
      fr: "Communiquez avec notre équipe aux communications pour obtenir notre logo, notre guide de normes graphiques ou pour toute question en lien avec ce sujet.",
      en: "Contact our communications team to receive our logo, graphical norms, or any other request regarding our organization.",
    },
  },
  {
    key: "missions",
    title: {
      fr: "Notre mission : activer les idéateurs d'ici | La Ruche",
      en: "Our mission: enabling local ideators | La Ruche",
    },
    description: {
      fr: "La Ruche est une plateforme de financement participatif qui favorise l’émergence de projets stimulant l’économie, le rayonnement et la vitalité d’une région.",
      en: "La Ruche is a crowdfunding platform that encourages projects that stimulate the economy and promote the vitality of a region.",
    },
  },
  {
    key: "partenaires",
    title: {
      fr: "Autres partenaires | La Ruche",
      en: "Other partners | La Ruche",
    },
    description: {
      fr: "La Ruche compte sur le soutien de plusieurs partenaires aux profils variés, qui partagent notre mission et notre vision de l'entrepreneuriat. Découvrez-les!",
      en: "La Ruche can count on the support of several different partners that share our vision and mission to help local entrepreneurs. Learn more about them!",
    },
  },
  {
    key: "financement-additionnel",
    title: {
      fr: "Obtenir du financement pour son projet | La Ruche",
      en: "Obtain funding for your project | La Ruche",
    },
    description: {
      fr: "Maximisez le financement de votre projet en accédant à une bourse, une subvention ou un prêt grâce aux alliés de La Ruche.",
      en: "Maximize the funding of your project with subsidies, grants or loans from La Ruche partners.",
    },
    image: {
      fr: "/assets/images/social-share-thumbnails/financement-additionnel-fr.png",
      en: "/assets/images/social-share-thumbnails/financement-additionnel-en.png",
    },
  },
  {
    key: "explorer-projets",
    title: {
      fr: "Explorer les projets | La Ruche",
      en: "Explore all projects | La Ruche",
    },
    description: {
      fr: "Retrouvez l’ensemble des campagnes sur La Ruche et recevez une contrepartie en contribuant au succès de celles qui sont présentement en cours.",
      en: "Discover all of La Ruche's current campaigns. Get great perks when you contribute to La Ruche's different projects.",
    },
    image: {
      fr: "/assets/images/social-share-thumbnails/explorer-projets-fr.png",
      en: "/assets/images/social-share-thumbnails/explorer-projets-en.png",
    },
  },
  {
    key: "lancer-projet",
    title: {
      fr: "Lancer un projet | La Ruche ",
      en: "Launch a project | La Ruche",
    },
    description: {
      fr: "Vous avez une idée de projet que vous aimeriez activer ou tester sur La Ruche? Connectez-vous à notre plateforme et lancez votre campagne, une étape à la fois.",
      en: "Do you have a business project in mind that you would like to test out on La Ruche? Follow our step-by-step guide and launch your campaign!",
    },
    image: {
      fr: "/assets/images/social-share-thumbnails/lancer-projet-fr.png",
      en: "/assets/images/social-share-thumbnails/lancer-projet-en.png",
    },
  },
  {
    key: "nouvelles",
    title: {
      fr: "Quoi de neuf | La Ruche ",
      en: "What's new | La Ruche",
    },
    description: {
      fr: "Suivez toutes les actualités de La Ruche sur notre blogue !",
      en: "Follow all the news from La Ruche on our blog !",
    },
    image: {
      fr: "/assets/images/social-share-thumbnails/lancer-projet-fr.png",
      en: "/assets/images/social-share-thumbnails/lancer-projet-en.png",
    },
  },
  {
    key: "admin",
    title: {
      fr: "Administration | La Ruche ",
      en: "Administration | La Ruche ",
    },
  },
  {
    key: "project",
    title: {
      fr: "Gérer mon projet | La Ruche ",
      en: "Manage my project | La Ruche ",
    },
  },
  {
    key: "organization",
    title: {
      fr: "Gérer mon organisation | La Ruche ",
      en: "Manage my organization | La Ruche ",
    },
  },
  {
    key: "profil",
    title: {
      fr: "Mon profil | La Ruche ",
      en: "My profile | La Ruche ",
    },
  },
  {
    key: "cartsuccess",
    title: {
      fr: "Merci! | La Ruche ",
      en: "Thank you! | La Ruche ",
    },
  },
  {
    key: "nos-services",
    title: {
      fr: "La Ruche | Solution de financement 100% québécoise",
      en: "Découvrez comment La Ruche peut vous aider à financer votre projet grâce au sociofinancement et à des programmes de financement additionnel exclusifs.",
    },
    description: {
      fr: "La Ruche | 100% Quebec-based financing solution",
      en: "Find out how La Ruche can help you finance your project through crowdfunding and exclusive additional financing programs to achieve your objectives.",
    },
  },
];
