import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InlineEditorComponent } from "./inline-editor.component";
import { QuillModule } from "ngx-quill";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslationsModule } from "@shared/translations/translations.module";

@NgModule({
  declarations: [InlineEditorComponent],
  imports: [CommonModule, QuillModule, FormsModule, ReactiveFormsModule, TranslationsModule],
  exports: [InlineEditorComponent],
})
export class InlineEditorModule {}
