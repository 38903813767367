<div class="value-container" title="{{ valueText$ | async }}">
  <span class="value">{{ valueText$ | async }}</span>

  <app-v2-icon *ngIf="!isLoading; else loading" class="icon" icon="arrow-bottom" size="medium" />

  <ng-template #loading>
    <mat-spinner [diameter]="24" />
  </ng-template>
</div>

<div *ngIf="isOpen" class="options" @dropdownAnimation>
  <div class="options__wrapper">
    <ng-content select="app-lrd-option" />
  </div>
</div>
