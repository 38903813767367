<div class="content" [ngClass]="trackingClass">
  <div class="icon-left" *ngIf="iconLeft">
    <app-v2-icon [icon]="iconLeft" [size]="big ? 'large' : 'medium'" />
  </div>

  <ng-container>
    <ng-container *ngIf="!label; else content">
      <ng-content />
    </ng-container>

    <ng-template #content>
      {{ label | translatable }}
    </ng-template>

    <mat-spinner *ngIf="busy" diameter="20" />
  </ng-container>

  <div class="icon-right" *ngIf="iconRight">
    <app-v2-icon [icon]="iconRight" [size]="big ? 'large' : 'medium'" />
  </div>
</div>
