import { Component, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface IPopupAction {
  label: string;
  color?: string;
  onClick: () => void;
}

export interface IPopupOptions {
  text: string;
  title?: string;
  boldText?: boolean;
  onClose?: () => void;
  actions?: IPopupAction[];
  closeButtonHidden?: boolean;
}

@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.scss"],
})
export class PopupComponent {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public options: IPopupOptions,
  ) {}

  onAction(action: IPopupAction) {
    if (action.onClick) action.onClick();

    this.dialogRef.close();
  }

  close() {
    if (this.options.onClose) {
      this.options.onClose();
    }
    this.dialogRef.close();
  }
}
