import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "@app/app.module";
import * as Sentry from "@sentry/angular";
import { environment } from "./environments/environment";
import packageInfo from "../package.json";

Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [],
  release: packageInfo.version,
  environment: environment.environment,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
