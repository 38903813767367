<div class="container" (click)="handleOnSelectCard()">
  <div class="body" [innerHTML]="content"></div>

  <div class="footer">
    <p class="tones">
      {{ formatedTones }}
    </p>

    <p class="length">
      {{ formatedLength | translatable }}
    </p>

    <div class="lock">
      <button
        app-v2-button
        [square]="true"
        [compact]="true"
        [iconLeft]="lockIcon"
        (click)="handleOnToggleLock($event)"
      ></button>
    </div>
  </div>
</div>
