import { Injectable } from "@angular/core";
import { AbstractControl, FormControlStatus, ValidationErrors, ValidatorFn } from "@angular/forms";
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
  Subject,
  switchMap,
} from "rxjs";
import { distinctUntilChanged, map, startWith } from "rxjs/operators";

const DEFAULT_OPTIONS = Object.freeze({
  allowIcon: true,
} as LrdFormFieldOptions);

@Injectable()
export class LrdFormFieldContextService {
  private readonly _fieldId = new ReplaySubject<string>(1);
  private readonly fieldId$ = this._fieldId.asObservable();

  private readonly _options = new BehaviorSubject({ ...DEFAULT_OPTIONS });
  private readonly options$ = this._options.asObservable();

  private readonly _labelClick = new Subject<MouseEvent>();
  private readonly labelClick$ = this._labelClick.asObservable();

  private _formControl = new ReplaySubject<AbstractControl>(1);

  private readonly value$: Observable<unknown> = this._formControl.pipe(
    switchMap((formControl) => formControl.valueChanges.pipe(startWith(formControl.value))),
    distinctUntilChanged(),
  );

  private readonly status$: Observable<FormControlStatus> = this._formControl.pipe(
    switchMap((formControl) => formControl.statusChanges),
    distinctUntilChanged(),
  );

  private readonly errors$: Observable<ValidationErrors> = this._formControl.pipe(
    switchMap((formControl) => {
      return LrdFormFieldContextService.getErrorsObservable(formControl);
    }),
  );

  /**
   * Must be called as soon as the consumer component
   * finds its form control object as many other observables
   * in this service rely on it.
   */
  public setControl(control: AbstractControl): void {
    this._formControl.next(control);
  }

  public errors(): Observable<ValidationErrors> {
    return this.errors$;
  }

  public value<T>(): Observable<T> {
    return this.value$ as Observable<T>;
  }

  public isEmpty(): Observable<boolean> {
    return this.value$.pipe(map((value) => value == null || value === ""));
  }

  public setFieldId(id: string): void {
    this._fieldId.next(id);
  }

  public fieldId(): Observable<string> {
    return this.fieldId$;
  }

  public fieldLabelId(): Observable<string> {
    return this.fieldId$.pipe(map((fieldId) => `lrd-label-for_${fieldId}`));
  }

  public status(): Observable<FormControlStatus> {
    return this.status$;
  }

  public options(): Observable<LrdFormFieldOptions> {
    return this.options$;
  }

  public setOptions(options: Partial<LrdFormFieldOptions>): void {
    const currentOptions = this._options.getValue();
    this._options.next({ ...currentOptions, ...options });
  }

  public triggerLabelClick(event: MouseEvent): void {
    this._labelClick.next(event);
  }

  public labelClick(): Observable<MouseEvent> {
    return this.labelClick$;
  }

  public hasValidator(validator: ValidatorFn): Observable<boolean> {
    return this._formControl.pipe(
      switchMap((control) => {
        return combineLatest([control.valueChanges, control.statusChanges]).pipe(
          startWith(undefined),
          map(() => control.hasValidator(validator)),
        );
      }),
    );
  }

  public static getErrorsObservable(control: AbstractControl): Observable<ValidationErrors> {
    const statusChange$ = control.statusChanges.pipe(startWith(control.status));
    const valueChange$ = control.valueChanges.pipe(startWith(control.value));

    return combineLatest([statusChange$, valueChange$]).pipe(
      map(() => {
        const { untouched, pristine, valid, errors } = control;

        if (untouched || pristine || valid) {
          return null;
        }

        return errors;
      }),
      distinctUntilChanged(),
    );
  }
}

interface LrdFormFieldOptions {
  allowIcon?: boolean;
}
