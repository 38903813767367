import { NgModule } from "@angular/core";
import { registerLocaleData } from "@angular/common";

import { TRANS_DEFAULT_LANG, TranslateService } from "@shared/translations/translate.service";
import { TranslatePipe } from "@shared/translations/translate.pipe";
import localeFr from "@angular/common/locales/fr";
import localeFrExtra from "@angular/common/locales/extra/fr";
import { RouteTranslationService } from "@shared/translations/route-translation.service";

export function setupTranslateFactory(service: TranslateService): Function {
  return () => service.use(service.detectLanguage());
}

registerLocaleData(localeFr, "fr-CA", localeFrExtra);

export interface TranslationsModuleConfig {
  defaultLang?: string;
}

@NgModule({
  declarations: [TranslatePipe],
  exports: [TranslatePipe],
})
export class TranslationsModule {
  static forRoot(conf: TranslationsModuleConfig = {}): any {
    return {
      ngModule: TranslationsModule,
      providers: [
        {
          provide: TRANS_DEFAULT_LANG,
          useValue: conf.defaultLang || "fr",
        },
        TranslatePipe,
        TranslateService,
        RouteTranslationService,
      ],
    };
  }
}
