import { DestroyRef, Injectable } from "@angular/core";
import {
  BehaviorSubject,
  combineLatest,
  delay,
  NEVER,
  Observable,
  of,
  ReplaySubject,
  share,
} from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { isNotEmpty } from "../../../../helpers/arrays/filters";
import { isNotEqualTo } from "../../../../helpers/observables/mappers";
import { throwDevError } from "../../../utils";
import BindableRef from "../../../utils/bindable-ref";

@Injectable({
  providedIn: "root",
})
export class LrdSelectContextService<T> {
  private readonly isMultiSelect$ = new BehaviorSubject(false);

  private readonly _maxSelectionLengthValue = new BehaviorSubject(null);
  private readonly maxSelectionLength$ = this.createMaxSelectionLengthObservable();
  private readonly maxSelectionLengthRef = BindableRef.bindTo(
    this.maxSelectionLength$,
    this.destroyRef,
  );

  private readonly _options = new ReplaySubject<T[]>();
  private readonly optionsRef = BindableRef.bindTo(this._options, this.destroyRef);

  private readonly _selectedIndexes = new ReplaySubject<number[]>();
  private readonly selectedIndexesRef = BindableRef.bindTo(this._selectedIndexes, this.destroyRef);

  private readonly isReady$ = this._options.pipe(delay(0), map(isNotEqualTo(null, false)));

  private readonly selectedOptions$ = this.createSelectedOptionsObservable();
  private readonly selectedOptionsRef = new BindableRef<T[]>().bindTo(this.selectedOptions$);

  private readonly fieldValue$ = combineLatest([this.isMultiSelect$, this.selectedOptions$]).pipe(
    map(([isMultiSelect, selectedValues]) => {
      return isMultiSelect ? selectedValues : selectedValues[0];
    }),
  );

  constructor(private destroyRef: DestroyRef) {}

  public isReady(): Observable<boolean> {
    return this.isReady$;
  }

  public areAllSelected(): boolean {
    return this.optionsRef.get()?.length === this.selectedOptionsRef.get()?.length;
  }

  public setOptions(values: T[]): void {
    this._options.next(values);
  }

  public select(index: number): void {
    const selectedIndexes = this.selectedIndexesRef.get();
    const isMultiSelect = this.getIsMultiSelect();
    const maxSelectionLength = this.getMaxSelectionLength();

    console.log("LrdSelectContextService", 0);

    if (isMultiSelect && selectedIndexes.length === maxSelectionLength) {
      console.log("LrdSelectContextService", 1);
      return;
    }

    const allOptions = this.optionsRef.get();
    const selectedIndexesCopy = [...selectedIndexes];

    if (allOptions[index] === undefined) {
      console.error(`Couldn't find index ${index} in current options.`);
      return;
    }

    console.log("LrdSelectContextService", 2);

    if (this.getIsMultiSelect()) {
      if (!selectedIndexesCopy.includes(index)) {
        console.log("LrdSelectContextService", 3);
        selectedIndexesCopy.push(index);
      }

      console.log("LrdSelectContextService", 4);

      this._selectedIndexes.next(selectedIndexesCopy);
      return;
    }

    console.log("LrdSelectContextService", 5);

    this._selectedIndexes.next([index]);
  }

  public selectAll(): void {
    if (!this.getIsMultiSelect()) {
      throwDevError("Trying to call selectAll() on non-multiselect instance.");
      return;
    }

    const allOptions = this.optionsRef.get();
    const allOptionsIndexes = allOptions.map((_, index) => index);

    this._selectedIndexes.next(allOptionsIndexes);
  }

  public deselectAll(): void {
    if (!this.getIsMultiSelect()) {
      throwDevError("Trying to call deselectAll() on non-multiselect instance.");
    }

    this._selectedIndexes.next([]);
  }

  public deselect(index: number): void {
    const allOptions = this.optionsRef.get();
    const selectedIndexesCopy = [...this.selectedIndexesRef.get()];

    if (allOptions[index] === undefined) {
      console.error(`Couldn't find index ${index} in current options.`);
      return;
    }

    if (this.getIsMultiSelect()) {
      const selectedOptionIndexIndex = selectedIndexesCopy.indexOf(index);

      if (selectedOptionIndexIndex !== -1) {
        selectedIndexesCopy.splice(selectedOptionIndexIndex, 1);
      }

      this._selectedIndexes.next(selectedIndexesCopy);
      return;
    }

    this._selectedIndexes.next([]);
  }

  public fieldValue(): Observable<T | T[]> {
    return this.fieldValue$;
  }

  public selectedOptions(): Observable<T[]> {
    return this.selectedOptions$;
  }

  public getSelectedOptions(): T[] {
    return this.selectedOptionsRef.get();
  }

  public setSelectedOptions(options: T | T[], trackByKey?: keyof T): void {
    const newSelectedOptions: T[] = Array.isArray(options) ? options : [options];

    const allOptions = this.optionsRef.get();
    const selectedIndexes = allOptions
      .map((option, index) => {
        return this.isSelectedOption(option, newSelectedOptions, trackByKey) ? index : null;
      })
      .filter(isNotEmpty());

    this._selectedIndexes.next(selectedIndexes);
  }

  private isSelectedOption(option: T, newSelectedOptions: T[], trackByKey: keyof T): boolean {
    if (trackByKey) {
      const matchIndex = newSelectedOptions.findIndex((selectedOption) => {
        if (selectedOption === null || option === null) {
          return selectedOption === option;
        } else {
          return selectedOption[trackByKey] === option[trackByKey];
        }
      });

      return matchIndex !== -1;
    }

    return newSelectedOptions.includes(option);
  }

  public setIsMultiSelect(value: boolean): void {
    this.isMultiSelect$.next(value);
  }

  public getIsMultiSelect(): boolean {
    return this.isMultiSelect$.getValue();
  }

  public setMaxSelectionLength(value: number): void {
    this._maxSelectionLengthValue.next(value);
  }

  public getMaxSelectionLength(): number {
    return this.maxSelectionLengthRef.get();
  }

  private createMaxSelectionLengthObservable(): Observable<number> {
    return combineLatest([this.isMultiSelect$, this._maxSelectionLengthValue]).pipe(
      map(([isMultiselect, maxSelectionLengthValue]) => {
        return isMultiselect ? maxSelectionLengthValue : 1;
      }),
    );
  }

  private createSelectedOptionsObservable() {
    return combineLatest([this._options, this._selectedIndexes]).pipe(
      switchMap((value) => (value[0] != null ? of(value) : NEVER)),
      map(([values, selectedIndexes]) => {
        return values.filter((value, index) => selectedIndexes.includes(index));
      }),
      share(),
    );
  }
}
