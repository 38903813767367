export enum ApiServicesList {
  ORGANIZATION_SERVICE,
  USER_SERVICE,
  VARIOUS_SERVICE,
  ABOUT_PAGE_SERVICE,
  GENERATED_SERVICE,
  SYSTEM_SERVICE,
  PROJECT_SERVICE,
  UTIL_SERVICE,
  PROGRAM_SERVICE,
  SOCIAL_SERVICE,
  CONTRIBUTIONS_SERVICE,
  PAYMENTS_SERVICE,
  SEARCH_SERVICE,
  HELLO_SERVICE,
  PROMOTION_BANNER_SERVICE,
}

export enum ApiVersionsList {
  VERSION_1 = "v1",
}
