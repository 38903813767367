import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ButtonComponent } from "../button/button.component";
import { GptService } from "@app/services-v2/gpt.service";
import { GptLanguage } from "lrd-interfaces/interfaces";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslatableService } from "@app/shared-v2/services/translatable.service";
import { BehaviorSubject, Observable, combineLatest, map } from "rxjs";
import { CommonModule } from "@angular/common";
import { asFormControlValueObservable } from "@app/helpers/observables/helpers";
import { isEmptyValue } from "@app/helpers/observables/mappers";

export type SupportedLanguage = "en" | "fr";

@Component({
  standalone: true,
  selector: "app-ai-translator",
  templateUrl: "./ai-translator.component.html",
  styleUrls: ["./ai-translator.component.scss"],
  imports: [ButtonComponent, CommonModule],
})
export class AiTranslatorComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() sourceFormControlName: string;
  @Input() sourceLanguage: SupportedLanguage;
  @Input() targetFormControlName: string;
  @Input() targetLanguage: SupportedLanguage;

  isDisabled$: Observable<boolean>;
  isLoading$ = new BehaviorSubject(false);

  constructor(
    private gptService: GptService,
    private snakeBar: MatSnackBar,
    private translatableService: TranslatableService,
  ) {}

  ngOnInit(): void {
    this.isDisabled$ = this.createButtonIsDisabledObservable();
  }

  public handleTranslation(): void {
    this.translate();
  }

  private createButtonIsDisabledObservable(): Observable<boolean> {
    const sourceFormControl = this.form.get(this.sourceFormControlName);
    const sourceValues$ = asFormControlValueObservable(sourceFormControl);

    return combineLatest([this.isLoading$, sourceValues$.pipe(map(isEmptyValue()))]).pipe(
      map(([loading, sourceFormControlIsEmpty]) => loading || sourceFormControlIsEmpty),
    );
  }

  private valueIsEmpty(value: string): boolean {
    return value === null || value === "";
  }

  private async translate() {
    try {
      this.isLoading$.next(true);

      const sourceText = this.form.get(this.sourceFormControlName).value;

      const translation = await this.gptService.translateText({
        text: sourceText,
        target: this.targetLanguage as GptLanguage,
      });

      this.form.get(this.targetFormControlName).setValue(translation.text);
    } catch (error) {
      const message = this.translatableService.transform("errors.GENERIC_ERROR");

      this.snakeBar.open(message, null, { panelClass: "lrd-error", duration: 5_000 });
    } finally {
      this.isLoading$.next(false);
    }
  }
}
