import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { I18nString } from "lrd-interfaces/interfaces";
import { LoggerType } from "../../services-v2/logger/logger-type";
import Logger from "../../services-v2/logger/logger.service";
import {
  I18nTranslatable,
  isI18nString,
  isI18nTranslatable,
  SupportedLanguage,
  Translatable,
} from "../../types";

@Injectable({
  providedIn: "root",
})
export class TranslatableService {
  private readonly logger = Logger.withName("TranslatableService", LoggerType.SERVICE);

  constructor(private translateService: TranslateService) {}

  public transform(
    value: null | Translatable,
    params?: object,
    fallbackOptions?: TranslationFallbackOptions,
  ): string | null {
    if (!value) {
      return null;
    }

    if (typeof value === "string") {
      return this.translateService.instant(value, params);
    }

    // It’s a Translatable string coming from localization system, translate it.
    if (isI18nTranslatable(value)) {
      return this.handleI18nTranslatable(value);
    }

    // It’s a I18String value coming from the API, use the appropriate language.
    if (isI18nString(value)) {
      return this.handleI18nString(value, fallbackOptions);
    }

    this.logger.warn("[Translatable] Unknown translatable value", value);
  }

  public transformWithUnit(value: number | string, unit: TranslatableUnit): string {
    return this.transform(`units.${unit}`, { value });
  }

  public exists(translatable: Translatable): boolean {
    if (isI18nString(translatable)) {
      return true;
    }

    const key: string = isI18nTranslatable(translatable) ? translatable.key : translatable;
    const translationResult: string = this.translateService.instant(key, {});

    return translationResult.length > 0 && translationResult !== key;
  }

  private handleI18nTranslatable(value: I18nTranslatable): string {
    const translatedParams = {};

    Object.keys(value.params ?? {}).forEach((key) => {
      translatedParams[key] = this.transform(value.params[key]);
    });

    return this.translateService.instant(value.key, translatedParams);
  }

  private handleI18nString(
    value: I18nString,
    fallbackOptions?: TranslationFallbackOptions,
  ): string {
    if (fallbackOptions?.isTranslationRequired === false) {
      return value[fallbackOptions.lang];
    }

    const { currentLang } = this.translateService;

    return value[currentLang] ?? value[oppositeLanguage(currentLang)];
  }
}

export enum TranslatableUnit {
  HOURS = "duration.hours.short",
  HOURS_LONG = "duration.hours.long",
}

function oppositeLanguage(currentLanguage: string) {
  return currentLanguage === "fr" ? "en" : "fr";
}

const DEFAULT_SINGULAR_KEY = "singular";
const DEFAULT_PLURAL_KEY = "plural";
const DEFAULT_PLURAL_THRESHOLD = 1;

export function pluralize(baseKeyOrConfig: string | TranslationKeyConfig, count: number): string {
  if (typeof baseKeyOrConfig === "string") {
    const countIsPlural = count > DEFAULT_PLURAL_THRESHOLD;
    return `${baseKeyOrConfig}.${countIsPlural ? DEFAULT_PLURAL_KEY : DEFAULT_SINGULAR_KEY}`;
  }

  const {
    base,
    pluralKey,
    singularKey,
    pluralThreshold = DEFAULT_PLURAL_THRESHOLD,
  } = baseKeyOrConfig;
  const countIsPlural = count > pluralThreshold;
  return `${base}.${countIsPlural ? pluralKey : singularKey}`;
}

export interface TranslationKeyConfig {
  base: string;
  singularKey: string;
  pluralKey: string;
  pluralThreshold?: number;
}

export interface TranslationFallbackOptions {
  isTranslationRequired?: boolean;
  lang?: SupportedLanguage;
}
