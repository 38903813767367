export enum Icons {
  ADD_CIRCLE = "add-circle",
  ALLIES = "allies",
  ALLIES_GREEN = "allies-green",
  ALLIES_GREEN_ALT = "allies-green-alt",
  ALLIES_YELLOW = "allies-yellow",
  ALLIES_YELLOW_ALT = "allies-yellow-alt",
  ANNOUNCE = "announce",
  ARROW_BOTTOM = "arrow-bottom",
  ARROW_BOTTOM_LEFT = "arrow-bottom-left",
  ARROW_BOTTOM_RIGHT = "arrow-bottom-right",
  ARROW_LEFT = "arrow-left",
  ARROW_RIGHT = "arrow-right",
  ARROW_TOP = "arrow-top",
  ARROW_TOP_LEFT = "arrow-top-left",
  ARROW_TOP_RIGHT = "arrow-top-right",
  BURGER = "burger",
  CARET_DOWN = "caret-down",
  CHECK = "check",
  FORM = "form",
  FORM_WHITE_ALT = "form-white-alt",
  CONNECT = "connect",
  CONTACT_EMAIL = "contact-email",
  CONTACT_PHONE = "contact-phone",
  CONTRIBUTION_CASH = "contribution-cash",
  CONTRIBUTION_CASH_ALT = "contribution-cash-alt",
  CONTRIBUTION_CASH_PLUS_SIGN = "contribution-cash-plus-sign",
  CONTRIBUTION_CASH_WHITE_ALT = "contribution-cash-white-alt",
  CONTRIBUTION_EXPERT = "contribution-expert",
  CONTRIBUTION_EXPERT_LAVENDER = "contribution-expert-lavender",
  CONTRIBUTION_EXPERT_YELLOW = "contribution-expert-yellow",
  CONTRIBUTION_EXPERT_YELLOW_ALT = "contribution-expert-yellow-alt",
  CONTRIBUTION_MATERIAL = "contribution-material",
  CONTRIBUTION_MATERIAL_ALT = "contribution-material-alt",
  CONTRIBUTION_VOLUNTEER_ALT = "contribution-volunteer-alt",
  CONTRIBUTOR_BLUE = "contributor-blue",
  CONTRIBUTOR_BLUE_ALT = "contributor-blue-alt",
  CONTRIBUTOR_VOLUNTEER_BLACK_ALT = "contribution-volunteer-black-alt",
  CONTRIBUTOR_YELLOW = "contributor-yellow",
  CONTRIBUTOR_YELLOW_ALT = "contributor-yellow-alt",
  COUNTERPARTS = "counterparts",
  CROSS = "cross",
  DISCUSS = "discuss",
  DISCUSS_WHITE_ALT = "discuss-white-alt",
  DOCUMENT_WHITE_ALT = "document-white-alt",
  DOLLAR_SIGN = "dollar-sign",
  EDIT_PEN = "edit-pen",
  EDIT_PEN_YELLOW_ALT = "edit-pen-yellow-alt",
  EMAIL = "email",
  EMAIL_SMALL = "email-small",
  EXCHANGE = "exchange",
  FILTERS = "filters",
  GIFT_CARD = "gift_card",
  GROUP = "group",
  HANDSHAKE = "handshake",
  HANDSHAKE_WHITE_ALT = "handshake-white-alt",
  HELP = "help",
  INFO = "info",
  INFORMATION_WHITE_ALT = "information-white-alt",
  JOIN_THE_HIVE = "join-the-hive",
  LIKE = "like",
  LINK = "link",
  LINK_WHITE_ALT = "link-white-alt",
  LINK_EXTERNAL = "link-external",
  LOCATION = "location",
  LOCATION_WHITE_ALT = "location-white-alt",
  LOCK = "lock",
  MOBILIZE = "mobilize",
  OBJECTIVES_WHITE_ALT = "objectives-white-alt",
  PANIER_BLEU = "panier_bleu",
  PLAY = "play",
  QUESTION = "question",
  TRASH = "trash",
  TRASH_MONOCHROME = "trash-monochrome",
  PRINT = "print",
  PROFILE = "profile",
  REFRESH_WHITE = "refresh-white",
  SAVE = "save",
  SEARCH = "search",
  SETTINGS = "settings",
  SHARE = "share",
  SHOPPING_CART = "shopping_cart",
  SPARK = "spark",
  SOCIAL_FACEBOOK = "social-facebook",
  SOCIAL_FACEBOOK_FILLED = "social-facebook-filled",
  SOCIAL_GOOGLE = "social-google",
  SOCIAL_INSTAGRAM = "social-instagram",
  SOCIAL_LINKED_IN = "social-linkedin",
  SOCIAL_LINKED_IN_FILLED = "social-linkedin-filled",
  SOCIAL_THREAD = "social-thread",
  SOCIAL_X = "social-x",
  SUCCESS_WHITE_ALT = "success-white-alt",
  THANKS = "thanks",
  THUMBS_DOWN = "thumbs-down",
  THUMBS_UP = "thumbs-up",
  UNLOCK = "unlock",
  VISIBILITY_ON = "visibility_on",
  VISIBILITY_OFF = "visibility_off",
}
