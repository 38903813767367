import { Renderer2 } from "@angular/core";

export const loadGeneratedBootstrapScript = (renderer: Renderer2, document: Document) => {
  const script = renderer.createElement("script");

  script.type = "text/javascript";
  script.src = "/generated/bootstrap.js";

  renderer.appendChild(document.body, script);
};
