import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarComponent } from "./snackbar.component";
import { TranslatePipe } from "src/app/shared/translations/translate.pipe";
import { CartPopupComponent } from "@app/ui/project/shared/components/cart-popup/cart-popup.component";
import { TranslateService } from "@shared/translations/translate.service";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {}

  public showMessage(message: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: "confirm",
      data: {
        message: this.translate.transform(message),
        snackType: "Message",
      },
    });
  }

  public showError(error?: any, defaultMessage = "GLOBAL_ERROR") {
    let message = defaultMessage;
    let fallbackMessage = defaultMessage;

    if (typeof error === "string") {
      message = error;
    } else if (error && error.error && error.error.body) {
      const errorBody = error.error.body;
      if (errorBody.managed === true) {
        message = errorBody.error;
      } else if (errorBody.joiErrors === true) {
        message = "ERROR";
        errorBody.errors[0].path.forEach((pathElement) => {
          if (typeof pathElement === "string") {
            message = `${message}_${pathElement.toUpperCase()}`;
          }
        });
        fallbackMessage = errorBody.errors[0].message
          ? errorBody.errors[0].message
          : fallbackMessage;
      }
    }
    this.translate.load(this.translate.currentLanguage).then(() => {
      message =
        this.translate.transform(message) === message
          ? this.translate.transform(fallbackMessage)
          : this.translate.transform(message);

      this.snackBar.openFromComponent(SnackbarComponent, {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "top",
        panelClass: "fail",
        data: { message, snackType: "Error" },
      });
    });
  }

  public showTextError(message: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: "fail",
      data: { message, snackType: "Error" },
    });
  }
}
