import { hasSomeProps } from "@shared-v2/utils/helpers";
import { I18nString } from "lrd-interfaces/interfaces";

export interface PageViewModel {
  metadata: PageMetadata;
}

export interface PageMetadata {
  linkTags?: LinkTag[];
  title?: Translatable;
  tags?: MetaTag[];
}

export function isPageMetadata(value: unknown): value is PageMetadata {
  return typeof value === "object" && hasSomeProps<PageMetadata>(value, ["title", "tags"]);
}

export interface MetaTag {
  name: string;
  content: Translatable;
}

export declare type LinkTag = {
  id: string;
  charset?: string;
  crossOrigin?: string;
  href?: string;
  hrefLang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};

export interface I18nTranslatable {
  key: string;
  params?: object;
}

export enum SupportedLanguage {
  FR = "fr",
  EN = "en",
}

export function toI18nTranslatable(value: string, params?: object): I18nTranslatable {
  return { key: value, params };
}

export type Translatable = I18nTranslatable | I18nString | string;

export function isI18nTranslatable(value: unknown): value is I18nTranslatable {
  return typeof value === "object" && "key" in value;
}

export function isI18nString(value: unknown): value is I18nString {
  return typeof value === "object" && ("fr" in value || "en" in value);
}
