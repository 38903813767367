<ng-container [ngSwitch]="type">
  <button
    *ngSwitchCase="'button'"
    app-v2-button
    class="button"
    [useImmutableViewModel]="useImmutableViewModel"
    [attr.title]="accessibleTitle | translatable"
    [ngClass]="trackingClass"
    [ngStyle]="customStyles"
    [color]="color"
    [light]="light"
    [compact]="compact"
    [flat]="flat"
    [iconLeft]="iconLeft"
    [iconRight]="iconRight"
    [label]="label"
    [round]="round"
    [disabled]="disabled"
    [busy]="busy"
    [textual]="textual"
    [action]="action"
  ></button>

  <a
    *ngSwitchCase="'externalLink'"
    app-v2-button
    class="button"
    [useImmutableViewModel]="useImmutableViewModel"
    [attr.title]="accessibleTitle | translatable"
    [ngClass]="trackingClass"
    [ngStyle]="customStyles"
    [color]="color"
    [light]="light"
    [compact]="compact"
    [flat]="flat"
    [iconLeft]="iconLeft"
    [iconRight]="iconRight"
    [label]="label"
    [round]="round"
    [busy]="busy"
    [forceDisabled]="disabled"
    [textual]="textual"
    [href]="externalUrl | translatable"
    (click)="action?.($event)"
    target="_blank"
  ></a>

  <a
    *ngSwitchCase="'routerLink'"
    app-v2-button
    class="button"
    [useImmutableViewModel]="useImmutableViewModel"
    [attr.title]="accessibleTitle | translatable"
    [ngClass]="trackingClass"
    [ngStyle]="customStyles"
    [routerLink]="routerLinkUrl ?? routerLink"
    [queryParams]="queryParams"
    [color]="color"
    [light]="light"
    [compact]="compact"
    [flat]="flat"
    [iconLeft]="iconLeft"
    [iconRight]="iconRight"
    [label]="label"
    [round]="round"
    [busy]="busy"
    [forceDisabled]="disabled"
    [textual]="textual"
    (click)="action?.($event)"
  ></a>
</ng-container>
