import { Renderer2 } from "@angular/core";

export const loadLinkedinPixelScript = (renderer: Renderer2, document: Document) => {
  loadScript(renderer, document);
  loadNoScript(renderer, document);
};

const loadScript = (renderer: Renderer2, document: Document) => {
  const script = renderer.createElement("script");

  script.type = "text/plain";
  script.setAttribute("data-cookieconsent", "preferences,marketing,statistics");
  script.innerHTML = `
    _linkedin_partner_id = "2680396";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);

    (function () {
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";
      b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);
    })();
  `;

  renderer.appendChild(document.body, script);
};

const loadNoScript = (renderer: Renderer2, document: Document): HTMLScriptElement => {
  const script = renderer.createElement("noscript");

  script.type = "text/javascript";
  script.innerHTML = `
      <img
        height="1"
        width="1"
        style="display: none"
        alt=""
        src="https://px.ads.linkedin.com/collect/?pid=2680396&fmt=gif"
      />
        `;
  renderer.appendChild(document.body, script);

  return script;
};
