import {
  booleanAttribute,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  NgModel,
} from "@angular/forms";
import { Subject, takeUntil, tap } from "rxjs";
import Logger from "../../../services-v2/logger/logger.service";
import { LrdFormFieldContextService } from "./lrd-form-field-context.service";

@Directive()
export abstract class BaseLrdFieldDirective<T = unknown, E extends Element = HTMLElement>
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input({ transform: booleanAttribute })
  set disabled(isDisabled: boolean) {
    this.onDisabledChange(isDisabled);
  }

  @Input()
  value: T;

  @Output()
  valueChange = new EventEmitter<T>();

  @HostBinding("aria-labelledby")
  accessibilityLabelledBy: string;

  protected control?: FormControl;

  protected onChange?: (value: T) => void;
  protected onTouched?: () => void;

  public get touched(): boolean {
    return this.control?.touched;
  }

  protected allowIcon = true;
  protected destroy$ = new Subject<void>();

  constructor(
    @Inject(Injector) protected injector: Injector,
    @Optional() protected formFieldContext: LrdFormFieldContextService,
    protected elementRef: ElementRef<E>,
  ) {}

  ngOnInit(): void {
    this.setupComponentControl();
    this.setupAccessibilityLabelledByUpdate();
    this.setup?.();
    this.formFieldContext?.setOptions({ allowIcon: this.allowIcon });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  public registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.onDisabledChange(isDisabled);
  }

  public abstract writeValue(value: T): void;

  protected abstract onDisabledChange(isDisabled: boolean): void;

  protected setup?(): void;

  protected setValue(value: T, emitChange = true): void {
    if (value !== this.value) {
      this.value = value;

      if (emitChange) {
        this.valueChange.emit(value);
        this.onChange?.(value);
      }
    }
  }

  private setupComponentControl(): void {
    try {
      const injectedControl: NgControl = this.injector.get(NgControl);

      switch (injectedControl.constructor) {
        case NgModel: {
          const { control, update } = injectedControl as NgModel;

          this.control = control;
          this.control.valueChanges
            .pipe(
              tap((value) => update.emit(value)),
              takeUntil(this.destroy$),
            )
            .subscribe();
          break;
        }

        case FormControlName: {
          this.control = this.injector
            .get(FormGroupDirective)
            .getControl(injectedControl as FormControlName);
          break;
        }

        default: {
          this.control = (injectedControl as FormControlDirective).form as FormControl;
          break;
        }
      }
    } catch (e) {
      Logger.error(e);
    }
  }

  private setupAccessibilityLabelledByUpdate(): void {
    if (this.formFieldContext == null) {
      return;
    }

    this.formFieldContext
      .fieldLabelId()
      .pipe(
        tap((labelId) => (this.accessibilityLabelledBy = labelId)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  protected markAsTouched(): void {
    this.onTouched?.();
  }
}
