import { CommonModule, registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { APP_INITIALIZER, NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Data } from "@shared/data";
import { GeneratedDataProvider } from "@shared/generated-data-provider";
import { SharedModule } from "@shared/shared.module";
import { TranslationsModule } from "@shared/translations/translations.module";
import { User } from "@shared/user";
import { CookieService } from "ngx-cookie-service";
import { provideEnvironmentNgxMask } from "ngx-mask";
import { QuillModule } from "ngx-quill";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MetadataService } from "./services-v2/metadata/metadata.service";
import { AboutPagesService } from "./services/aboutPagesService";
import { NotificationService } from "./services/notificationService";
import { ScriptService } from "src/app/services/script.service";
import { environment } from "../environments/environment";
import {
  TranslateModule,
  TranslateLoader,
  TranslatePipe,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import * as Sentry from "@sentry/angular";

import { LottieModule, provideCacheableAnimationLoader } from "ngx-lottie";
import player from "lottie-web";
import appPackage from "../../package.json";

registerLocaleData(localeFr);

export const DEFAULT_LANGUAGE = "fr";
export const AVAILABLE_LOCALES = ["fr", "en"];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", `-v2.json?v=${appPackage.version}`);
}

export function AppInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang(DEFAULT_LANGUAGE);

    return translate.use(DEFAULT_LANGUAGE).toPromise();
  };
}

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    LottieModule.forRoot({ player: playerFactory }),
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslationsModule.forRoot(),
    QuillModule.forRoot(),
    SharedModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: DEFAULT_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    User,
    GeneratedDataProvider,
    NotificationService,
    Data,
    AboutPagesService,
    CookieService,
    ScriptService,
    MetadataService,
    provideEnvironmentNgxMask({ validation: false }),
    { provide: "googleTagManagerId", useValue: environment.gtmCode },
    TranslatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    provideCacheableAnimationLoader(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
})
export class AppModule {}
