import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Deferrer } from "@shared-v2/utils/Deferrer";
import appPackage from "../../../package.json";

export enum DynamicStylesheet {
  V1 = "v1",
  V2 = "v2",
  HYBRID = "hybrid",
  ADMIN = "admin",
}

export const ALL_DYNAMIC_STYLESHEETS = Object.values(DynamicStylesheet);

@Injectable({ providedIn: "root" })
export class DynamicStylesService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public use(stylesheets: DynamicStylesheet[]): DynamicStylesheetChange {
    return this.load(stylesheets);
  }

  private load(stylesheets: DynamicStylesheet[]): DynamicStylesheetChange {
    const otherStylesheets = this.getOtherDynamicStylesheets(stylesheets);
    const newStylesheets: HTMLLinkElement[] = [];

    const promises = stylesheets.map((stylesheet) => {
      const existingStylesheetEl = this.document.getElementById(stylesheet);

      if (existingStylesheetEl) {
        return Promise.resolve(existingStylesheetEl);
      }

      const deferrer = new Deferrer<HTMLLinkElement>();
      const fileName = DynamicStylesService.toFileName(stylesheet);
      const styleEl = this.document.createElement("link");

      styleEl.id = stylesheet;
      styleEl.rel = "stylesheet";
      styleEl.href = `${fileName}?v=${appPackage.version}`;

      styleEl.addEventListener("load", () => {
        deferrer.resolve(styleEl);
      });

      newStylesheets.push(styleEl);

      this.document.head.appendChild(styleEl);

      return deferrer.promise();
    });

    return {
      elements: newStylesheets,
      promise: Promise.all(promises).then(() => {
        otherStylesheets.forEach((stylesheet) => stylesheet.remove());
        return Promise.resolve();
      }),
    };
  }

  private getOtherDynamicStylesheets(requestedStylesheets: DynamicStylesheet[]) {
    return ALL_DYNAMIC_STYLESHEETS.filter(
      (stylesheet) => !requestedStylesheets.includes(stylesheet),
    )
      .map((stylesheet) => this.document.getElementById(stylesheet))
      .filter(Boolean);
  }

  private static toFileName(stylesheet: DynamicStylesheet): string {
    return `${stylesheet}-styles.css`;
  }
}

export interface DynamicStylesheetChange {
  elements: HTMLLinkElement[];
  promise: Promise<void>;
}
