import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Icons } from "@app/shared-v2/icons";
import { User } from "@app/shared/user";
import { Observable, shareReplay } from "rxjs";
import { map } from "rxjs/operators";
import { Colors } from "src/app/shared-v2/colors";
import { AvailableRoutes } from "../../../services-v2/routing/available-routes.enum";
import { RoutingService } from "../../../services-v2/routing/routing.service";
import { toI18nTranslatable } from "../../../types";
import {
  DynamicButtonType,
  DynamicButtonViewModel,
} from "../dynamic-button/dynamic-button-view-model";
import { DynamicLinkType } from "../dynamic-link/dynamic-link-view-model";
import { DesktopMenuViewModel } from "./desktop/desktop-view-model";
import { MenuItemType } from "./menu-view-model";
import { MobileMenuViewModel } from "./mobile/mobile-view-model";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class MenuFactory {
  private readonly window: Window;

  constructor(
    @Inject(DOCUMENT) document: Document,
    private user: User,
    private routingService: RoutingService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.window = document.defaultView;
  }

  public desktopMenuFromDefault(): DesktopMenuViewModel {
    return {
      topBar: {
        switchLanguage: this.buildLanguageSwitchButton(),
        actions$: this.buildActionsObservable(),
        contactUs: {
          bold: false,
          type: "routerLink",
          routerLink: this.routingService.routeFor(AvailableRoutes.CONTACT_US),
        },
        profile: { icon: Icons.PROFILE },
        user: this.user.userChange$,
        userDropdown: {
          adminLink: {
            bold: false,
            type: "routerLink",
            routerLink: ["/", "admin"],
          },
          profileLink: {
            bold: false,
            type: "routerLink",
            routerLink: this.routingService.routeFor(AvailableRoutes.USER_PROFILE),
          },
          createOrganizationLink: {
            bold: false,
            type: "routerLink",
            routerLink: this.routingService.routeFor(AvailableRoutes.CREATE_ORGANIZATION),
          },
        },
      },
      homeRouterLink: this.routingService.routeFor(AvailableRoutes.HOME),
      items: [
        {
          type: "dropdown" as MenuItemType,
          label: { key: "components.menu.main.one.label" },
          items: [
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.one.secondary.one" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.OUR_SERVICES),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.one.secondary.two" },
              link: {
                type: "externalLink" as DynamicLinkType,
                externalUrl: this.routingService
                  .routeFor(AvailableRoutes.ADDITIONAL_FINANCING)
                  .join(""),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.one.secondary.three" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.LAUNCH_CAMPAIGN),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.one.secondary.four" },
              link: {
                type: "externalLink" as DynamicLinkType,
                externalUrl: this.routingService
                  .routeFor(AvailableRoutes.INFORMATION_SESSION)
                  .join(""),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.one.secondary.five" },
              link: {
                type: "externalLink" as DynamicLinkType,
                externalUrl: this.routingService
                  .routeFor(AvailableRoutes.FREE_CONSULTATION)
                  .join(""),
              },
            },
          ],
        },
        {
          type: "link" as MenuItemType,
          label: { key: "components.menu.main.two.label" },
          link: {
            type: "routerLink" as DynamicLinkType,
            routerLink: this.routingService.routeFor(AvailableRoutes.CONTRIBUTE),
          },
        },
        {
          type: "dropdown" as MenuItemType,
          label: { key: "components.menu.main.three.label" },
          items: [
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.three.secondary.one" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.ABOUT),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.three.secondary.two" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.TEAM),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.three.secondary.three" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.BOARD),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.three.secondary.four" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.PRESS),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.three.secondary.five" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.NEWS),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.three.secondary.six" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.CAREER),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.three.secondary.seven" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.FAQ),
              },
            },
          ],
        },
        {
          type: "dropdown" as MenuItemType,
          label: { key: "components.menu.main.four.label" },
          items: [
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.four.secondary.one" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.PARTNER_DESJARDINS),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.four.secondary.two" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.PARTNERS),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.four.secondary.three" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.AMBASSADORS),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.four.secondary.four" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.ALLIES),
              },
            },
          ],
        },
      ],
      callToAction: {
        label: { key: "components.menu.call-to-action.label" },
        type: "routerLink" as DynamicButtonType,
        color: Colors.YELLOW,
        routerLink: this.routingService.routeFor(AvailableRoutes.LAUNCH_CAMPAIGN),
      },
    };
  }

  public mobileMenuFromDefault(): MobileMenuViewModel {
    return {
      topBar: {
        actions$: this.buildActionsObservable(),
        menu: {
          icon: Icons.BURGER,
        },
        user: this.user.userChange$,
        profile: { icon: Icons.PROFILE },
        userDropdown: {
          adminLink: {
            bold: false,
            type: "routerLink",
            routerLink: ["/", "admin"],
          },
          profileLink: {
            bold: false,
            type: "routerLink",
            routerLink: this.routingService.routeFor(AvailableRoutes.USER_PROFILE),
          },
          createOrganizationLink: {
            bold: false,
            type: "routerLink",
            routerLink: this.routingService.routeFor(AvailableRoutes.CREATE_ORGANIZATION),
          },
        },
      },
      homeRouterLink: this.routingService.routeFor(AvailableRoutes.HOME),
      items: [
        {
          type: "dropdown" as MenuItemType,
          label: { key: "components.menu.main.one.label" },
          items: [
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.one.secondary.one" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.OUR_SERVICES),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.one.secondary.two" },
              link: {
                type: "externalLink" as DynamicLinkType,
                externalUrl: this.routingService
                  .routeFor(AvailableRoutes.ADDITIONAL_FINANCING)
                  .join(""),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.one.secondary.three" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.LAUNCH_CAMPAIGN),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.one.secondary.four" },
              link: {
                type: "externalLink" as DynamicLinkType,
                externalUrl: this.routingService
                  .routeFor(AvailableRoutes.INFORMATION_SESSION)
                  .join(""),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.one.secondary.five" },
              link: {
                type: "externalLink" as DynamicLinkType,
                externalUrl: this.routingService
                  .routeFor(AvailableRoutes.FREE_CONSULTATION)
                  .join(""),
              },
            },
          ],
        },
        {
          type: "link" as MenuItemType,
          label: { key: "components.menu.main.two.label" },
          link: {
            type: "routerLink" as DynamicLinkType,
            routerLink: this.routingService.routeFor(AvailableRoutes.CONTRIBUTE),
          },
        },
        {
          type: "dropdown" as MenuItemType,
          label: { key: "components.menu.main.three.label" },
          items: [
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.three.secondary.one" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.ABOUT),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.three.secondary.two" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.TEAM),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.three.secondary.three" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.BOARD),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.three.secondary.four" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.PRESS),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.three.secondary.five" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.NEWS),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.three.secondary.six" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.CAREER),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.three.secondary.seven" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.FAQ),
              },
            },
          ],
        },
        {
          type: "dropdown" as MenuItemType,
          label: { key: "components.menu.main.four.label" },
          items: [
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.four.secondary.one" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.PARTNER_DESJARDINS),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.four.secondary.two" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.PARTNERS),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.four.secondary.three" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.AMBASSADORS),
              },
            },
            {
              type: "link" as MenuItemType,
              label: { key: "components.menu.main.four.secondary.four" },
              link: {
                type: "routerLink" as DynamicLinkType,
                routerLink: this.routingService.routeFor(AvailableRoutes.ALLIES),
              },
            },
          ],
        },
        {
          type: "link" as MenuItemType,
          label: { key: "components.menu.main.five.label" },
          link: {
            type: "routerLink" as DynamicLinkType,
            routerLink: this.routingService.routeFor(AvailableRoutes.CONTACT_US),
          },
        },
        {
          type: "link",
          label: { key: "alternate-language-full" },
          link: {
            type: "externalLink" as DynamicLinkType,
            externalUrl: this.routingService
              .alternateLanguageRouteFor(AvailableRoutes.HOME)
              .join(""),
            target: "_self",
          },
        },
      ],
      callToAction: {
        label: { key: "components.menu.call-to-action.label" },
        type: "routerLink" as DynamicButtonType,
        color: Colors.YELLOW,
        compact: true,
        routerLink: this.routingService.routeFor(AvailableRoutes.LAUNCH_CAMPAIGN),
      },
    };
  }

  private buildLanguageSwitchButton(): DynamicButtonViewModel {
    return {
      type: "button",
      label: toI18nTranslatable("alternate-language"),
      accessibleTitle: toI18nTranslatable("alternate-language-full"),
      textual: true,
      light: true,
      action: () => {
        const currentRoute = this.routingService.getCurrentRoute();
        const translatedRoute = this.routingService.alternateLanguageRouteFor(
          currentRoute ?? AvailableRoutes.HOME,
          this.routingService.getAllRoutesParams(),
        );

        const queryParams = new URLSearchParams(this.activatedRoute.snapshot.queryParams);
        const queryParamsString = queryParams.size > 0 ? `?${queryParams.toString()}` : "";

        this.window.location.href = `${translatedRoute.join("/")}${queryParamsString}`;
      },
    };
  }

  private buildActionsObservable(): Observable<DynamicButtonViewModel[]> {
    const SEARCH_BUTTON: DynamicButtonViewModel = this.buildSearchButton();

    return this.user.userChange$.pipe(
      map((user) => {
        if (user == null) {
          return [SEARCH_BUTTON];
        }

        return [this.buildChatButton(), SEARCH_BUTTON];
      }),
      shareReplay(1),
    );
  }

  private buildSearchButton(): DynamicButtonViewModel {
    return {
      type: "routerLink",
      routerLinkUrl: this.routingService.routeFor(AvailableRoutes.SEARCH),
      iconLeft: Icons.SEARCH,
      textual: true,
      accessibleTitle: toI18nTranslatable("pages.search.title"),
      trackingClass: "gtm-recherche",
    };
  }

  private buildChatButton(): DynamicButtonViewModel {
    return {
      type: "routerLink",
      routerLinkUrl: this.routingService.routeFor(AvailableRoutes.CHAT),
      iconLeft: Icons.EMAIL_SMALL,
      textual: true,
      accessibleTitle: toI18nTranslatable("pages.chat.title"),
    };
  }
}
