import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IconComponent } from "src/app/shared-v2/components/icon/icon.component";
import { TranslatablePipe } from "@app/shared-v2/pipes/translatable.pipe";
import { SupportedLanguage } from "../../../types";
import { ButtonComponent } from "../button/button.component";
import { GptService } from "@app/services-v2/gpt.service";
import { BehaviorSubject, Observable, combineLatest, map, of, startWith } from "rxjs";
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AiDrawerCardViewModel } from "./card/ai-drawer-card-view-model";
import { AiDrawerControlsComponent } from "./controls/ai-drawer-controls.component";
import { AiDrawerCardComponent } from "./card/ai-drawer-card.component";
import { v4 as uuid } from "uuid";
import { GptLanguage, GptTone, GptTypeOfText } from "lrd-interfaces/interfaces";
import { AiDrawerLoadingCardComponent } from "./loading-card/loading-card.component";
import { TranslatableService } from "@app/shared-v2/services/translatable.service";
import { MatSnackBar } from "@angular/material/snack-bar";

const NUMBER_OF_OPTIONS = 3;

const PREDEFINED_TONES = [[GptTone.INFORMATIVE], [GptTone.ENTHOUSIAST], [GptTone.INSPIRATIONAL]];

enum ACTION {
  GENERATE = "GENERATE",
  ENHANCE = "ENHANCE",
  TRANSLATE = "TRANSLATE",
}

enum STATUS {
  BUSY = "BUSY",
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  HIDDEN = "HIDDEN",
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    TranslatablePipe,
    ButtonComponent,
    AiDrawerControlsComponent,
    AiDrawerCardComponent,
    AiDrawerLoadingCardComponent,
  ],
  selector: "app-ai-drawer",
  templateUrl: "./ai-drawer.component.html",
  styleUrls: ["./ai-drawer.component.scss"],
})
export class AiDrawerComponent implements OnInit {
  @Input() formToEdit: UntypedFormControl;
  @Input() alternativeLanguageForm: UntypedFormControl;
  @Input() language: SupportedLanguage | string;
  @Input() alternativeLanguage: SupportedLanguage | string;
  @Input() formDataForGpt: Record<string, string>;
  @Input() maxChar: number;
  @Input() textType: GptTypeOfText = GptTypeOfText.PROJECT_DESCRIPTION;
  @Input() canTranslate = true;
  @Input() canEnhance = true;
  @Input() canGenerate = true;

  @Output() changeLanguageTabTrigger = new EventEmitter<null>();

  cards$ = new BehaviorSubject<AiDrawerCardViewModel[]>([]);
  isDrawerOpen$: Observable<boolean>;
  activeAction$: BehaviorSubject<ACTION> = new BehaviorSubject(null);
  generateActionStatus$: Observable<STATUS>;
  enhanceActionStatus$: Observable<STATUS>;
  translateActionStatus$: Observable<STATUS>;
  formValue$: Observable<string>;
  readyToEnhance$: Observable<boolean>;
  generateButtonLabel$: Observable<string>;
  enhanceButtonLabel$: Observable<string>;
  translateButtonLabel$: Observable<string>;

  actionForm: UntypedFormGroup;
  status = STATUS;

  constructor(
    private formBuilder: FormBuilder,
    private gptService: GptService,
    private translatableService: TranslatableService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.actionForm = this.initialiseActionForm();

    this.formValue$ = this.formToEdit.valueChanges;

    const observables = [
      this.formValue$.pipe(startWith(this.formToEdit.value)),
      this.activeAction$,
    ];

    this.generateActionStatus$ = combineLatest([...observables, of(this.canGenerate)]).pipe(
      map(this.mapGenerateActionStatus),
    );

    this.enhanceActionStatus$ = combineLatest([...observables, of(this.canEnhance)]).pipe(
      map(this.mapEnhanceActionStatus),
    );

    this.translateActionStatus$ = combineLatest([...observables, of(this.canTranslate)]).pipe(
      map(this.mapTranslateActionStatus),
    );

    this.isDrawerOpen$ = combineLatest([
      this.activeAction$.pipe(startWith(null)),
      this.cards$.pipe(startWith([])),
    ]).pipe(map(([activeAction, cards]) => activeAction === ACTION.ENHANCE || cards.length > 0));

    const buttonLabelObservable = combineLatest([of(this.canGenerate), of(this.canEnhance)]);

    this.generateButtonLabel$ = of(
      `1. ${this.translatableService.transform("components.ai-drawer.actions.generate")}`,
    );

    this.enhanceButtonLabel$ = buttonLabelObservable.pipe(
      map(([canGenerate]) => {
        const number = [canGenerate].filter((can) => can).length + 1;

        return `${number}. ${this.translatableService.transform(
          "components.ai-drawer.actions.enhance",
        )}`;
      }),
    );

    this.translateButtonLabel$ = buttonLabelObservable.pipe(
      map(([canGenerate, canEnhance]) => {
        const number = [canGenerate, canEnhance].filter((can) => can).length + 1;

        const translateStringSlug =
          this.alternativeLanguage === "fr"
            ? "components.ai-drawer.actions.translate-to-french"
            : "components.ai-drawer.actions.translate-to-english";

        return `${number}. ${this.translatableService.transform(translateStringSlug)}`;
      }),
    );
  }

  handleOnGenerateContentClick() {
    this.generateContent();
  }

  handleOnEnhanceContentClick() {
    this.enhanceContent();
  }

  handleOnTranslateContentClick() {
    this.translateContent();
  }

  handleOnToggleLock(id: string) {
    const cards = this.cards$.getValue();
    const card = cards.find((card) => card.id === id);
    card.locked = !card.locked;
    this.cards$.next(cards);
  }

  handleOnSelectCard(id: string) {
    const cards = this.cards$.getValue();
    const card = cards.find((card) => card.id === id);
    this.formToEdit.setValue(card.content);
  }

  handleOnSubmitEnhanceForm() {
    this.enhanceContent();
  }

  private initialiseActionForm() {
    return this.formBuilder.group({
      tones: [""],
      length: this.formBuilder.control(this.maxChar, Validators.max(this.maxChar)),
    });
  }

  private mapGenerateActionStatus([formValue, activeAction, canGenerate]) {
    if (!canGenerate) return STATUS.HIDDEN;

    if (activeAction === ACTION.GENERATE) return STATUS.BUSY;

    if (formValue && formValue.length > 0) return STATUS.DISABLED;

    if (!activeAction) return STATUS.ENABLED;

    return STATUS.DISABLED;
  }

  private mapEnhanceActionStatus([formValue, activeAction, canEnhance]) {
    if (!canEnhance) return STATUS.HIDDEN;

    if (activeAction === ACTION.ENHANCE) return STATUS.BUSY;

    if (!formValue || formValue.length <= 0) return STATUS.DISABLED;

    if (!activeAction) return STATUS.ENABLED;

    return STATUS.DISABLED;
  }

  private mapTranslateActionStatus([formValue, activeAction, canTranslate]) {
    if (!canTranslate) return STATUS.HIDDEN;

    if (activeAction === ACTION.TRANSLATE) return STATUS.BUSY;

    if (!formValue || formValue.length <= 0) return STATUS.DISABLED;

    if (!activeAction) return STATUS.ENABLED;

    return STATUS.DISABLED;
  }

  private async generateContent() {
    try {
      this.activeAction$.next(ACTION.GENERATE);

      const length = this.textType === GptTypeOfText.FAQ_ANSWER ? 150 : this.maxChar;

      const response = await this.gptService.generateText({
        formData: this.formDataForGpt,
        language: this.GptLanguageMapper(this.language),
        maxLength: length,
        textType: this.textType,
        tones: [GptTone.INFORMATIVE],
      });

      this.formToEdit.setValue(response.text);
    } catch (error) {
      console.error(error);
      this.openErrorSnackBar();
    } finally {
      this.activeAction$.next(null);
    }
  }

  private async enhanceContent() {
    try {
      this.activeAction$.next(ACTION.ENHANCE);

      const lockedCards = this.cards$.getValue().filter((card) => card.locked);
      const selectedTones = this.actionForm.get("tones").value || [];
      const length = this.actionForm.get("length").value || this.maxChar;

      this.cards$.next([...lockedCards]);

      const formData = this.formDataForGpt;

      if (lockedCards.length > 0) {
        lockedCards.forEach((card, index) => {
          formData[`exemple${index}`] = card.content;
        });
      }

      const requests = Array(NUMBER_OF_OPTIONS - lockedCards.length)
        .fill([])
        .map((_, index) => {
          if (selectedTones.length <= 0) return PREDEFINED_TONES[index];

          if (selectedTones.length > index) return [selectedTones[index]];

          return [selectedTones[0]];
        })
        .map((tones) =>
          this.gptService.enhanceText({
            formData,
            maxLength: length,
            language: this.GptLanguageMapper(this.language),
            text: this.formToEdit.value,
            textType: this.textType,
            tones,
          }),
        );

      const responses = await Promise.all(requests);

      const newCards = responses.map((response) => ({
        id: uuid(),
        content: response.text,
        tones: response.tones,
        locked: false,
      }));

      this.cards$.next([...lockedCards, ...newCards]);
    } catch (error) {
      console.error(error);

      this.openErrorSnackBar();
    } finally {
      this.actionForm.markAsUntouched();
      this.activeAction$.next(null);
    }
  }

  private async translateContent() {
    try {
      this.activeAction$.next(ACTION.TRANSLATE);

      const response = await this.gptService.translateText({
        text: this.formToEdit.value,
        target: this.GptLanguageMapper(this.alternativeLanguage),
      });

      this.alternativeLanguageForm.setValue(response.text);
      this.changeLanguageTabTrigger.emit();
    } catch (error) {
      console.error(error);

      this.openErrorSnackBar();
    } finally {
      this.activeAction$.next(null);
    }
  }

  private openErrorSnackBar() {
    const message = this.translatableService.transform("errors.GENERIC_ERROR");

    this.snackBar.open(message, null, { panelClass: "lrd-error", duration: 5_000 });
  }

  private GptLanguageMapper(language: SupportedLanguage | string): GptLanguage {
    return language === SupportedLanguage.FR ? GptLanguage.FRENCH : GptLanguage.ENGLISH;
  }
}
