import { AvailableRoutes } from "./available-routes.enum";

export type TranslatableRoutes = {
  [key: string]: {
    [route in AvailableRoutes]: string[] | string;
  };
};

export const Routes: TranslatableRoutes = {
  fr: {
    ABOUT: ["a-propos", "mission"],
    ADDITIONAL_FINANCING: "https://finadd.laruchequebec.com/",
    ALLIES: ["a-propos", "financement-additionnel"],
    AMBASSADORS: ["a-propos", "ambassadeurs"],
    BOARD: ["a-propos", "ca"],
    CAREER: ["a-propos", "carrieres"],
    CHAT: ["chat"],
    CONTACT_US: ["a-propos", "contact"],
    CONTRIBUTE: ["projets"],
    CREATE_CAMPAIGN: ["l", "projets", "nouveau"],
    CREATE_ORGANIZATION: ["organisations", "nouveau"],
    DISCLAIMER: ["a-propos", "terms"],
    EDIT_ORGANIZATION_PROFILE: ["organisations", ":slug", "modifier"],
    FAQ: ["a-propos", "faq"],
    FREE_CONSULTATION: "https://consultation.laruchequebec.com/fr/",
    GIFT_CARD: ["carte-cadeau", ":giftCardId"],
    HOME: [],
    INFORMATION_SESSION: "https://seanceinfo.laruchequebec.com/",
    INVOICE_CONTRIBUTION: ["l", "factures", "contribution", ":id"],
    INVOICE_PROMOTER: ["l", "factures", "promoteur", ":id"],
    LAUNCH_CAMPAIGN: ["a-propos", "debuter-campagne"],
    MANAGE_PROJECT: ["l", "projets", ":id", "gerer"],
    NEWS: ["nouvelles"],
    NEWS_ARTICLE: ["nouvelles", ":slug"],
    ORGANIZATION_PUBLIC_PROFILE: ["organisations", ":slug"],
    ORGANIZATION_PROFILE: ["organisations", ":slug", "modifier"],
    OUR_SERVICES: ["a-propos", "services"],
    PARTNERS: ["a-propos", "partenaires"],
    PARTNER_DESJARDINS: ["a-propos", "desjardins"],
    PRESS: ["a-propos", "salle-de-presse"],
    PRIVACY_POLICY: ["a-propos", "legal"],
    PROJECT: ["projets", ":slug"],
    PROJECTS: ["projets"],
    PROJECT_CONTRIBUTION: ["projets", ":slug", "contribution"],
    PROJECT_CONTRIBUTION_SELECT: ["projets", ":slug", "contribution", "choisir"],
    PROJECT_CONTRIBUTION_LOGIN: ["projets", ":slug", "contribution", "connexion"],
    PROJECT_CONTRIBUTION_PAYMENT: ["projets", ":slug", "contribution", "paiement"],
    PROJECT_CONTRIBUTION_PAYMENT_CONFIRMATION: [
      "projets",
      ":slug",
      "contribution",
      "confirmation",
      ":cartId",
    ],
    PROJECT_CONTRIBUTIONS: ["projets", ":slug", "contributions"],
    PROJECT_STATISTICS: ["l", "projets", ":id", "statistiques"],
    PROJECT_SUMMARY: ["l", "projets", ":id", "sommaire"],
    UPDATE_PROJECT: ["l", "projets", ":id", "modifier"],
    SEARCH: ["recherche"],
    TEAM: ["a-propos", "equipe"],
    USER_PROFILE: ["utilisateurs", "moi"],
    USER_PUBLIC_PROFILE: ["utilisateurs", ":slug"],
  },

  en: {
    ABOUT: ["about", "mission"],
    ADDITIONAL_FINANCING: "https://finadd.laruchequebec.com/en",
    ALLIES: ["about", "financement-additionnel"],
    AMBASSADORS: ["about", "ambassadeurs"],
    BOARD: ["about", "ca"],
    CHAT: ["chat"],
    CAREER: ["about", "carrieres"],
    CONTACT_US: ["about", "contact"],
    CONTRIBUTE: ["projects"],
    CREATE_CAMPAIGN: ["l", "projects", "new"],
    CREATE_ORGANIZATION: ["organizations", "new"],
    DISCLAIMER: ["about", "terms"],
    EDIT_ORGANIZATION_PROFILE: ["organizations", ":slug", "edit"],
    FAQ: ["about", "faq"],
    FREE_CONSULTATION: "https://consultation.laruchequebec.com/en/",
    GIFT_CARD: ["gift-card", ":giftCardId"],
    HOME: [],
    INFORMATION_SESSION: "https://seanceinfo.laruchequebec.com/en",
    INVOICE_CONTRIBUTION: ["l", "invoices", "contribution", ":id"],
    INVOICE_PROMOTER: ["l", "invoices", "promoter", ":id"],
    LAUNCH_CAMPAIGN: ["about", "debuter-campagne"],
    MANAGE_PROJECT: ["l", "projects", ":id", "manage"],
    NEWS: ["news"],
    NEWS_ARTICLE: ["news", ":slug"],
    ORGANIZATION_PUBLIC_PROFILE: ["organizations", ":slug"],
    ORGANIZATION_PROFILE: ["organizations", ":slug", "edit"],
    OUR_SERVICES: ["about", "services"],
    PARTNERS: ["about", "partenaires"],
    PARTNER_DESJARDINS: ["about", "desjardins"],
    PRESS: ["about", "salle-de-presse"],
    PRIVACY_POLICY: ["about", "legal"],
    PROJECT: ["projects", ":slug"],
    PROJECTS: ["projects"],
    PROJECT_CONTRIBUTION: ["projects", ":slug", "contribution"],
    PROJECT_CONTRIBUTION_SELECT: ["projects", ":slug", "contribution", "select"],
    PROJECT_CONTRIBUTION_LOGIN: ["projects", ":slug", "contribution", "login"],
    PROJECT_CONTRIBUTION_PAYMENT: ["projects", ":slug", "contribution", "payment"],
    PROJECT_CONTRIBUTION_PAYMENT_CONFIRMATION: [
      "projects",
      ":slug",
      "contribution",
      "confirmation",
      ":cartId",
    ],
    PROJECT_CONTRIBUTIONS: ["projects", ":slug", "contributions"],
    PROJECT_STATISTICS: ["l", "projects", ":id", "statistics"],
    PROJECT_SUMMARY: ["l", "projects", ":id", "summary"],
    UPDATE_PROJECT: ["l", "projects", ":id", "edit"],
    SEARCH: ["search"],
    TEAM: ["about", "equipe"],
    USER_PROFILE: ["users", "me"],
    USER_PUBLIC_PROFILE: ["users", ":slug"],
  },
};

/**
 * Params names used in actual route declaration sometimes defer
 * from the ones used in {@link Routes}.
 *
 * This is a workaround to get URL language switching work without
 * changing params names all over the app.
 *
 * @key Actual param name used in real routes declarations
 * @value Param aliases used in {@link Routes}
 */
export const ROUTE_PARAMS_ALIASES = {
  projectId: ["slug", "id"],
  organizationId: ["slug"],
  userId: ["slug"],
  newsId: ["slug"],
};

export function getAliasesForRouteParam(paramName: string): string[] {
  return ROUTE_PARAMS_ALIASES[paramName] ?? [];
}

export function withRouteParamAliases(params: Record<string, any>): Record<string, any> {
  const paramsCopy = { ...params };

  for (const paramName in params) {
    const paramAliases = getAliasesForRouteParam(paramName);
    const paramValue = params[paramName];

    for (const alias of paramAliases) {
      paramsCopy[alias] = paramValue;
    }
  }

  return paramsCopy;
}
