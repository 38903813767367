import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { Observable } from "rxjs";
import { TranslatablePipe } from "../../pipes/translatable.pipe";
import { DynamicButtonViewModel } from "../dynamic-button/dynamic-button-view-model";
import { DynamicButtonComponent } from "../dynamic-button/dynamic-button.component";

@Component({
  selector: "app-contextual-nav-bar",
  standalone: true,
  imports: [CommonModule, TranslatablePipe, RouterLink, DynamicButtonComponent],
  templateUrl: "./contextual-nav-bar.component.html",
  styleUrls: ["./contextual-nav-bar.component.scss"],
})
export class ContextualNavBarComponent implements ContextualNavBarViewModel {
  @Input() backButton$: Observable<DynamicButtonViewModel>;
}

export interface ContextualNavBarViewModel {
  backButton$: Observable<DynamicButtonViewModel>;
}

export function isContextualNavBarViewModel(object: unknown): object is ContextualNavBarViewModel {
  return typeof object === "object" && "backButton$" in object;
}
