<button
  app-v2-button
  color="purple"
  [compact]="true"
  (click)="handleTranslation()"
  [disabled]="isDisabled$ | async"
  [busy]="isLoading$ | async"
>
  <span class="language-label">{{ sourceLanguage }}</span> →
  <span class="language-label">{{ targetLanguage }}</span>
</button>
