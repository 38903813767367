import { environment } from "../../../environments/environment";
import Logger from "../../services-v2/logger/logger.service";

export const NO_OP = () => void 0;

export function getElementOffsetTop(element: Element): number {
  return element.getBoundingClientRect().top + window.scrollY;
}

export function throwDevError(error: unknown): void {
  if (environment.production) {
    Logger.error(error);
    return;
  }

  if (typeof error === "string") {
    throw new Error(error);
  }

  throw error;
}
