export enum NavBarType {
  DEFAULT = "default",
  CONTEXTUAL = "contextual",
}

export const NAV_BAR_TYPES = Object.values(NavBarType);

export function isNavBarType(value: unknown): value is NavBarType {
  return value != null && typeof value === "string" && (NAV_BAR_TYPES as string[]).includes(value);
}
