import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import {
  ActivatedRouteSnapshot,
  ActivationEnd,
  ActivationStart,
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Route,
  Router,
} from "@angular/router";
import { TranslateService } from "./translate.service";
import { MetaTagService } from "@app/services/metaTagService";

declare let history: History;
export const prefixLocale = (locale: string, routes: Array<Route>): Array<Route> => {
  return routes.map((r: Route): Route => {
    const path: string = r && r.path && r.path.length ? `${locale}/${r.path}` : `${locale}`;
    return {
      ...r,
      path: `${path}`.replace("^//", "/"),
      data: { ...r.data, locale },
    } as Route;
  });
};

@Injectable({
  providedIn: "root",
})
export class RouteTranslationService {
  private subscriptions: Subscription;
  private currentSnapshot;
  private currentUrl: string;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private metaTagService: MetaTagService,
  ) {
    this.subcribe();
  }

  subcribe(): void {
    this.subscriptions = new Subscription();
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter((e: Event) => {
            return (
              e instanceof NavigationStart ||
              e instanceof NavigationEnd ||
              e instanceof NavigationError ||
              e instanceof ActivationStart ||
              e instanceof ActivationEnd
            );
          }),
        )
        .subscribe((e: Event) => this.handleEvents(e)),
    );
  }

  routeName(route: Route): string {
    return route && route.data && route.data.routeName && route.data.routeName
      ? route.data.routeName
      : undefined;
  }

  routeLocale(route: Route): string {
    let locale: string =
      route && route.data && route.data.locale && route.data.locale ? route.data.locale : undefined;
    if (!locale && route && route.path) {
      locale = this.urlLocale(route.path);
    }

    return locale;
  }

  rootRouteNameUrl(routeName: string, locale?: string): string {
    const route: Route = this.rootRouteByName(routeName, locale);
    return route && route.path ? decodeURIComponent(route.path) : "";
  }

  rootRouteByName(routeName: string, locale?: string): Route {
    return this.router.config.find((r: Route) => {
      return this.routeName(r) === routeName && (!locale || this.routeLocale(r) === locale);
    });
  }

  getInitialSwitchLocaleUrl(): string {
    if (this.router.url === "/") {
      return "/en";
    }
    if (this.router.url === "/en") {
      return "/";
    }
    if (this.translate.currentLang === "en") {
      return this.router.url.replace("/en/", "/fr/");
    }
    if (this.translate.currentLang === "fr") {
      return this.router.url.replace("/fr/", "/en/");
    }
  }

  switchLocale(locale: string): string {
    let url: string;
    const name: string = this.routeName(this.currentSnapshot);
    const currentLocale: string = this.routeLocale(this.currentSnapshot);
    const extractedLocale: string = this.urlLocale(this.currentUrl);

    if (!extractedLocale) {
      return this.currentUrl;
    }

    const routeNameUrl: string = this.rootRouteNameUrl(name, locale);
    if (this.currentUrl === `/${routeNameUrl}`) {
      url = routeNameUrl;
    } else if (this.currentUrl.indexOf(this.rootRouteNameUrl(name, currentLocale)) >= 0) {
      url = this.appendChild(routeNameUrl, this.currentSnapshot);
    }

    if (!url && this.urlLocale(this.currentUrl) === currentLocale && currentLocale) {
      url = this.currentUrl.replace(`/${currentLocale}`, `/${locale}`);
    }

    return decodeURIComponent(url);
  }

  urlLocale(url: string): string {
    if (!url) {
      return undefined;
    }

    url = decodeURIComponent(url);
    const localesPipe: string = this.translate.locales.map((l) => l.toLocaleLowerCase()).join("|");
    const matches = url.toLowerCase().match(`^\/(${localesPipe})(/.*)?$`);
    return matches ? matches[1] : undefined;
  }

  updateLocale(locale: string): void {
    if (locale && locale !== this.translate.currentLang) {
      this.translate.use(locale);
    }
  }

  unsubscribe(): void {
    this.subscriptions.unsubscribe();
  }

  private trimTrailingSlash(url: string) {
    return url && url.length > 1 && url.substr(-1, 1) === "/" ? url.substr(0, url.length - 1) : url;
  }

  private appendChild(url: string, route: ActivatedRouteSnapshot) {
    url = this.trimTrailingSlash(url);

    if (route.firstChild) {
      let path: string = route.firstChild.routeConfig.path;
      Object.keys(route.firstChild.params).forEach((k: string) => {
        path = `/${path.replace(`:${k}`, route.firstChild.params[k])}`;
      });

      url = `${url}${this.trimTrailingSlash(path)}`;
      if (route.firstChild.firstChild) {
        return this.appendChild(url, route.firstChild.firstChild);
      }
    }
    return url;
  }

  private handleEvents(e: Event): void {
    if (e instanceof NavigationEnd) {
      this.currentUrl = decodeURIComponent(e.url);
      const locale: string = this.urlLocale(e.url) || "fr";
      if (e.url === "/" && locale === "en") {
        this.router.navigate(["/"], { replaceUrl: true });
      }

      this.updateLocale(locale);

      if (this.currentSnapshot) {
        setTimeout(() => {
          const pathToExclude = [
            "/admin/",
            "/project/",
            "/organization/",
            "/profil",
            "/cart/success/",
          ];
          const foundPath = pathToExclude.find((path) => this.router.url.includes(path));
          if (foundPath) {
            this.metaTagService.setDataForNoIndexPage(
              this.router.url,
              foundPath.replace(/\//g, ""),
            );
            this.metaTagService.addNoIndex();
          } else {
            this.metaTagService.removeNoIndex();
            this.metaTagService.setAlternateUrls("fr-ca", this.switchLocale("fr"));
            this.metaTagService.setAlternateUrls("en-ca", this.switchLocale("en"));
            this.metaTagService.switchHtmlLang(locale);
          }
        });
      }
    }

    if (e instanceof ActivationStart || e instanceof ActivationEnd) {
      this.currentSnapshot = e.snapshot;
    }
  }
}
