import { Injectable } from "@angular/core";
import { parseBool } from "@shared-v2/utils/helpers";
import { UserConfigurationRolesEnum } from "lrd-interfaces/interfaces";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NotificationService } from "../services/notificationService";

@Injectable()
export class User {
  private user: any;
  public isConnected = false;

  private readonly user$: BehaviorSubject<any | null>;
  public readonly userChange$: Observable<User | null>;

  constructor(private notificationService: NotificationService) {
    this.user$ = new BehaviorSubject(null);
    this.userChange$ = this.createUserChangeObservable(this.user$);
    this.refresh();
  }

  refresh() {
    const userString: string = sessionStorage.getItem("user");
    const user = userString ? JSON.parse(userString) : null;

    if (user) {
      this.user = user;
      this.isConnected = true;
      this.notificationService.connectDevice(this.user);
    } else {
      this.user = null;
      this.isConnected = false;
      this.notificationService.disconnectDevice();
    }

    this.user$.next(this.user);
  }

  get regionAdmin() {
    return this.user
      ? this.user.regions && this.user.regions.some((region: any) => region.role === "ADMIN")
      : false;
  }

  regionAdminInRegion(regionId) {
    return (
      this.user &&
      this.user.regions &&
      this.user.regions.some((region) => region.role === "ADMIN" && region.regionId === regionId)
    );
  }

  get specializedRegionAdmin() {
    return this.user
      ? this.user.regions &&
          this.user.regions.some((region: any) => region.role === "SPECIALIZED_ADMIN")
      : false;
  }

  specializedRegionAdminInRegion(subRegionId) {
    return (
      this.user &&
      this.user.regions &&
      this.user.regions.some(
        (region) => region.role === "SPECIALIZED_ADMIN" && region.regionId === subRegionId,
      )
    );
  }

  get configurationRoles(): UserConfigurationRolesEnum[] {
    return this.user?.configurationRoles;
  }

  get isConfigurationAdmin(): boolean {
    return this.user?.configurationRoles?.length > 0;
  }

  get programAdmin() {
    return this.user
      ? this.user.programs && this.user.programs.some((program: any) => program.role === "ADMIN")
      : false;
  }

  programAdminForProgram(programId) {
    return (
      this.user &&
      this.user.programs &&
      this.user.programs.some(
        (program) => program.role === "ADMIN" && program.programId === programId,
      )
    );
  }

  get sm40() {
    return this.user
      ? this.user.regions && this.user.regions.some((region: any) => region.role === "SM40")
      : false;
  }

  get cofounder() {
    return this.user
      ? this.user.regions && this.user.regions.some((region: any) => region.role === "COFOUNDER")
      : false;
  }

  get admin() {
    return this.user ? this.user.role === "ADMIN" : false;
  }

  get webmaster() {
    return this.user ? this.user.role === "WEBMASTER" : false;
  }

  get firstName() {
    return this.user ? this.user.firstName : null;
  }

  get lastName() {
    return this.user ? this.user.lastName : null;
  }

  get name() {
    return this.user ? `${this.user.firstName} ${this.user.lastName}` : null;
  }

  get city() {
    return this.user ? this.user.city : "";
  }

  get email() {
    return this.user ? this.user.principalEmail : null;
  }

  get userId() {
    return this.user ? this.user.userId : null;
  }

  get profilePicture() {
    return this.user ? this.user.profilePicture : null;
  }

  get organizations(): any[] {
    return this.user ? this.user.organizations : [];
  }

  get websocket() {
    return this.user ? this.user.websocket : null;
  }

  get topic() {
    return this.user ? this.user.topic : null;
  }

  refreshWith(profile: any) {
    if (this.user) {
      this.user.firstName = profile.firstName;
      this.user.lastName = profile.lastName;
      this.user.profilePicture = profile.imageCropped;
      this.user.organizations = profile.organizations;

      sessionStorage.setItem("user", JSON.stringify(this.user));
    }
  }

  /**
   * Indicates that the user is currently logged in with a temporary token.
   * This token is usually given to users temporarily while waiting for them to
   * set up or resolve MFA.
   */
  public isTemporarilyLoggedIn(): boolean {
    return parseBool(sessionStorage.getItem("hasTemporaryToken"));
  }

  public belongsToOrganization(organizationId: string): boolean {
    return this.organizations.find((it) => it.organizationId === organizationId) != null;
  }

  private createUserChangeObservable(user$: Observable<any>): Observable<User | null> {
    return user$.pipe(map((value) => (value != null ? this : null)));
  }
}
