import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { APP_ROUTES } from "./app-routes";

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      onSameUrlNavigation: "reload",
      anchorScrolling: "enabled",
      scrollOffset: [0, 24],
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
