import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AiDrawerCardViewModel } from "./ai-drawer-card-view-model";
import { TranslatableService, pluralize } from "@app/shared-v2/services/translatable.service";
import { TranslatablePipe } from "@app/shared-v2/pipes/translatable.pipe";
import { ButtonComponent } from "../../button/button.component";
import { GptToneI18NKey } from "@app/services-v2/gpt.service";
import { GptTone } from "lrd-interfaces/interfaces";

@Component({
  standalone: true,
  imports: [TranslatablePipe, ButtonComponent],
  selector: "app-ai-drawer-card",
  templateUrl: "./ai-drawer-card.component.html",
  styleUrls: ["./ai-drawer-card.component.scss"],
})
export class AiDrawerCardComponent implements AiDrawerCardViewModel {
  @Input() id: string;
  @Input() content: string;
  @Input() tones: GptTone[];
  @Input() locked: boolean;

  @Output() toggleLock = new EventEmitter<null>();
  @Output() selectCard = new EventEmitter<null>();

  constructor(private translatableService: TranslatableService) {}

  get length() {
    return this.content.length;
  }

  get formatedTones() {
    const translatedTones = this.tones.map((value) =>
      this.translatableService.transform(GptToneI18NKey[value]),
    );

    return new Intl.ListFormat("fr", { style: "long", type: "conjunction" }).format(
      translatedTones,
    );
  }

  get formatedLength() {
    return {
      key: pluralize("components.ai-drawer.card.caracter", this.length),
      params: { count: this.length.toString() },
    };
  }

  handleOnToggleLock(event: MouseEvent) {
    event.stopPropagation();

    this.toggleLock.emit();
  }

  handleOnSelectCard() {
    this.selectCard.emit();
  }

  get lockIcon() {
    return this.locked ? "lock" : "unlock";
  }
}
