import { Injectable } from "@angular/core";
import { ActivatedRoute, IsActiveMatchOptions, Route, Router, UrlTree } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { I18nString } from "lrd-interfaces/interfaces";
import { from, Observable } from "rxjs";
import { UrlService } from "../../services/urlService";
import { AvailableRoutes } from "./available-routes.enum";
import { Routes, withRouteParamAliases } from "./routes";

const DEFAULT_IS_ACTIVE_MATCH_OPTIONS: IsActiveMatchOptions = {
  paths: "exact",
  fragment: "exact",
  matrixParams: "exact",
  queryParams: "exact",
};

@Injectable({ providedIn: "root" })
export class RoutingService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
  ) {}

  public routeFor(
    routeName: AvailableRoutes,
    args = {},
    forceLanguage = null,
  ): (string | unknown)[] {
    let builtRoute: string[] | string;

    const currentLang = forceLanguage || this.translateService.currentLang;
    const route = Routes[currentLang][routeName];

    if (typeof route === "string") {
      return [route];
    }

    if (route) {
      const argKeys = Object.keys(args).map((it) => `:${it}`);

      builtRoute = route.map((part: string) => {
        return argKeys.includes(part) ? args[part.replace(":", "")] : part;
      });
    } else {
      builtRoute = Routes[currentLang].HOME;
    }

    return [`/${currentLang}`, ...builtRoute];
  }

  public routeUrlFor(routeName: AvailableRoutes, args = {}, forceLanguage = null): string {
    const route = this.routeFor(routeName, args, forceLanguage);
    return "https://" + UrlService.getBaseUrlForEnvironment() + route.join("/");
  }

  public urlTreeFor(routeName: AvailableRoutes, args = {}, forceLanguage = null): UrlTree {
    const route = this.routeFor(routeName, args, forceLanguage);
    return this.router.createUrlTree(route);
  }

  public routePathFor(routeName: AvailableRoutes, args = {}, forceLanguage = null): string {
    return this.routeFor(routeName, args, forceLanguage).join("/");
  }

  public getCurrentRoute(): AvailableRoutes {
    const availableRoutes = Object.values(AvailableRoutes);
    const params = this.getAllRoutesParams();

    for (const route of availableRoutes) {
      if (route === AvailableRoutes.HOME) {
        continue;
      }

      const routeSegments = this.routeFor(route, params);

      if (this.isActive(routeSegments, {}, { queryParams: "ignored" })) {
        return route;
      }
    }

    return null;
  }

  public navigateTo(
    routeName: AvailableRoutes,
    args = {},
    forceLanguage = null,
  ): Observable<boolean> {
    const route = this.routeFor(routeName, args, forceLanguage);
    return from(this.router.navigate(route));
  }

  public alternateLanguageRouteFor(routeName: AvailableRoutes, args = {}) {
    if (this.translateService.currentLang === "fr") {
      return this.routeFor(routeName, args, "en");
    }

    return this.routeFor(routeName, args, "fr");
  }

  public isActive(
    route: AvailableRoutes | (string | unknown)[],
    args = {},
    options: Partial<IsActiveMatchOptions> = {},
  ): boolean {
    if (!Array.isArray(route)) {
      route = this.routeFor(route, args);
    }

    return this.router.isActive(this.router.createUrlTree(route), {
      ...DEFAULT_IS_ACTIVE_MATCH_OPTIONS,
      ...options,
    });
  }

  public getAllRoutesParams(): Record<string, any> {
    let route = this.activatedRoute.snapshot.root;
    let params: Record<string, any> = {};

    do {
      const routeParams = withRouteParamAliases(route.params);
      params = { ...params, ...routeParams };
      route = route.firstChild;
    } while (route != null);

    return params;
  }
}

export type TranslatableRoute = Omit<Route, "children"> & {
  paths?: I18nString;
  children: (TranslatableRoute | Route)[];
};

export function generateTranslatedRoutes(routes: (TranslatableRoute | Route)[]): Route[] {
  const result: Route[] = [];

  for (const route of routes) {
    if (route.children?.length) {
      route.children = generateTranslatedRoutes(route.children);
    }

    if (!isTranslatableRoute(route)) {
      result.push(route);
      continue;
    }

    const { paths, ...routeConfig } = route;
    const routePaths = Object.values(paths);

    for (const path of routePaths) {
      result.push({ path, ...routeConfig });
    }
  }

  return result;
}

function isTranslatableRoute(value: unknown): value is TranslatableRoute {
  return typeof value === "object" && "paths" in value;
}
