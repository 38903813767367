import { Injectable } from "@angular/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Injectable({
  providedIn: "root",
})
export class GoogleTagManagerImplService {
  private lastVisitedPage: string;
  private currentTitle: string;

  constructor(private googleTagManagerService: GoogleTagManagerService) {}

  public pushTag(eventName: string, url: string, title?: string, ecommerce?: any): void {
    const tag = {
      event: eventName,
      page_location: url,
      page_title: title || this.currentTitle,
      page_referrer: this.lastVisitedPage,
      ecommerce,
    };

    this.googleTagManagerService.pushTag(tag);
    this.lastVisitedPage = url;
    this.currentTitle = title;
  }

  public pushTagCart(url: string, step: number, isUserLogged: boolean): void {
    if (url !== this.lastVisitedPage) {
      const ecommerce = {
        checkout_option: {
          actionField: {
            step: step,
            option: isUserLogged ? "connecte" : "invite",
          },
        },
      };
      this.pushTag("page", url, this.currentTitle, ecommerce);
    }
  }

  public pushInteractionPage(
    action: string,
    category: string,
    label: string,
    ecommerce?: any,
  ): void {
    const pushTag = {
      event: "interractionsPage",
      action,
      category,
      label,
      value: 0,
      nonInteraction: false,
    };
    const pushTagWithEcommerceProperty = ecommerce ? { ...pushTag, ecommerce } : pushTag;
    this.googleTagManagerService.pushTag(pushTagWithEcommerceProperty);
  }
}
