import { CommonModule } from "@angular/common";
import { Component, HostBinding, Input } from "@angular/core";
import { Icons } from "../../icons";

export enum IconSize {
  X_SMALL = "x-small",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  LARGER = "larger",
  X_LARGE = "x-large",
  XX_LARGE = "xx-large",
}

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: "app-v2-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
})
export class IconComponent implements IconViewModel {
  @Input() icon: Icons | ValuesOf<typeof Icons> | string;
  @Input() path?: string = "/assets/svg/icons/";

  @Input()
  @HostBinding("class")
  size?: IconSize | ValuesOf<IconSize> | string = IconSize.MEDIUM;
}

export interface IconViewModel {
  icon: Icons | ValuesOf<typeof Icons> | string;
  path?: string;
  size?: IconSize | ValuesOf<IconSize> | string;
}
