import { Component, OnDestroy } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AVAILABLE_LOCALES, DEFAULT_LANGUAGE } from "./app.module";
import packageInfo from "../../package.json";
import { isInstanceOf } from "./helpers/observables/filters";
import Logger from "./services-v2/logger/logger.service";
import { MetadataService } from "./services-v2/metadata/metadata.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnDestroy {
  public localeLoaded = false;

  private readonly navigationSubscription: Subscription = new Subscription();

  constructor(
    private metadataService: MetadataService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    Logger.info("Version: ", packageInfo.version);

    const onNavigationStart$ = this.router.events.pipe(filter(isInstanceOf(NavigationStart)));
    const onNavigationEnd$ = this.router.events.pipe(filter(isInstanceOf(NavigationEnd)));

    this.navigationSubscription.add(
      onNavigationStart$.subscribe({
        next: async (event) => {
          if (!this.localeLoaded) {
            await this.setupTranslations(event.url);
          }
        },
      }),
    );

    this.navigationSubscription.add(
      onNavigationEnd$.subscribe({
        next: (event) => {
          if (event.url === "/") {
            this.redirectToHomepage();
          }
        },
      }),
    );

    this.metadataService.init();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  private async setupTranslations(url: string) {
    return new Promise<void>((resolve) => {
      this.translateService.addLangs(AVAILABLE_LOCALES);

      const result = new RegExp(`^/(?<locale>${AVAILABLE_LOCALES.join("|")})`).exec(url);
      const urlLocale = result?.groups?.locale;

      if (urlLocale && AVAILABLE_LOCALES.includes(urlLocale)) {
        this.translateService.use(result.groups.locale).subscribe(() => {
          this.localeLoaded = true;

          resolve();
        });
      } else {
        this.localeLoaded = true;

        resolve();
      }
    });
  }

  private getBrowserLang(): string {
    if (typeof window === "undefined" || typeof window.navigator === "undefined") {
      return DEFAULT_LANGUAGE;
    }

    const locale = new Intl.Locale(
      window.navigator.language || window.navigator.languages[0] || DEFAULT_LANGUAGE,
    );

    if (AVAILABLE_LOCALES.includes(locale.language)) {
      return locale.language;
    }

    return DEFAULT_LANGUAGE;
  }

  private redirectToHomepage() {
    this.router.navigate([`/${this.translateService.currentLang ?? this.getBrowserLang()}`]);
  }
}
