import { Pipe, PipeTransform } from "@angular/core";
import { Translatable } from "@app/types";
import { TranslatableService, TranslationFallbackOptions } from "../services/translatable.service";

@Pipe({
  name: "translatable",
  standalone: true,
  pure: false,
})
export class TranslatablePipe implements PipeTransform {
  constructor(private translatableService: TranslatableService) {}

  transform(
    value: null | Translatable,
    params?: object,
    fallbackOptions?: TranslationFallbackOptions,
  ): string | null {
    return this.translatableService.transform(value, params, fallbackOptions);
  }
}
