import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "highlightFirstWord" })
export class HighlightFirstWord implements PipeTransform {
  transform(value: string, count: any = 1, transition = false): string {
    const text = value.trim().split(" ");
    let res = "";
    while (count > 0) {
      if (res !== "") {
        res += " ";
      }
      res += text.shift();
      count--;
    }
    if (transition) {
      return "<span class='underlined-with-transition'>" + res + "</span> " + text.join(" ");
    } else {
      return "<span class='underlined'>" + res + "</span> " + text.join(" ");
    }
  }
}
