import { Injectable } from "@angular/core";
import { IApiPostResponse } from "@app/shared/models/api";
import { Lookup } from "@app/shared/models/lookup";
import { IAlly, IApiNewsArticle, IItems, IRegion } from "lrd-interfaces/interfaces";
import { ApiServicesList } from "@shared/enumerations/apiServiceEnums";
import { GeneratedDataProvider } from "@shared/generated-data-provider";
import { ApiService, IHttpOptions } from "./apiService";

@Injectable({
  providedIn: "root",
})
export class VariousService {
  private cachedRegions: any;

  private regionLoading = false;
  private regionLoadingSuccess = [];
  private regionLoadingError = [];

  constructor(
    private apiService: ApiService,
    private generatedDataProvider: GeneratedDataProvider,
  ) {}

  public loadAllLookups(): Promise<any> {
    return this.generatedDataProvider.getData("lookups");
  }

  public async getLookupById(id: string): Promise<any> {
    return this.generatedDataProvider.getData("lookups").then((result: Array<any>) => {
      return result.find((lookup) => lookup.lookupId === id);
    });
  }

  public async getBatchLookupById(ids: string[]): Promise<any> {
    return this.generatedDataProvider.getData("lookups").then((result) => {
      return ids
        .map((id) => ({
          id,
          lookup: result.filter((lookup) => lookup.lookupId === id)[0],
        }))
        .reduce((map, resultLookup) => {
          map[resultLookup.id] = resultLookup.lookup;
          return map;
        }, {});
    });
  }

  public async getLookupByType(type: string, onlyRoot = true): Promise<IApiPostResponse<Lookup>> {
    if (onlyRoot) {
      return this.getLookupChild("NONE", type);
    } else {
      return this.getLookupChild(null, type);
    }
  }

  public async getLookupChild(parentId: string, type: string): Promise<IApiPostResponse<Lookup>> {
    const lookups: Lookup[] = await this.generatedDataProvider.getData("lookups");
    const requestedLookups = lookups
      .filter((lookup) => lookup.lookupType === type)
      .filter((lookup) => !parentId || lookup.parent === parentId)
      .sort((s1: any, s2: any) => s1.order - s2.order);

    return { items: requestedLookups };
  }

  public async getActiveLookupChild(parentId: string, type: string): Promise<Lookup[]> {
    const response = await this.getLookupChild(parentId, type);
    return response.items.filter((lookup) => lookup.status === "actif");
  }

  public async getRegion(regionId: number): Promise<IRegion> {
    const regions = (await this.getRegions())?.items ?? [];
    return regions.find((region: IRegion) => region.id === regionId);
  }

  /**
   * This function use a cachig mecanism that support simultanious call so only one real call
   * is executed against the server
   */
  public async getRegions(): Promise<any> {
    if (!this.cachedRegions) {
      // When we are alreading loading regions, the return a promises that will be resolved after the current load
      if (this.regionLoading) {
        return new Promise((resolve, reject) => {
          // Stack resolve and reject functions so we can call then after the loading that is currently running
          this.regionLoadingSuccess.push(resolve);
          this.regionLoadingError.push(reject);
        });
      } else {
        this.regionLoading = true;
        try {
          // Load regions
          this.cachedRegions = await this.apiService.get(
            ApiServicesList.VARIOUS_SERVICE,
            "regions",
          );
          this.cachedRegions.items = this.cachedRegions.items.filter((region) => !region.inactive);

          // Resolve queued promises
          for (const resolve of this.regionLoadingSuccess) {
            resolve(this.cachedRegions);
          }
        } catch {
          // Reject queued promises
          for (const reject of this.regionLoadingError) {
            reject(this.cachedRegions);
          }
        } finally {
          this.regionLoading = false;
        }
      }
    }

    return Promise.resolve(this.cachedRegions);
  }

  public getFaq(): Promise<any> {
    return this.apiService.get(ApiServicesList.VARIOUS_SERVICE, "faq");
  }

  public getSM40(regionId?: number, size?: number): Promise<any> {
    const options: IHttpOptions = {
      parameters: {
        regionId,
        size,
      },
    };
    return this.apiService.get(ApiServicesList.USER_SERVICE, `thumbnails/sm40`, options);
  }

  public getAllies(regionId: number): Promise<IItems<IAlly>> {
    return this.apiService.get(ApiServicesList.VARIOUS_SERVICE, `allies/${regionId}`);
  }

  public getCoFounders(regionId?: number, size?: number): Promise<any> {
    const options: IHttpOptions = {
      parameters: {
        regionId,
        size,
      },
    };
    return this.apiService.get(ApiServicesList.USER_SERVICE, `thumbnails/coFounders`, options);
  }

  public async getTeamMembers(regionId?: number, size?: number): Promise<any> {
    const options: IHttpOptions = {
      parameters: {
        regionId,
        size,
      },
    };
    return this.apiService.get(ApiServicesList.USER_SERVICE, `thumbnails/teamMembers`, options);
  }

  public async getRegionAdmins(regionId?: number, size?: number): Promise<any> {
    const options: IHttpOptions = {
      parameters: {
        regionId,
        size,
      },
    };
    return this.apiService.get(ApiServicesList.USER_SERVICE, `thumbnails/regionAdmins`, options);
  }

  public async getCAMembers(): Promise<any> {
    return this.apiService.get(ApiServicesList.USER_SERVICE, `thumbnails/caMembers`);
  }

  public async getNewsArticle(newsId: string): Promise<IApiNewsArticle> {
    const data = await this.apiService.get(
      ApiServicesList.VARIOUS_SERVICE,
      `newsArticle/${newsId}`,
    );
    if (data.items) {
      return data.items[0];
    }
    return null;
  }

  public async getProjectNewsArticles(projectId: string): Promise<any> {
    return await this.apiService.get(
      ApiServicesList.VARIOUS_SERVICE,
      `newsArticle/project/${projectId}`,
    );
  }

  public async postNewsArticle(newsArticle: IApiNewsArticle): Promise<any> {
    return this.apiService.post(ApiServicesList.VARIOUS_SERVICE, "newsArticle", newsArticle);
  }

  public async postCancelNewsArticles(newsIds: string[]): Promise<any> {
    return this.apiService.post(ApiServicesList.VARIOUS_SERVICE, "cancel/newsArticle", newsIds);
  }

  public async getNumberOfFeaturedNewsArticles(): Promise<{
    ids: string[];
    size: number;
  }> {
    return this.apiService.get(ApiServicesList.VARIOUS_SERVICE, "featured/newsArticle");
  }

  public async deleteNewsArticle(newsId: string): Promise<any> {
    return this.apiService.delete(ApiServicesList.VARIOUS_SERVICE, `newsArticle/delete/${newsId}`);
  }

  shuffle(list: any[]) {
    for (let index = list.length - 1; index > 0; index--) {
      const newPosition = Math.floor(Math.random() * (index + 1));
      const temp = list[index];
      list[index] = list[newPosition];
      list[newPosition] = temp;
    }
    return list;
  }

  public getAppSettings(): Promise<any> {
    return this.apiService.get(ApiServicesList.VARIOUS_SERVICE, "appSettings");
  }

  public startIndexing() {
    return this.apiService.get(ApiServicesList.VARIOUS_SERVICE, "startIndexingJob");
  }

  public sendGiftCards(projectId: string): Promise<any> {
    return this.apiService.get(ApiServicesList.VARIOUS_SERVICE, `sendGiftCard/${projectId}`);
  }

  public postImage(image: string, folder?: string, name?: string) {
    const body: any = { image: image };

    if (folder) {
      body.folder = folder;
    }

    if (name) {
      body.name = name;
    }

    return this.apiService.post(ApiServicesList.VARIOUS_SERVICE, "images", body);
  }
}
