import { DestroyRef, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { User } from "@shared/user";
import { iot, mqtt } from "aws-iot-device-sdk-v2";
import { IIotMessage } from "lrd-interfaces/interfaces";
import { BehaviorSubject, combineLatest, ReplaySubject, skipWhile } from "rxjs";
import { distinctUntilChanged, first, switchMap } from "rxjs/operators";
import { LoggerType } from "../services-v2/logger/logger-type";
import Logger from "../services-v2/logger/logger.service";
import { AppSettings } from "./common/AppSettings/app-settings.service";
import { MessageService } from "./messageService";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private readonly logger = Logger.withName("NotificationService", {
    type: LoggerType.SERVICE,
    color: "#108ba8",
  });

  private readonly user$ = new BehaviorSubject<User>(null);
  private readonly deviceEventHandlers = new Map<string, (...args: unknown[]) => void>();
  private device: mqtt.MqttClientConnection;

  public readonly topic$ = new ReplaySubject<IIotMessage<unknown>>(1);

  constructor(
    private messageService: MessageService,
    private appSettings: AppSettings,
    private destroyRef: DestroyRef,
  ) {
    const appSettings$ = this.appSettings.appSettings$.pipe(first());
    const user$ = this.user$.pipe(skipWhile((user) => user == null));

    combineLatest([user$, appSettings$])
      .pipe(
        distinctUntilChanged(([previousUser], [currentUser]) => {
          return previousUser?.userId === currentUser?.userId;
        }),
        switchMap(([user, appSettings]) => {
          if (user) {
            return this.initDevice(user, appSettings);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  connectDevice(user: User) {
    this.user$.next(user);
  }

  private async initDevice(user: User, appSettings: AppSettings) {
    this.logger.info("initializing device");

    await this.disconnectDevice();

    const config = iot.AwsIotMqttConnectionConfigBuilder.new_builder_for_websocket()
      .with_clean_session(true)
      .with_endpoint(appSettings.websocketEndpoint)
      .with_credentials(
        "ca-central-1",
        user.websocket.accessKey,
        user.websocket.secretKey,
        user.websocket.sessionToken,
      )
      .build();

    const client = new mqtt.MqttClient();

    this.device = client.new_connection(config);

    this.device.connect();

    this.addDeviceEventListener("connect", () => {
      this.logger.info("IoT connection");
      this.device.subscribe(user.topic, mqtt.QoS.AtLeastOnce);
    });

    this.addDeviceEventListener("disconnect", () => {
      this.logger.info("IoT disconnection");
      this.device.unsubscribe(user.topic);
    });

    this.addDeviceEventListener("message", (topic: string, message: ArrayBuffer) => {
      const textDecoder = new TextDecoder();
      const decodedMessage = textDecoder.decode(message);
      const parsedMessage = JSON.parse(decodedMessage);

      this.logger.info("IoT message", { message: parsedMessage });
      this.topic$.next(parsedMessage);
    });

    this.addDeviceEventListener("error", (error: any) => {
      this.logger.error(`IoT error: ${JSON.stringify(error)}`);
      this.messageService.sendMessage("sessionRequest");
    });
  }

  async disconnectDevice() {
    if (this.device) {
      this.clearEventListeners();
      await this.device.disconnect();

      this.deviceEventHandlers.clear();
      this.device = null;
    }
  }

  onRefreshUser(user: User) {
    this.logger.info("Refreshing credentials");
    this.user$.next(user);
  }

  private addDeviceEventListener(eventName: string, handler: (...args: unknown[]) => void): void {
    this.deviceEventHandlers.set(eventName, handler);
    this.device.on(eventName as any, this.deviceEventHandlers.get(eventName));
  }

  private clearEventListeners(): void {
    this.deviceEventHandlers.forEach((handler, eventName) => {
      this.device.off(eventName, handler);
    });
  }
}
