<div>
  <div class="header">
    <app-v2-icon [icon]="logo" size="large" />

    <p class="title">
      {{ title | translatable }}
    </p>
  </div>

  <ng-content></ng-content>
</div>
