import { Renderer2, Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";

import { loadDonorBoxScript } from "src/app/scripts/donor-box.script";
import { loadFacebookPixelScript } from "src/app/scripts/facebook-pixel.script";
import { loadGeneratedBootstrapScript } from "src/app/scripts/generated-bootstrap.script";
import { loadLinkedinPixelScript } from "src/app/scripts/linkedin-pixel.script";
import { loadZohoScript } from "src/app/scripts/zoho.script";
import { loadGTMScript } from "@app/scripts/google-tag-manager";

export const Scripts = {
  DONOR_BOX: loadDonorBoxScript,
  FACEBOOK_PIXEL: loadFacebookPixelScript,
  GENERATED_BOOTSTRAP: loadGeneratedBootstrapScript,
  LINKEDIN_PIXEL: loadLinkedinPixelScript,
  ZOHO: loadZohoScript,
  GOOGLE_TAG_MANAGER: loadGTMScript,
};

export type AvailableScripts = keyof typeof Scripts;

@Injectable()
export class ScriptService {
  loadedScripts: AvailableScripts[] = [];

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public load(renderer: Renderer2, scriptNames: AvailableScripts[]) {
    scriptNames.forEach((scriptName) => {
      if (!this.loadedScripts.includes(scriptName)) {
        Scripts[scriptName](renderer, this.document);

        this.loadedScripts = [...this.loadedScripts, scriptName];
      }
    });
  }
}
