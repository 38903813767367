import { Injectable } from "@angular/core";
import { FooterFactory } from "@shared-v2/components/footer/footer-factory.service";
import { MenuFactory } from "@shared-v2/components/menu/menu-factory.service";
import { ViewModelDataResolver } from "@shared-v2/utils/view-models/view-model-data-resolver";
import { IRegion } from "lrd-interfaces/interfaces";
import { from, map, Observable } from "rxjs";
import { AppV2PageViewModel } from "./app-v2-view-model";
import { NavBarService } from "./services-v2/nav-bar/nav-bar.service";
import { PromotionBannerDisplayService } from "./services-v2/promotion-banner-display.service";
import { VariousService } from "./services/variousService";
import { EmailValidationFactory } from "./ui-v2/connection/email-validation/email-validation-factory.service";
import { ResetPasswordFactory } from "./ui-v2/connection/reset-password/reset-password-factory.service";
import { SignInFactory } from "./ui-v2/connection/sign-in/sign-in-factory.service";
import { SignUpFactory } from "./ui-v2/connection/sign-up/sign-up-factory.service";

@Injectable({
  providedIn: "root",
})
export class AppV2Resolver implements ViewModelDataResolver<AppV2PageViewModel> {
  constructor(
    private variousService: VariousService,
    private footerFactory: FooterFactory,
    private menuFactory: MenuFactory,
    private signInFactory: SignInFactory,
    private resetPasswordFactory: ResetPasswordFactory,
    private navBarService: NavBarService,
    private signUpFactory: SignUpFactory,
    private emailValidationFactory: EmailValidationFactory,
    private promotionBannerDisplayService: PromotionBannerDisplayService,
  ) {}

  public resolve(): Observable<AppV2PageViewModel> | null {
    return this.getRegion(0).pipe(
      map((region) => ({
        navigation: {
          desktopMenu: this.menuFactory.desktopMenuFromDefault(),
          mobileMenu: this.menuFactory.mobileMenuFromDefault(),
          navBar: this.navBarService.navBar(),
        },
        footer: this.footerFactory.fromRegion(region),
        profileDrawer: {
          signIn: this.signInFactory.fromDefault(),
          signUp: this.signUpFactory.fromDefault(),
          emailValidation: this.emailValidationFactory.fromDefault(),
          resetPassword: this.resetPasswordFactory.fromDefault(),
        },
        promotionBanner: this.promotionBannerDisplayService.promotionBannerViewModel$,
      })),
    );
  }

  private getRegion(regionId: number): Observable<IRegion> {
    return from(this.variousService.getRegion(regionId));
  }
}
