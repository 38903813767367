import { DestroyRef } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import Logger from "../../services-v2/logger/logger.service";
import { hasSomeProps } from "./helpers";

export default class BindableRef<T> {
  public static bindTo<T>(observable: Observable<T>, destroyRef: DestroyRef): BindableRef<T>;

  public static bindTo<T>(
    observable: Observable<T>,
    config: BindableRefBuilderConfig<T>,
  ): BindableRef<T>;

  public static bindTo<T>(
    observable: Observable<T>,
    configOrDestroyRef: BindableRefBuilderConfig<T> | DestroyRef,
  ): BindableRef<T> {
    let destroyRef: DestroyRef;
    let subscription: Subscription;
    let initialValue: T;

    if (isBindableRefBuilderConfig(configOrDestroyRef)) {
      destroyRef = configOrDestroyRef.destroyRef;
      subscription = configOrDestroyRef.subscription;
      initialValue = configOrDestroyRef.initialValue;
    } else {
      destroyRef = configOrDestroyRef;
    }

    if (destroyRef != null && subscription == null) {
      subscription = new Subscription();
      destroyRef.onDestroy(() => subscription.unsubscribe());
    }

    return new BindableRef(initialValue).bindTo(observable, subscription);
  }

  protected value: T = null;

  private logger = Logger.withName("BindableRef");
  private subscriptionManager: Subscription;

  constructor(initialValue: T = null) {
    this.value = initialValue;
  }

  public bindTo(
    observable: Observable<T>,
    externalSubscriptionManager?: Subscription,
  ): BindableRef<T> {
    if (this.subscriptionManager !== undefined) {
      throw new Error("BindableReference already bound.");
    }

    this.subscriptionManager = new Subscription();

    this.subscriptionManager.add(
      observable.subscribe({
        next: (value) => (this.value = value),
        complete: () => this.destroy(),
      }),
    );

    if (externalSubscriptionManager) {
      externalSubscriptionManager.add(this.subscriptionManager);
    }

    return this;
  }

  public unbind(): void {
    if (this.subscriptionManager === undefined) {
      this.logger.warn("Trying convertTo unbind a BindableReference that has never been bound.");
      return;
    }

    if (this.subscriptionManager === null) {
      this.logger.warn("Trying convertTo unbind an already unbound BindableReference.");
      return;
    }

    this.subscriptionManager.unsubscribe();
    this.destroy();
  }

  public get(): T {
    return this.value;
  }

  public isEmpty(): boolean {
    return this.value == null;
  }

  public isNotEmpty(): boolean {
    return this.value == null;
  }

  private destroy(): void {
    this.subscriptionManager = null;
    this.value = null;
  }
}

function isBindableRefBuilderConfig<T>(value: unknown): value is BindableRefBuilderConfig<T> {
  if (typeof value !== "object") {
    return false;
  }

  return hasSomeProps<BindableRefBuilderConfig<T>>(value, [
    "initialValue",
    "subscription",
    "destroyRef",
  ]);
}

interface BindableRefBuilderConfig<T> {
  initialValue?: T;
  subscription?: Subscription;
  destroyRef?: DestroyRef;
}
