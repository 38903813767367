import { inject, ProviderToken } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { NEVER } from "rxjs";
import { catchError } from "rxjs/operators";
import { ViewModelDataResolver } from "./view-model-data-resolver";

export const VIEW_MODEL_RESOLVER_DATA_KEY = "pageViewModel";

export function useViewModelResolver<T extends ViewModelDataResolver<unknown>>(
  resolver: ProviderToken<T>,
) {
  return {
    [VIEW_MODEL_RESOLVER_DATA_KEY]: (route: ActivatedRouteSnapshot) => {
      const resolverInstance = inject(resolver);
      let result$ = resolverInstance.resolve(route);

      if (resolverInstance.redirectOnError !== undefined) {
        result$ = result$.pipe(
          catchError((error) => {
            resolverInstance.redirectOnError(error);
            return NEVER;
          }),
        );
      }

      return result$;
    },
  };
}
