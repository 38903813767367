import { MetaTag } from "../../../types";
import { SocialMediaTag } from "./social-media-tag";

export enum TwitterTag {
  CARD = "twitter:card",
  DESCRIPTION = "twitter:description",
  IMAGE = "twitter:image",
  SITE = "twitter:site",
  TITLE = "twitter:title",
}

export const DEFAULT_TWITTER_CARD = "summary";
export const DEFAULT_TWITTER_SITE = "@laruchequebec";

const DEFAULT_TWITTER_TAGS: MetaTag[] = [
  { name: TwitterTag.CARD, content: DEFAULT_TWITTER_CARD },
  { name: TwitterTag.SITE, content: DEFAULT_TWITTER_SITE },
];

export function buildTwitterTags({ title, description, image }: TwitterTagsBuilder): MetaTag[] {
  const twitterTags: MetaTag[] = [...DEFAULT_TWITTER_TAGS];

  if (title) {
    twitterTags.push({ name: TwitterTag.TITLE, content: title });
  }

  if (description) {
    twitterTags.push({ name: TwitterTag.DESCRIPTION, content: description });
  }

  if (image) {
    twitterTags.push({ name: TwitterTag.IMAGE, content: image });
  }

  return twitterTags;
}

export type TwitterTagsBuilder = SocialMediaTag;
