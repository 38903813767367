export enum AvailableRoutes {
  ABOUT = "ABOUT",
  ADDITIONAL_FINANCING = "ADDITIONAL_FINANCING",
  ALLIES = "ALLIES",
  AMBASSADORS = "AMBASSADORS",
  BOARD = "BOARD",
  CAREER = "CAREER",
  CHAT = "CHAT",
  CONTACT_US = "CONTACT_US",
  CONTRIBUTE = "CONTRIBUTE",
  CREATE_CAMPAIGN = "CREATE_CAMPAIGN",
  CREATE_ORGANIZATION = "CREATE_ORGANIZATION",
  DISCLAIMER = "DISCLAIMER",
  EDIT_ORGANIZATION_PROFILE = "EDIT_ORGANIZATION_PROFILE",
  FAQ = "FAQ",
  FREE_CONSULTATION = "FREE_CONSULTATION",
  GIFT_CARD = "GIFT_CARD",
  HOME = "HOME",
  INFORMATION_SESSION = "INFORMATION_SESSION",
  INVOICE_CONTRIBUTION = "INVOICE_CONTRIBUTION",
  INVOICE_PROMOTER = "INVOICE_PROMOTER",
  LAUNCH_CAMPAIGN = "LAUNCH_CAMPAIGN",
  MANAGE_PROJECT = "MANAGE_PROJECT",
  NEWS = "NEWS",
  NEWS_ARTICLE = "NEWS_ARTICLE",
  ORGANIZATION_PROFILE = "ORGANIZATION_PROFILE",
  ORGANIZATION_PUBLIC_PROFILE = "ORGANIZATION_PUBLIC_PROFILE",
  OUR_SERVICES = "OUR_SERVICES",
  PARTNERS = "PARTNERS",
  PARTNER_DESJARDINS = "PARTNER_DESJARDINS",
  PRESS = "PRESS",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  PROJECT = "PROJECT",
  PROJECTS = "PROJECTS",
  PROJECT_CONTRIBUTION = "PROJECT_CONTRIBUTION",
  PROJECT_CONTRIBUTION_LOGIN = "PROJECT_CONTRIBUTION_LOGIN",
  PROJECT_CONTRIBUTION_PAYMENT = "PROJECT_CONTRIBUTION_PAYMENT",
  PROJECT_CONTRIBUTION_PAYMENT_CONFIRMATION = "PROJECT_CONTRIBUTION_PAYMENT_CONFIRMATION",
  PROJECT_CONTRIBUTION_SELECT = "PROJECT_CONTRIBUTION_SELECT",
  PROJECT_CONTRIBUTIONS = "PROJECT_CONTRIBUTIONS",
  PROJECT_STATISTICS = "PROJECT_STATISTICS",
  PROJECT_SUMMARY = "PROJECT_SUMMARY",
  SEARCH = "SEARCH",
  TEAM = "TEAM",
  UPDATE_PROJECT = "UPDATE_PROJECT",
  USER_PROFILE = "USER_PROFILE",
  USER_PUBLIC_PROFILE = "USER_PUBLIC_PROFILE",
}
