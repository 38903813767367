import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { LoggerType } from "../logger/logger-type";
import Logger from "../logger/logger.service";
import { NavBarType } from "./models/nav-bar-type";
import { NavBarViewModel } from "./models/nav-bar-view-model";

@Injectable({
  providedIn: "root",
})
export class NavBarService {
  private logger = Logger.withName("NavBarService", LoggerType.SERVICE);
  private readonly navBar$ = new BehaviorSubject<NavBarViewModel>(DEFAULT_NAV_BAR);

  #isLocked = false;

  public setNavBar(navBar: NavBarViewModel): NavBarService {
    if (this.#isLocked) {
      this.logger.error("Trying to set a new navbar while NavBarService is locked.");
      return this;
    }

    this.navBar$.next(navBar);
    return this;
  }

  public navBar(): Observable<NavBarViewModel> {
    return this.navBar$.asObservable();
  }

  public getNavBar(): NavBarViewModel {
    return this.navBar$.getValue();
  }

  public lock(): NavBarService {
    this.#isLocked = true;
    return this;
  }

  public unlock(): NavBarService {
    this.#isLocked = false;
    return this;
  }

  public isLocked(): boolean {
    return this.#isLocked;
  }
}

export const DEFAULT_NAV_BAR: NavBarViewModel = {
  getType: () => NavBarType.DEFAULT,
};
