import { DOCUMENT } from "@angular/common";
import { DestroyRef, inject, Injectable, OnDestroy } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Meta, Title } from "@angular/platform-browser";
import UnexpectedEnumValue from "@shared-v2/errors/UnexpectedEnumValue";
import { TranslatableService } from "@shared-v2/services/translatable.service";
import { throwDevError } from "@shared-v2/utils";
import { asSecureUrl, asUnsecureUrl } from "@shared-v2/utils/helpers";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  share,
  Subject,
  tap,
} from "rxjs";
import { safeTimeout } from "../../helpers/observables/operators";
import { UrlService } from "../../services/urlService";
import { LinkTag, MetaTag, PageMetadata, Translatable } from "../../types";
import { LinkTagService } from "../link-tag.service";
import { LoggerType } from "../logger/logger-type";
import Logger from "../logger/logger.service";
import { buildSocialMediaMetaTags } from "./tags/helpers";
import { isOgTagName, OgTag } from "./tags/og-tag";

export const defaultMetadata = (prefix = "pages"): PageMetadata => {
  const title = { key: `${prefix}.metadata.title` };
  const description = { key: `${prefix}.metadata.description` };
  const image = { key: `${prefix}.metadata.image-url` };

  return {
    title: { key: `${prefix}.metadata.title` },
    tags: [
      { name: "description", content: { key: `${prefix}.metadata.description` } },

      // Social medias tags
      ...buildSocialMediaMetaTags({
        url: UrlService.getBaseUrlForEnvironment(),
        title,
        description,
        image: image,
        type: { key: `${prefix}.metadata.type` },
        siteName: { key: `${prefix}.metadata.site-name` },
      }),
    ],
  };
};

const METADATA_CHANGE_DEBOUNCE_TIME = 250;
const PRERENDER_READY_DEBOUNCE_TIME = 500;
const PRERENDER_READY_TIMEOUT_TIME = 5_000;
const DEFAULT_METADATA = defaultMetadata();

@Injectable()
export class MetadataService implements OnDestroy {
  private static instanceCount = 0;

  private readonly logger = Logger.withName("MetadataService", LoggerType.SERVICE);
  private readonly metadataChange$: Subject<MetadataChange>;
  private readonly currentMetadata$: Observable<TransformedMetadataChange>;

  private readonly window: Window = inject(DOCUMENT).defaultView;

  constructor(
    private title: Title,
    private meta: Meta,
    private linkTag: LinkTagService,
    private translatableService: TranslatableService,
    private destroyRef: DestroyRef,
  ) {
    MetadataService.instanceCount++;

    if (MetadataService.instanceCount > 1) {
      throwDevError("Cannot initiate MetadataService more than once.");
      return;
    }

    this.metadataChange$ = new Subject();
    this.currentMetadata$ = this.createCurrentMetadataObservable(this.metadataChange$);

    this.injectSitemapLink();
    this.connectPrerenderReadyUpdate();
  }

  public ngOnDestroy(): void {
    MetadataService.instanceCount--;
  }

  public init(): void {
    if (MetadataService.instanceCount > 1) {
      return;
    }

    this.connectPageTitleUpdate(this.currentMetadata$);
    this.connectMetaTagsUpdate(this.currentMetadata$);
    this.connectLinkTagsUpdate(this.currentMetadata$);
  }

  public updateMetadata(metadata: PageMetadata): void {
    this.metadataChange$.next({ metadata, action: MetadataChangeAction.UPDATE });
  }

  public removeMetadata(metadata: PageMetadata): void {
    this.metadataChange$.next({ metadata, action: MetadataChangeAction.REMOVE });
  }

  private createCurrentMetadataObservable(
    metadataChange$: Observable<MetadataChange>,
  ): Observable<TransformedMetadataChange> {
    return metadataChange$.pipe(
      debounceTime(METADATA_CHANGE_DEBOUNCE_TIME),
      map((event) => this.asProcessedMetadataChange(event)),
      takeUntilDestroyed(this.destroyRef),
      share(),
    );
  }

  private injectSitemapLink(): void {
    this.linkTag.updateTag({
      id: "sitemap",
      rel: "sitemap",
      href: "/sitemap_index.xml",
    });
  }

  private async connectPrerenderReadyUpdate(): Promise<void> {
    if (!("prerenderReady" in this.window)) {
      return;
    }

    const metadataReady$ = this.metadataChange$.pipe(
      debounceTime(PRERENDER_READY_DEBOUNCE_TIME),
      safeTimeout(PRERENDER_READY_TIMEOUT_TIME, undefined),
      takeUntilDestroyed(this.destroyRef),
    );

    await firstValueFrom(metadataReady$);
    this.window.prerenderReady = true;
    this.logger.debug("Page metadata ready!");
  }

  private asProcessedMetadataChange({
    action,
    metadata,
  }: MetadataChange): TransformedMetadataChange {
    const { title, tags = [], linkTags } = metadata;
    const { title: defaultTitle, tags: defaultTags } = DEFAULT_METADATA;

    const existingTags = [];

    const overriddenTags = [...tags, ...defaultTags]
      .map((tag) => {
        if (existingTags.includes(tag.name)) {
          return null;
        }

        tag.content = this.fallbackValue(tag.content);

        if (!tag.content) {
          return null;
        }

        existingTags.push(tag.name);

        return tag;
      })
      .filter(Boolean);

    const suffix = this.fallbackValue({ key: "pages.metadata.title-suffix" }, "") || "";
    const prefix = this.fallbackValue({ key: "pages.metadata.title-prefix" }, "") || "";

    return {
      action,
      metadata: {
        title: `${prefix}${this.fallbackValue(title, defaultTitle)}${suffix}`,
        tags: overriddenTags,
        linkTags,
      },
    };
  }

  private fallbackValue(
    value: Translatable | null,
    defaultValue: Translatable = null,
  ): string | null {
    const translatedDefaultValue = this.translatableService.transform(defaultValue);

    if (!value) {
      return translatedDefaultValue;
    }

    const translatedValue = this.translatableService.transform(value);

    if (translatedValue === "") {
      return translatedDefaultValue;
    }

    if (translatedValue?.includes("pages.")) {
      return translatedDefaultValue;
    }

    return translatedValue || null;
  }

  private connectPageTitleUpdate(currentMetadata$: Observable<TransformedMetadataChange>): void {
    const pageTitle$ = currentMetadata$.pipe(
      distinctUntilChanged((previous, current) => {
        return (
          previous.action === current.action && previous.metadata.title === current.metadata.title
        );
      }),
    );

    pageTitle$
      .pipe(
        tap(({ action, metadata }) => this.onMetadataTitleChange(metadata.title, action)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private onMetadataTitleChange(title: string, action: MetadataChangeAction): void {
    switch (action) {
      case MetadataChangeAction.UPDATE:
        this.updateTitle(title);
        break;

      case MetadataChangeAction.REMOVE:
        this.removeTitle(title);
        break;

      default:
        throw new UnexpectedEnumValue(action, "MetadataChangeAction");
    }
  }

  private updateTitle(title: string): void {
    if (title === this.title.getTitle()) {
      return;
    }

    this.title.setTitle(title);
    this.logger.info("Applying page title", { title });
  }

  private removeTitle(title: string): void {
    if (this.title.getTitle() !== title) {
      return;
    }

    const defaultTitle = this.translatableService.transform(DEFAULT_METADATA.title);

    this.title.setTitle(defaultTitle);
    this.logger.info("Restoring default page title");
  }

  private connectMetaTagsUpdate(currentMetadata$: Observable<TransformedMetadataChange>): void {
    currentMetadata$
      .pipe(
        tap(({ action, metadata }) => this.onMetadataTagsChange(metadata.tags, action)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private onMetadataTagsChange(tags: MetaTag[], action: MetadataChangeAction): void {
    switch (action) {
      case MetadataChangeAction.UPDATE:
        this.applyTags(tags);
        break;

      case MetadataChangeAction.REMOVE:
        this.removeTags(tags);
        break;

      default:
        throw new UnexpectedEnumValue(action, "MetadataChangeAction");
    }
  }

  private removeTags(tags: MetaTag[]): void {
    tags?.forEach((tag) => {
      const attrSelector = isOgTagName(tag.name) ? "property" : "name";
      this.meta.removeTag(`${attrSelector}="${tag.name}"`);
    });

    this.logger.info("Removing page tags", { tags });
  }

  private applyTags(tags: MetaTag[]): void {
    tags?.forEach((tag) => {
      const content = this.translatableService.transform(tag.content);

      if (isOgTagName(tag.name)) {
        this.meta.updateTag({
          property: tag.name,
          content: this.transformOgTagContent(tag.name, content),
        });
      } else {
        this.meta.updateTag({ name: tag.name, content });
      }
    });

    this.logger.info("Applying page tags", { tags });
  }

  private transformOgTagContent(tagName: string, content: string): string {
    switch (tagName) {
      case OgTag.IMAGE_URL:
        return asUnsecureUrl(content);

      case OgTag.IMAGE_SECURE_URL:
        return asSecureUrl(content);

      default:
        return content;
    }
  }

  private connectLinkTagsUpdate(currentMetadata$: Observable<TransformedMetadataChange>): void {
    currentMetadata$
      .pipe(
        filter(({ metadata }) => metadata.linkTags != null),
        tap(({ action, metadata }) => this.onMetadataLinkTagsChange(metadata.linkTags, action)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private onMetadataLinkTagsChange(linkTags: LinkTag[], action: MetadataChangeAction): void {
    switch (action) {
      case MetadataChangeAction.UPDATE:
        this.linkTag.updateTags(linkTags);
        this.logger.info("Applying link tags", { linkTags });
        break;

      case MetadataChangeAction.REMOVE:
        this.linkTag.removeTags(linkTags);
        this.logger.info("Removing link tags", { linkTags });
        break;

      default:
        throw new UnexpectedEnumValue(action, "MetadataChangeAction");
    }
  }
}

enum MetadataChangeAction {
  UPDATE = "UPDATE",
  REMOVE = "REMOVE",
}

interface MetadataChange {
  action: MetadataChangeAction;
  metadata: PageMetadata;
}

interface TransformedMetadataChange {
  action: MetadataChangeAction;
  metadata: Omit<PageMetadata, "title"> & { title: string };
}
