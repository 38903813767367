import { Injectable } from "@angular/core";
import { IRegion } from "lrd-interfaces/interfaces";
import { ContactMethods } from "../../../data-v2/contact-methods";
import { SocialNetworks } from "../../../data-v2/social-networks";
import { limitTo } from "../../../helpers/arrays/filters";
import { AvailableRoutes } from "../../../services-v2/routing/available-routes.enum";
import { FooterViewModel } from "./footer-view-model";
import { RoutingService } from "../../../services-v2/routing/routing.service";
import { DynamicLinkType } from "../dynamic-link/dynamic-link-view-model";

@Injectable({
  providedIn: "root",
})
export class FooterFactory {
  constructor(private routingService: RoutingService) {}

  public fromRegion(region: IRegion): FooterViewModel {
    return {
      contacts: {
        title: { key: "components.footer.contacts.title" },
        contactMethods: ContactMethods,
        socialNetworks: SocialNetworks,
      },
      partners: {
        title: { key: "components.footer.partners.title" },
        partners: region.partners
          .filter((partner) => partner.type === "footer")
          .filter(limitTo(2))
          .map(({ name, logoCropped, url }) => ({ name, logoCropped, url })),
      },
      terms: {
        disclaimer: { key: "components.footer.terms.disclaimer.text" },
        links: [
          {
            label: { key: "components.footer.terms.disclaimer.links.one" },
            type: "routerLink",
            routerLink: this.routingService.routeFor(AvailableRoutes.DISCLAIMER),
          },
          {
            label: { key: "components.footer.terms.disclaimer.links.two" },
            type: "routerLink",
            routerLink: this.routingService.routeFor(AvailableRoutes.PRIVACY_POLICY),
          },
        ],
      },
      newsletter: {
        title: { key: "components.footer.newsletter.title" },
        button: {
          type: "externalLink",
          label: { key: "components.footer.newsletter.button.label" },
          externalUrl: { key: "components.footer.newsletter.button.link" },
        },
      },
      links: {
        columns: [
          {
            items: [
              {
                label: { key: "components.footer.links.main.one.label" },
                items: [
                  {
                    label: { key: "components.footer.links.main.one.secondary.one" },
                    link: {
                      type: "routerLink",
                      routerLink: this.routingService.routeFor(AvailableRoutes.OUR_SERVICES),
                    },
                  },
                  {
                    label: { key: "components.footer.links.main.one.secondary.two" },
                    link: {
                      type: "externalLink",
                      externalUrl: this.routingService
                        .routeFor(AvailableRoutes.ADDITIONAL_FINANCING)
                        .join(""),
                    },
                  },
                  {
                    label: { key: "components.footer.links.main.one.secondary.three" },
                    link: {
                      type: "routerLink",
                      routerLink: this.routingService.routeFor(AvailableRoutes.LAUNCH_CAMPAIGN),
                    },
                  },
                  {
                    label: { key: "components.footer.links.main.one.secondary.four" },
                    link: {
                      type: "externalLink" as DynamicLinkType,
                      externalUrl: this.routingService
                        .routeFor(AvailableRoutes.INFORMATION_SESSION)
                        .join(""),
                    },
                  },
                  {
                    label: { key: "components.footer.links.main.one.secondary.five" },
                    link: {
                      type: "externalLink" as DynamicLinkType,
                      externalUrl: this.routingService
                        .routeFor(AvailableRoutes.FREE_CONSULTATION)
                        .join(""),
                    },
                  },
                ],
              },
            ],
          },
          {
            items: [
              {
                label: { key: "components.footer.links.main.two.label" },
                class: "isolated-top",
                link: {
                  type: "routerLink",
                  routerLink: this.routingService.routeFor(AvailableRoutes.CONTRIBUTE),
                },
              },
              {
                label: { key: "components.footer.links.main.three.label" },
                items: [
                  {
                    label: { key: "components.footer.links.main.three.secondary.one" },
                    link: {
                      type: "routerLink",
                      routerLink: this.routingService.routeFor(AvailableRoutes.ABOUT),
                    },
                  },
                  {
                    label: { key: "components.footer.links.main.three.secondary.two" },
                    link: {
                      type: "routerLink",
                      routerLink: this.routingService.routeFor(AvailableRoutes.TEAM),
                    },
                  },
                  {
                    label: { key: "components.footer.links.main.three.secondary.three" },
                    link: {
                      type: "routerLink",
                      routerLink: this.routingService.routeFor(AvailableRoutes.BOARD),
                    },
                  },
                  {
                    label: { key: "components.footer.links.main.three.secondary.four" },
                    link: {
                      type: "routerLink",
                      routerLink: this.routingService.routeFor(AvailableRoutes.PRESS),
                    },
                  },
                  {
                    label: { key: "components.footer.links.main.three.secondary.five" },
                    link: {
                      type: "routerLink",
                      routerLink: this.routingService.routeFor(AvailableRoutes.NEWS),
                    },
                  },
                  {
                    label: { key: "components.footer.links.main.three.secondary.six" },
                    link: {
                      type: "routerLink",
                      routerLink: this.routingService.routeFor(AvailableRoutes.CAREER),
                    },
                  },
                  {
                    label: { key: "components.footer.links.main.three.secondary.seven" },
                    link: {
                      type: "routerLink",
                      routerLink: this.routingService.routeFor(AvailableRoutes.FAQ),
                    },
                  },
                ],
              },
            ],
          },
          {
            items: [
              {
                label: { key: "components.footer.links.main.four.label" },
                items: [
                  {
                    label: { key: "components.footer.links.main.four.secondary.one" },
                    link: {
                      type: "routerLink",
                      routerLink: this.routingService.routeFor(AvailableRoutes.PARTNER_DESJARDINS),
                    },
                  },
                  {
                    label: { key: "components.footer.links.main.four.secondary.two" },
                    link: {
                      type: "routerLink",
                      routerLink: this.routingService.routeFor(AvailableRoutes.PARTNERS),
                    },
                  },
                  {
                    label: { key: "components.footer.links.main.four.secondary.three" },
                    link: {
                      type: "routerLink",
                      routerLink: this.routingService.routeFor(AvailableRoutes.ALLIES),
                    },
                  },
                  {
                    label: { key: "components.footer.links.main.four.secondary.four" },
                    link: {
                      type: "routerLink",
                      routerLink: this.routingService.routeFor(AvailableRoutes.AMBASSADORS),
                    },
                  },
                ],
              },
              {
                label: { key: "components.footer.links.main.five.label" },
                class: "isolated-bottom",
                link: {
                  type: "routerLink",
                  routerLink: this.routingService.routeFor(AvailableRoutes.CONTACT_US),
                },
              },
              {
                label: { key: "alternate-language-full" },
                link: {
                  type: "externalLink",
                  externalUrl: this.routingService
                    .alternateLanguageRouteFor(AvailableRoutes.HOME)
                    .join(""),
                  target: "_self",
                },
              },
            ],
          },
        ],
      },
    };
  }
}
