import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Icons } from "@app/shared-v2/icons";
import { TranslatablePipe } from "@app/shared-v2/pipes/translatable.pipe";
import { IconComponent } from "../icon/icon.component";

@Component({
  standalone: true,
  imports: [CommonModule, TranslatablePipe, IconComponent],
  selector: "app-icon-title-container",
  templateUrl: "./icon-title-container.component.html",
  styleUrls: ["./icon-title-container.component.scss"],
})
export class IconTitleContainerComponent {
  @Input() logo = Icons.EXCHANGE;
  @Input() blackTitle = false;
  @Input() title: string;
}
