import { AsyncPipe, NgIf } from "@angular/common";
import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Injector,
  Input,
  QueryList,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import {
  animationFrameScheduler,
  Observable,
  observeOn,
  of,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { first, map, startWith } from "rxjs/operators";
import { asQueryListChangeObservable } from "../../../../helpers/observables/helpers";
import { doFirstThen } from "../../../../helpers/observables/operators";
import { dropdownAnimation } from "../../../animations/dropdown.animation";
import { EnumeratePipe } from "../../../pipes/enumerate.pipe";
import { IconComponent } from "../../icon/icon.component";
import { BaseLrdFieldDirective } from "../base-lrd-field.directive";
import { LrdFormFieldContextService } from "../lrd-form-field-context.service";
import { LrdOptionComponent } from "./lrd-option/lrd-option.component";
import { LrdSelectContextService } from "./lrd-select-context.service";

@Component({
  selector: "app-lrd-select",
  standalone: true,
  imports: [IconComponent, AsyncPipe, NgIf, MatProgressSpinnerModule],
  templateUrl: "./lrd-select.component.html",
  styleUrls: ["./lrd-select.component.scss"],
  animations: [dropdownAnimation],
  providers: [
    LrdSelectContextService,
    EnumeratePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LrdSelectComponent),
      multi: true,
    },
    {
      provide: BaseLrdFieldDirective,
      useExisting: LrdSelectComponent,
    },
  ],
})
export class LrdSelectComponent<T>
  extends BaseLrdFieldDirective<T | T[]>
  implements AfterContentInit
{
  @Input()
  public get multiselect(): boolean {
    return this.selectContext.getIsMultiSelect();
  }

  public set multiselect(isMultiselect: boolean) {
    this.selectContext.setIsMultiSelect(isMultiselect);
  }

  @Input()
  public get multiselectMax(): number {
    return this.selectContext.getMaxSelectionLength();
  }

  public set multiselectMax(max: number) {
    this.selectContext.setMaxSelectionLength(max);
  }

  @Input()
  placeholder: string;

  @Input()
  valueDisplayType: LrdSelectLabelType = "selection";

  @Input()
  @HostBinding("class.lrd-loading")
  isLoading = false;

  @Input()
  trackBy?: string;

  @HostBinding("tabindex")
  tabindex = 0;

  @HostBinding("class.lrd-open")
  isOpen = false;

  @HostBinding("class.lrd-disabled")
  isDisabled = false;

  @HostBinding("attr.role")
  readonly accessibilityRole = "listbox";

  @ContentChildren(LrdOptionComponent)
  options: QueryList<LrdOptionComponent<T>>;

  valueText$: Observable<string>;

  protected override allowIcon = false;

  constructor(
    @Inject(Injector) injector: Injector,
    formFieldContext: LrdFormFieldContextService,
    elementRef: ElementRef<HTMLInputElement | HTMLTextAreaElement>,
    private selectContext: LrdSelectContextService<T>,
    private enumeratePipe: EnumeratePipe,
  ) {
    super(injector, formFieldContext, elementRef);
  }

  ngAfterContentInit(): void {
    const optionsChange$ = asQueryListChangeObservable(this.options);

    const valueChange$ = this.selectContext.fieldValue();
    const formFieldLabelClick$ = this.formFieldContext.labelClick();

    this.subscribeToOptionsChange(optionsChange$);
    this.subscribeToValueChange(valueChange$);
    this.bindTextValue(optionsChange$, valueChange$);
    this.subscribeToFormFieldLabelClick(formFieldLabelClick$);
  }

  public selectAll(): void {
    this.selectContext.selectAll();
  }

  public deselectAll(): void {
    this.selectContext.deselectAll();
  }

  public toggleAll(): void {
    if (this.areAllSelected()) {
      this.deselectAll();
    } else {
      this.selectAll();
    }
  }

  public areAllSelected(): boolean {
    return this.selectContext.areAllSelected();
  }

  private subscribeToFormFieldLabelClick(formFieldLabelClick$: Observable<MouseEvent>): void {
    formFieldLabelClick$
      .pipe(
        tap((event) => {
          event.stopPropagation();
          this.toggle();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private subscribeToOptionsChange(
    optionsChange$: Observable<QueryList<LrdOptionComponent<T>>>,
  ): void {
    optionsChange$
      .pipe(
        startWith(this.options),
        map((options) => options.filter((it) => !it.ignored)),
        tap((options) => options.forEach((it, index) => it.setIndex(index))),
        map((options) => options.map((it) => it.value)),
        tap((values) => this.selectContext.setOptions(values)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private bindTextValue(
    optionsChange$: Observable<QueryList<LrdOptionComponent<T>>>,
    valueChange$: Observable<T[] | T>,
  ): void {
    if (this.valueDisplayType === "placeholder") {
      this.valueText$ = of(this.placeholder);
      return;
    }

    this.valueText$ = optionsChange$.pipe(
      switchMap((options) => {
        return valueChange$.pipe(
          observeOn(animationFrameScheduler),
          map(() => options.filter((it) => it.selected)),
          map((selectedOptions) => selectedOptions.map((it) => it.innerText())),
          map((selectedOptionsText) => {
            return selectedOptionsText.length
              ? this.enumeratePipe.transform(selectedOptionsText)
              : this.placeholder;
          }),
        );
      }),
    );
  }

  private subscribeToValueChange(valueChange$: Observable<T[] | T>): void {
    valueChange$
      .pipe(
        doFirstThen(
          (value) => {
            // count > 1 prevents the NgControl to see the field as dirty
            // we might want to make it prettier at some point.
            this.setValue(value, false);
          },
          (value) => {
            this.setValue(value);

            if (this.isOpen) {
              this.close();
            }
          },
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public override writeValue(newValues: T | T[]): void {
    this.selectContext
      .isReady()
      .pipe(
        first(),
        tap(() => this.selectContext.setSelectedOptions(newValues, this.trackBy as keyof T)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  protected override onDisabledChange(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.tabindex = isDisabled ? -1 : 0;
  }

  @HostListener("window:click", ["$event"])
  onHostClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const hostElement = this.elementRef.nativeElement;

    if (this.isDisabled) {
      return;
    }

    if (target == hostElement || hostElement.contains(target)) {
      event.preventDefault();
      this.toggle();
      return;
    }

    if (this.isOpen) {
      this.close(false);
    }
  }

  @HostListener("keydown.arrowdown", ["$event"])
  onHostArrowDownKeydown(event: KeyboardEvent): void {
    event.preventDefault();

    if (this.isDisabled) {
      return;
    }

    if (!this.isOpen) {
      this.open();
      return;
    }

    const focusedOptionIndex = this.getFocusedOptionIndex();

    if (focusedOptionIndex == -1) {
      return;
    }

    if (focusedOptionIndex < this.options.length) {
      this.options.get(focusedOptionIndex + 1)?.focus();
    }
  }

  @HostListener("keydown.arrowup", ["$event"])
  onHostArrowUpKeydown(event: KeyboardEvent): void {
    event.preventDefault();

    if (this.isDisabled) {
      return;
    }

    if (!this.isOpen) {
      return;
    }

    const focusedOptionIndex = this.getFocusedOptionIndex();

    if (focusedOptionIndex == -1) {
      return;
    }

    if (focusedOptionIndex > 0) {
      this.options.get(focusedOptionIndex - 1).focus();
    }
  }

  @HostListener("keydown.escape", ["$event"])
  onHostEscapeKeydown(event: KeyboardEvent): void {
    event.preventDefault();

    if (this.isDisabled) {
      return;
    }

    if (this.isOpen) {
      this.close();
    }
  }

  @HostListener("keydown.space", ["$event"])
  onHostSpaceKeydown(event: KeyboardEvent): void {
    event.preventDefault();

    if (this.isDisabled) {
      return;
    }

    if (!this.isOpen) {
      this.open();
    }
  }

  public open(): void {
    this.isOpen = true;

    setTimeout(() => this.focusSelected());
  }

  public close(autoFocus = true): void {
    this.isOpen = false;

    if (!this.touched) {
      this.markAsTouched();
    }

    if (autoFocus) {
      this.elementRef.nativeElement.focus();
    }
  }

  public toggle(): void {
    if (!this.isOpen) {
      this.open();
    } else {
      this.close();
    }
  }

  private focusSelected(): void {
    const selectedOptions = this.selectContext.getSelectedOptions();
    const firstSelectedOption = this.options.find((option) => option.value === selectedOptions[0]);
    firstSelectedOption?.focus();
  }

  private getFocusedOptionIndex(): number {
    return this.options.toArray().findIndex((option) => option.isFocused());
  }
}

export type LrdSelectLabelType = "selection" | "placeholder";
