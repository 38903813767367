import { Injectable } from "@angular/core";
import { Colors } from "@app/shared-v2/colors";
import { Icons } from "@app/shared-v2/icons";
import { ResetPasswordViewModel } from "./reset-password-view-model";

@Injectable({
  providedIn: "root",
})
export class ResetPasswordFactory {
  public fromDefault(): ResetPasswordViewModel {
    return {
      request: {
        title: { key: "pages.reset-password.request.title" },
        managedErrors: {
          RESET_EMAIL_NOT_FOUND: {
            key: "pages.reset-password.request.errors.RESET_EMAIL_NOT_FOUND",
          },
          RESET_LIMIT_EXCEEDED: {
            key: "pages.reset-password.request.errors.RESET_LIMIT_EXCEEDED",
          },
        },
        description: { key: "pages.reset-password.request.description" },
        formUI: {
          email: {
            label: { key: "pages.reset-password.request.form.email.label" },
          },
          submit: {
            label: { key: "pages.reset-password.request.form.submit.label" },
          },
        },
        dismissButton: {
          color: Colors.YELLOW,
          icon: Icons.CROSS,
        },
      },
      reset: {
        title: { key: "pages.reset-password.reset.title" },
        description: { key: "pages.reset-password.reset.description" },
        managedErrors: {
          CONFIRM_CODE_MISMATCH: {
            key: "pages.reset-password.reset.errors.CONFIRM_CODE_MISMATCH",
          },
        },
        formUI: {
          validationCode: {
            label: { key: "pages.reset-password.reset.form.validation-code.label" },
          },
          password: {
            label: { key: "pages.reset-password.reset.form.password.label" },
            tooltip: { key: "pages.reset-password.reset.form.password.tooltip" },
          },
          passwordValidation: {
            label: { key: "pages.reset-password.reset.form.password-validation.label" },
          },

          submit: {
            label: { key: "pages.reset-password.reset.form.submit.label" },
          },
        },
        dismissButton: {
          color: Colors.YELLOW,
          icon: Icons.CROSS,
        },
      },
    };
  }
}
