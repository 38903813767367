import { Component } from "@angular/core";
import { TranslatablePipe } from "@app/shared-v2/pipes/translatable.pipe";
import { LoadingComponent } from "../../loading/loading.component";

@Component({
  standalone: true,
  imports: [TranslatablePipe, LoadingComponent],
  selector: "app-ai-drawer-loading-card",
  templateUrl: "./loading-card.component.html",
  styleUrls: ["./loading-card.component.scss"],
})
export class AiDrawerLoadingCardComponent {}
