export enum ThirdPartyAuthPlatform {
  FACEBOOK = "Facebook",
  GOOGLE = "Google",
  DESJARDINS = "Desjardins",
  LA_RUCHE_QUEBEC = "LaRucheQuebec",
}

export const DESJARDINS_EMAIL_DOMAINS: string[] = [
  "acpol.com",
  "assistel.com",
  "cadesjardins.com",
  "collabriafinancial.com",
  "desjardins.com",
  "dfs.ca",
  "dgag.ca",
  "dgig.ca",
  "dsf.ca",
  "lapersonnelle.com",
  "mvt.desjardins.com",
  "sfl.qc.ca",
  "thepersonal.com",
  "uni.ca",
  "zagbank.ca",
  "desjardins.com",
  "mvtdevdesjardins.com",
];

export const LA_RUCHE_EMAIL_DOMAINS: string[] = ["laruchequebec.com"];
