import { Injectable } from "@angular/core";
import { ApiService } from "../services/apiService";
import { ApiServicesList } from "@app/shared/enumerations/apiServiceEnums";
import { IPromotionBanner, PromotionBannerLocationEnum } from "lrd-interfaces/interfaces";

@Injectable({
  providedIn: "root",
})
export class PromotionBannerService {
  constructor(private apiService: ApiService) {}

  public async deletePromotionBanner(promotionBannerId: string) {
    return this.apiService.delete(ApiServicesList.PROMOTION_BANNER_SERVICE, promotionBannerId);
  }

  public async getPromotionBanner(promotionBannerId: string): Promise<IPromotionBanner> {
    return this.apiService
      .get(ApiServicesList.PROMOTION_BANNER_SERVICE, promotionBannerId, {})
      .then((response) => {
        if (response?.items && response.items.length < 1) {
          throw new Error("Promotion banner not found");
        }

        return response.items[0];
      });
  }

  public async getPromotionBanners(): Promise<IPromotionBanner[]> {
    return this.apiService
      .get(ApiServicesList.PROMOTION_BANNER_SERVICE, "")
      .then((response) => (response?.items ? response.items : []));
  }

  public async getRandomPromotionBanners(options?: {
    limit?: number;
    location?: PromotionBannerLocationEnum;
  }): Promise<IPromotionBanner[]> {
    const { limit = undefined, location = undefined } = options || {};

    const parameters = {};

    if (limit) {
      parameters["limit"] = limit;
    }

    if (location) {
      parameters["locations"] = location;
    }

    return this.apiService
      .get(ApiServicesList.PROMOTION_BANNER_SERVICE, "randoms", { parameters })
      .then((response) => (response?.items ? response.items : []))
      .catch(() => []);
  }

  public async postPromotionBanner(data: IPromotionBanner): Promise<IPromotionBanner> {
    return this.apiService.post(ApiServicesList.PROMOTION_BANNER_SERVICE, "", data);
  }
}
