<div class="container">
  <p class="title">
    <app-v2-icon icon="spark" /> {{ "components.ai-drawer.title" | translatable }}
  </p>

  <div class="action-buttons-group">
    <button
      *ngIf="(generateActionStatus$ | async) !== status.HIDDEN"
      app-v2-button
      color="purple"
      (click)="handleOnGenerateContentClick()"
      [disabled]="(generateActionStatus$ | async) !== status.ENABLED"
      [busy]="(generateActionStatus$ | async) === status.BUSY"
      [compact]="true"
    >
      {{ generateButtonLabel$ | async }}
    </button>

    <button
      *ngIf="(enhanceActionStatus$ | async) !== status.HIDDEN"
      app-v2-button
      color="purple"
      (click)="handleOnEnhanceContentClick()"
      [disabled]="(enhanceActionStatus$ | async) !== status.ENABLED"
      [busy]="(enhanceActionStatus$ | async) === status.BUSY"
      [compact]="true"
    >
      {{ enhanceButtonLabel$ | async }}
    </button>

    <button
      *ngIf="(translateActionStatus$ | async) !== status.HIDDEN"
      app-v2-button
      color="purple"
      (click)="handleOnTranslateContentClick()"
      [disabled]="(translateActionStatus$ | async) !== status.ENABLED"
      [busy]="(translateActionStatus$ | async) === status.BUSY"
      [compact]="true"
    >
      {{ translateButtonLabel$ | async }}
    </button>
  </div>

  <div class="drawer" *ngIf="isDrawerOpen$ | async">
    <app-ai-drawer-controls
      [actionForm]="actionForm"
      [tones]="tones"
      [maxChar]="maxChar"
      (submitForm)="handleOnSubmitEnhanceForm()"
    />

    <div>
      <p class="section-title">{{ "components.ai-drawer.suggestion.title" | translatable }}</p>

      <ul class="card-list">
        <li *ngFor="let card of cards$ | async">
          <app-ai-drawer-card
            [content]="card.content"
            [tones]="card.tones"
            [locked]="card.locked"
            (toggleLock)="handleOnToggleLock(card.id)"
            (selectCard)="handleOnSelectCard(card.id)"
          />
        </li>

        <li *ngIf="(enhanceActionStatus$ | async) === status.BUSY">
          <app-ai-drawer-loading-card />
        </li>
      </ul>
    </div>
  </div>
</div>
