import { QueryList } from "@angular/core";
import { AbstractControl, FormControlStatus } from "@angular/forms";
import { StateData } from "@shared-v2/utils/state-data";
import {
  catchError,
  combineLatest,
  from,
  map,
  Observable,
  ObservableInput,
  of,
  ReplaySubject,
  retry,
  RetryConfig,
  startWith,
  Subject,
} from "rxjs";
import { distinctUntilChanged, first } from "rxjs/operators";
import { asSuccessStateData, pick } from "./mappers";
import { startWithPending } from "./operators";

export function asStateDataObservable<T, E = unknown>(
  observableOrPromise: Observable<T> | Promise<T>,
): Observable<StateData<T, E>> {
  if (observableOrPromise instanceof Promise) {
    observableOrPromise = from(observableOrPromise);
  }

  return observableOrPromise.pipe(
    map(asSuccessStateData<T, E>()),
    startWithPending<T, E>(),
    catchError((error) => of(StateData.createWithErrors<T, E>(error))),
  );
}

export function asQueryListChangeObservable<T>(queryList: QueryList<T>): Observable<QueryList<T>> {
  return (queryList.changes as Observable<QueryList<T>>).pipe(startWith(queryList));
}

export function asReplaySubject<T>(source: Observable<T>, bufferSize?: number): ReplaySubject<T> {
  const replaySubject = new ReplaySubject<T>(bufferSize);
  source.subscribe(replaySubject);
  return replaySubject;
}

export function asSubject<T>(source: Observable<T>): Subject<T> {
  const subject = new Subject<T>();
  source.subscribe(subject);
  return subject;
}

export function asFormControlObservable<T>(
  control: AbstractControl<T>,
): Observable<FormControlChange<T>> {
  const value$ = control.valueChanges;
  const status$ = control.statusChanges;

  return combineLatest([value$, status$]).pipe(
    map(([value, status]) => ({ value, status })),
    startWith({ value: control.value, status: control.status }),
  );
}

export interface FormControlChange<T> {
  value: T;
  status: FormControlStatus;
}

export function asFormControlValueObservable<T extends AbstractControl>(
  control: T,
): Observable<T["value"]> {
  return asFormControlObservable(control).pipe(map(pick("value")), distinctUntilChanged());
}

export function asFormControlStatusObservable(
  control: AbstractControl<unknown>,
): Observable<FormControlStatus> {
  return asFormControlObservable(control).pipe(map(pick("status")), distinctUntilChanged());
}

export function retryOnError<T>(
  request: ObservableInput<T>,
  retryConfig: RetryConfig = {},
): Observable<T> {
  return from(request).pipe(retry(retryConfig), first());
}
