import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { LinkTag } from "../types";

const LINK_TAG_SELECTOR = "link";

@Injectable({ providedIn: "root" })
export class LinkTagService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public updateTag(tag: LinkTag): void {
    let linkTagEl = this.document.getElementById(tag.id) as HTMLLinkElement;
    const useNewElement: boolean = linkTagEl == null;

    if (useNewElement) {
      linkTagEl = this.document.createElement(LINK_TAG_SELECTOR);
    }

    for (const key in tag) {
      linkTagEl.setAttribute(key.toLowerCase(), tag[key]);
    }

    if (useNewElement) {
      this.document.head.appendChild(linkTagEl);
    }
  }

  public removeTag(tag: LinkTag): void {
    this.document.getElementById(tag.id)?.remove();
  }

  public updateTags(tags: LinkTag[]): void {
    tags.forEach((tag) => this.updateTag(tag));
  }

  public removeTags(tags: LinkTag[]): void {
    tags.forEach((tag) => this.removeTag(tag));
  }
}
