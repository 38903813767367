import { CommonModule } from "@angular/common";
import { Component, HostListener, Input, OnInit } from "@angular/core";
import { RouterLink } from "@angular/router";
import { TranslatablePipe } from "@app/shared-v2/pipes/translatable.pipe";
import { Translatable } from "@app/types";
import { ButtonComponent } from "../button/button.component";
import { DynamicButtonType, DynamicButtonViewModel } from "./dynamic-button-view-model";

@Component({
  selector: "app-dynamic-button",
  standalone: true,
  imports: [CommonModule, ButtonComponent, RouterLink, TranslatablePipe],
  templateUrl: "./dynamic-button.component.html",
  styleUrls: ["./dynamic-button.component.scss"],
})
export class DynamicButtonComponent
  extends ButtonComponent
  implements OnInit, DynamicButtonViewModel
{
  @Input() type: DynamicButtonType = "button";
  @Input() externalUrl?: Translatable;
  @Input() trackingClass?: string;
  @Input() customStyles?: Record<string, string>;

  /** @deprecated Use {@link DynamicButtonComponent.routerLinkUrl} instead */
  @Input() routerLink?: unknown[] | string;
  @Input() routerLinkUrl?: unknown[] | string;

  @Input() queryParams?: object;
  @Input() disabled?: boolean;

  @Input("accessibleTitle")
  accessibleTitle?: string;

  /**
   * @warning
   * Should only be used if component options are static.
   * Changes on @Inputs values may not behave as expected otherwise.
   */
  @Input() useImmutableViewModel?: Readonly<DynamicButtonViewModel>;

  @HostListener("click", ["$event"])
  override onHostClick(): void {
    // Overriding parent's onHostClick method with noop to
    // prevent duplicate event handling.
  }
}
