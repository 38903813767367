import { Injectable } from "@angular/core";
import { Colors } from "src/app/shared-v2/colors";
import { Icons } from "@app/shared-v2/icons";
import { EmailValidationViewModel } from "./email-validation-view-model";

@Injectable({
  providedIn: "root",
})
export class EmailValidationFactory {
  public fromDefault(): EmailValidationViewModel {
    return {
      title: { key: "pages.email-validation.title" },
      description: { key: "pages.email-validation.description" },
      help: {
        title: { key: "pages.email-validation.help.title" },
        description: { key: "pages.email-validation.help.description" },
        success: { key: "pages.email-validation.help.success" },
      },
      formUI: {
        validationCode: { label: { key: "pages.email-validation.form.validation-code.label" } },
        submit: {
          label: { key: "pages.email-validation.form.submit.label" },
        },
      },
      managedErrors: {
        ERROR_CODE_MISSMATCH: {
          key: "pages.email-validation.errors.ERROR_CODE_MISSMATCH",
        },
      },
      resendButton: {
        color: Colors.WHITE,
        iconLeft: Icons.CONTACT_EMAIL,
        compact: true,
        label: { key: "pages.email-validation.resend-button.label" },
      },
      dismissButton: {
        color: Colors.YELLOW,
        icon: Icons.ARROW_RIGHT,
      },
    };
  }
}
