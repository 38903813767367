import { MetaTag, Translatable } from "../../../types";
import { SocialMediaTag } from "./social-media-tag";

export enum OgTag {
  DESCRIPTION = "og:description",
  IMAGE_HEIGHT = "og:image:height",
  IMAGE_WIDTH = "og:image:width",
  IMAGE_URL = "og:image",
  IMAGE_SECURE_URL = "og:image:secure_url",
  SITE_NAME = "og:site_name",
  TITLE = "og:title",
  TYPE = "og:type",
  URL = "og:url",
}

export const DEFAULT_OG_TYPE = "website";
export const DEFAULT_OG_IMAGE_WIDTH = "600";
export const DEFAULT_OG_IMAGE_HEIGHT = "350";

export function buildOgTags(builder: OgTagsBuilder): MetaTag[] {
  const ogTags: MetaTag[] = [
    {
      name: OgTag.TYPE,
      content: builder.type ?? DEFAULT_OG_TYPE,
    },
    {
      name: OgTag.URL,
      content: builder.url,
    },
  ];

  if (builder.title) {
    ogTags.push({ name: OgTag.TITLE, content: builder.title });
  }

  if (builder.description) {
    ogTags.push({ name: OgTag.DESCRIPTION, content: builder.description });
  }

  if (builder.image) {
    const {
      image,
      imageWidth = DEFAULT_OG_IMAGE_WIDTH,
      imageHeight = DEFAULT_OG_IMAGE_HEIGHT,
    } = builder;

    ogTags.push(
      { name: OgTag.IMAGE_URL, content: image },
      { name: OgTag.IMAGE_SECURE_URL, content: image },
      { name: OgTag.IMAGE_WIDTH, content: imageWidth.toString() },
      { name: OgTag.IMAGE_HEIGHT, content: imageHeight.toString() },
    );
  }

  if (builder.siteName) {
    ogTags.push({ name: OgTag.SITE_NAME, content: builder.siteName });
  }

  return ogTags;
}

export function isOgTagName(name: string): boolean {
  return name.startsWith("og:");
}

export interface OgTagsBuilder extends SocialMediaTag {
  url: string;
  imageWidth?: number;
  imageHeight?: number;
  siteName?: Translatable;
  type?: Translatable;
}
