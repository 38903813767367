<div class="container">
  <div class="messages">
    <h2>{{ options?.title | translate }}</h2>

    <p class="text" [class.bold]="options.boldText">
      {{ options.text | translate }}
    </p>

    <div class="button-container">
      <button
        app-v2-button
        *ngFor="let action of options.actions"
        [color]="action.color"
        (click)="onAction(action)"
      >
        {{ action.label | translate }}
      </button>
    </div>
  </div>

  <div class="close" [class.is-hidden]="options.closeButtonHidden">
    <app-button [color]="'round-dark'" class="close-button" (onClick)="close()">
      <i class="material-icons">close</i>
    </app-button>
  </div>
</div>
