import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ButtonComponent } from "../../button/button.component";
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from "@angular/forms";
import { LrdSelectComponent } from "../../lrd-form-field/lrd-select/lrd-select.component";
import { LrdFormFieldComponent } from "../../lrd-form-field/lrd-form-field.component";
import { LrdOptionComponent } from "../../lrd-form-field/lrd-select/lrd-option/lrd-option.component";
import { TranslatablePipe } from "@app/shared-v2/pipes/translatable.pipe";
import { LrdInputDirective } from "../../lrd-form-field/lrd-input.directive";
import { GptToneI18NKey } from "@app/services-v2/gpt.service";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    FormsModule,
    LrdSelectComponent,
    LrdFormFieldComponent,
    LrdOptionComponent,
    LrdInputDirective,
    TranslatablePipe,
    ReactiveFormsModule,
  ],
  selector: "app-ai-drawer-controls",
  templateUrl: "./ai-drawer-controls.component.html",
  styleUrls: ["./ai-drawer-controls.component.scss"],
})
export class AiDrawerControlsComponent {
  @Input() actionForm: UntypedFormGroup;
  @Input() maxChar: number;

  tones = Object.keys(GptToneI18NKey).map((key) => ({
    value: key,
    label: GptToneI18NKey[key],
  }));

  @Output() submitForm = new EventEmitter<null>();

  handleOnSubmit() {
    this.submitForm.emit();
  }
}
