import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@shared/translations/translate.service";

@Pipe({
  name: "translate",
  pure: false,
})
export class TranslatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(key: any, locale?: string): any {
    if (locale && locale !== this.translate.currentLang) {
      return this.translate.instantAlternate(key);
    }
    return this.translate.instant(key);
  }
}
