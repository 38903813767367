import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { animationFrameScheduler, observeOn, Subject, takeUntil, tap } from "rxjs";
import { filter } from "rxjs/operators";
import { IconComponent } from "../../../icon/icon.component";
import { LrdSelectContextService } from "../lrd-select-context.service";

@Component({
  selector: "app-lrd-option",
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: "./lrd-option.component.html",
  styleUrls: ["./lrd-option.component.scss"],
})
export class LrdOptionComponent<T> implements OnInit, OnDestroy {
  @Input({ required: true })
  value: T = null;

  @Input()
  ignored = false;

  @Input()
  @HostBinding("attr.aria-selected")
  selected = false;

  @HostBinding("tabindex")
  tabindex = 0;

  @HostBinding("attr.role")
  readonly accessibilityRole = "option";

  @HostBinding("attr.title")
  get titleAttr(): string {
    return this.innerText().trim();
  }

  focused = false;

  private index: number;
  private destroy$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private selectContext: LrdSelectContextService<T>,
  ) {}

  ngOnInit(): void {
    this.selectContext
      .selectedOptions()
      .pipe(
        observeOn(animationFrameScheduler),
        filter(() => !this.ignored),
        tap((selectedValues) => (this.selected = selectedValues.includes(this.value))),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  @HostListener("focus")
  onHostFocus(): void {
    this.focused = true;
  }

  @HostListener("blur")
  onHostBlur(): void {
    this.focused = false;
  }

  @HostListener("keydown.tab", ["$event"])
  onHostTabKeydown(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener("keydown.space", ["$event"])
  onHostSpaceKeydown(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.onUserAction();
  }

  @HostListener("click", ["$event"])
  onHostClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.onUserAction();
  }

  private onUserAction(): void {
    if (this.ignored) {
      return;
    }

    if (!this.selectContext.getIsMultiSelect()) {
      this.select();
      return;
    }

    if (this.selected) {
      this.deselect();
    } else {
      this.select();
    }
  }

  public focus(): void {
    this.elementRef.nativeElement.focus();
  }

  public isFocused(): boolean {
    return this.focused;
  }

  public innerText(): string {
    return this.elementRef.nativeElement.innerText;
  }

  public setIndex(index: number): void {
    this.index = index;
  }

  public select(): void {
    this.selectContext.select(this.index);
  }

  public deselect(): void {
    this.selectContext.deselect(this.index);
  }
}
