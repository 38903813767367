import { Renderer2 } from "@angular/core";
import { environment } from "src/environments/environment";

export const loadGTMScript = (renderer: Renderer2, document: Document) => {
  loadScript(renderer, document);
  loadNoScript(renderer, document);
};

function loadScript(renderer: Renderer2, document: Document) {
  const script = renderer.createElement("script");

  script.type = "text/javascript";
  script.innerHTML = `
    // Google Tag Manager
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${environment.gtmCode}');
  `;

  renderer.appendChild(document.body, script);
}

function loadNoScript(renderer: Renderer2, document: Document) {
  const noscript = renderer.createElement("noscript");

  noscript.innerHTML = `
    // Google Tag Manager (noscript)
    <iframe src="https://www.googletagmanager.com/ns.html?id=${environment.gtmCode}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;

  renderer.appendChild(document.body, noscript);
}
