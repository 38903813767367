import { Injectable } from "@angular/core";
import { Icons } from "@app/shared-v2/icons";
import { Colors } from "src/app/shared-v2/colors";
import { ThirdPartyAuthPlatform } from "../../../services-v2/third-party-auth/third-party-auth-platform";
import { SignInViewModel } from "./sign-in-view-model";

@Injectable({
  providedIn: "root",
})
export class SignInFactory {
  public fromDefault(): SignInViewModel {
    return {
      login: {
        title: { key: "pages.sign-in.login.title" },
        signUp: {
          title: { key: "pages.sign-in.login.register.title" },
          button: {
            label: { key: "pages.sign-in.login.register.link" },
            color: Colors.WHITE,
            compact: true,
          },
        },
        formUI: {
          email: { label: { key: "pages.sign-in.login.form.email.label" } },
          password: { label: { key: "pages.sign-in.login.form.password.label" } },
          forgotPassword: { key: "pages.sign-in.login.form.forgot-password.label" },
          rememberMe: { label: { key: "pages.sign-in.login.form.remember-me.label" } },
          submit: {
            label: { key: "pages.sign-in.login.form.submit.label" },
          },
        },
        managedErrors: {
          LOGIN_INVALID_USERNAME_PASSWORD: {
            key: "pages.sign-in.login.errors.LOGIN_INVALID_USERNAME_PASSWORD",
          },
          LOGIN_METHOD_INVALID: {
            key: "pages.sign-in.login.errors.LOGIN_METHOD_INVALID",
          },
        },
        socialUI: {
          description: { key: "pages.sign-in.login.social.description" },
          options: [
            {
              platform: ThirdPartyAuthPlatform.GOOGLE,
              icon: Icons.SOCIAL_GOOGLE,
            },
            {
              platform: ThirdPartyAuthPlatform.FACEBOOK,
              icon: Icons.SOCIAL_FACEBOOK,
            },
          ],
        },
        dismissButton: {
          color: Colors.YELLOW,
          icon: Icons.CROSS,
        },
      },
      setupMFA: {
        title: { key: "pages.sign-in.setup-mfa.title" },
        description: { key: "pages.sign-in.setup-mfa.description" },
        formUI: {
          phoneNumber: {
            icon: Icons.CONTACT_PHONE,
            label: { key: "pages.sign-in.setup-mfa.form.phone-number.label" },
          },
          submit: {
            label: { key: "pages.sign-in.setup-mfa.form.submit.label" },
          },
        },
        dismissButton: {
          color: Colors.YELLOW,
          icon: Icons.CROSS,
        },
      },
      activateMFA: {
        title: { key: "pages.sign-in.activate-mfa.title" },
        description: { key: "pages.sign-in.activate-mfa.description" },
        formUI: {
          password: {
            label: { key: "pages.sign-in.activate-mfa.form.password.label" },
          },
          verificationCode: {
            label: { key: "pages.sign-in.activate-mfa.form.verification-code.label" },
          },
          submit: {
            label: { key: "pages.sign-in.activate-mfa.form.submit.label" },
          },
        },
        managedErrors: {
          MFA_CODE_INVALID: { key: "pages.sign-in.activate-mfa.errors.MFA_CODE_INVALID" },
          LOGIN_INVALID_USERNAME_PASSWORD: {
            key: "pages.sign-in.activate-mfa.errors.LOGIN_INVALID_USERNAME_PASSWORD",
          },
        },
        dismissButton: {
          color: Colors.YELLOW,
          icon: Icons.CROSS,
        },
      },
      challengeMFA: {
        title: { key: "pages.sign-in.challenge-mfa.title" },
        description: { key: "pages.sign-in.challenge-mfa.description" },
        formUI: {
          verificationCode: {
            label: { key: "pages.sign-in.challenge-mfa.form.verification-code.label" },
          },
          submit: {
            label: { key: "pages.sign-in.challenge-mfa.form.submit.label" },
          },
        },
        dismissButton: {
          color: Colors.YELLOW,
          icon: Icons.CROSS,
        },
      },
    };
  }
}
