import { Pipe, PipeTransform } from "@angular/core";

/*
 * Very basic search and replace, to be inproved.
 */
@Pipe({ name: "replace" })
export class ReplacePipe implements PipeTransform {
  transform(value: string, args: string[]): string {
    let index = 0;

    for (const arg of args) {
      value = value.replace(`{${index}}`, arg);
      index++;
    }

    return value;
  }
}
